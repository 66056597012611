import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import RegistrationHeader from "../components/registration/RegistrationHeader";
import Footer from "../components/Footer";



export default function RegistrationInstructions(props) {

  return (
    <Container style={{ alignSelf: "center" }}>
      <main className="main-content h-100 border-radius-lg w-100 my-5">
        <RegistrationHeader />

        <div className="">
          <h2 className="text-center">Registration Instructions</h2>
          <h5 className="text-center">Instructions before Filling the Online Registration as a Certified Food Scientist of Nigeria (CFSN)</h5>
        </div>

        <div className='mt-5 mx-12'>
          <div>
            <p className='font-weight-normal'>1. Scan and save all your documents in png/jpeg format (Passport photo, NIFST membership certificate, Tertiary school certificate (s); Diploma/First/Second/Third Degree Certificates or whichever applies)</p>
          </div>
          <div>
           <p className='font-normal font-weight-normal'>2. Click on ‘NiCFoST Portal’ and click ‘CFSN Registration’ option.</p>
          </div>
          <div>
            <p className='font-normal font-weight-normal'>3.To proceed to registration, sign up to create an account. The account will be required to log into your portal. Your portal contains your registration and licensing details. </p>
          </div>
          <div>
          <p className='font-normal font-weight-normal'>4.When you have signed up,  fill in the required details in the registration form.</p>
          </div>
          <div>
            <p className='font-normal font-weight-normal'>a. The benefits of creating an account include pausing and resuming your registration  as well as reviewing your details before submission.</p>
          </div>
          <div>
            <p className='font-normal font-weight-normal'>b. You may resume your registration process by signing in using your phone number and password.</p>
          </div>
          <div>
            <p className='font-normal font-weight-normal'>5. Submit your application..</p>
          </div>
          <div>
            <p className='font-normal font-weight-normal'>6. After successful application, you can access your portal for your status using your sign in details.</p>
          </div>
          <div>
            <p className='font-normal font-weight-normal'>For complaints/support, you may chat with us or send an email to rldnicfost@gmail.com or call the office phone numbers 07048033367, 08184186450 or 0803548986</p>
          </div>
          <div>
          </div>
        </div>
      </main>
   
      <Footer />
    </Container>
  );
}


















// import React from 'react'

// export default function RegistrationInstructions(props) {

//   return (
//     <div className=''>
// {/* <h1 className="text">Registration Instructions</h1>
// //      <h3>Instructions before Filling the Online Registration as a Certified Food Scientist of Nigeria (CFSN)</h3>

// //      <div className=''>
// <div>
//   <p className='font-normal font-weight-normal'>1. Scan and save all your documents in png/jpeg format (Passport photo, NIFST membership certificate, Tertiary school certificates).</p>
// </div>
// <div>
//   <p className='font-normal font-weight-normal'>2. Visit the NiCFoST Website @ <link>www.nicfost.gon.ng</link></p>
// </div>
// <div>
//   <p className='font-normal font-weight-normal'>3. Click on ‘NiCFoST Portal’ and click ‘CFSN Registration’ option </p>
// </div>
// <div>
//   <p className='font-normal font-weight-normal'>4. Fill the required details in the registration form.</p>
// </div>
// <div>
//   <p className='font-normal font-weight-normal'>a. Note that you will require your username created while filling the registration form later. They will be required for membership login on the home page to view your CFSN number, certificate of registration and annual practice licence if you require it to practice in Nigeria.</p>
// </div>
// <div>
//   <p className='font-normal font-weight-normal'>b. While filling the ‘place of work’ and you are not employed, just indicate ‘Unemployed’ and ‘Not Applicable’ in the rest areas.
// // 5. Submit your application.</p>
// </div>
// <div>
//   <p className='font-normal font-weight-normal'>6. Click on “Ignore” in the next page after submission.</p>
// </div>
// <div>
//   <p className='font-normal font-weight-normal'>You are ready to view your CFSN Number and Certificate of Registration.</p>
// </div>
// <div>
//   <p className='font-normal font-weight-normal'>7. Go back to Home page and click on “Membership login”</p>
// </div>
// <div>
//   <p className='font-normal font-weight-normal'>8. Enter your username and password you created while filling the form.</p>
// </div> */}
    //  </div>

//      <div className=''>
  // <p className='font-normal font-weight-normal'>***Note that the hard copy of the certificate of registration will be collected from NiCFoST office at 11 Dire Dawa Street, off Herbert Macauley Street, off Rabat Street, off Zuwais Street, Wuse Zone 6, Abuja.</p>
  // <p className='font-normal font-weight-normal'>For complaints/support, you may chat with us or send an email to <link>rldnicfost@gmail.com</link> or call the office phone numbers 07048033367, 08054210587, 08184186450 or 08035489868</p>
//      </div>
//     </div> 
//   ) 
// }

