import React from "react";
import coatOfArms from "../../assets/images/coat.png";
import nicfostLogo from "../../assets/images/logo.png";

export default class RegistrationHeader extends React.Component {
  render() {
    return (
      <div className="text-center container-fluid px-4 d-flex justify-content-between mb-3">
        <div>
          <img
            src={nicfostLogo}
            className="navbar-brand-img"
            alt="NiCFoST Logo"
            style={{ width: 85 }}
          />
        </div>
        <div>
          <h4 className="font-weight-bold text-center mt-4">
            NIGERIAN COUNCIL OF FOOD SCIENCE AND TECHNOLOGY (NiCFoST)
          </h4>
        </div>
        <div>
          <img
            src={coatOfArms}
            className="navbar-brand-img"
            alt="Nigerian Coat Of Arms"
            style={{ width: 85 }}
          />
        </div>
      </div>
    );
  }
}
