import React from "react";
import { Container, Row, Col } from "reactstrap";
import Alert from "react-bootstrap/Alert";
import Footer from "../components/Footer";
import RegistrationHeader from "../components/registration/RegistrationHeader";
import {
  getFormSteps,
  defaultFormData,
} from "../components/registration/formStepsV3";
import { Wizard } from "react-formik-step-wizard";
import WizardWrapper from "../components/registration/WizardWrapper";
import WizardFooter from "../components/registration/WizardFooterV3";
import "../assets/css/wizard.css";
import Swal from "sweetalert2";

export default function RegisterFoodScientistV3(props) {
  const SaveData = async (values, endpoint) => {
    // do nothing
  };

  const submitForm = (values) => {
    sessionStorage.clear();
    localStorage.clear();
    Swal.fire({
      html: "Form successfully submitted",
      icon: "error",
      timer: 4000,
      timerProgressBar: true,
      showConfirmButton: false,
      showCancelButton: true,
      showCloseButton: true,
      // buttonsStyling: false,
      cancelButtonColor: "#ff7b7b",
    });
  };

  const informationUpdateNotice = () => {
    return (
      <Alert
        className="text-light font-weight-bold"
        variant="warning"
        dismissible={true}
      >
        <Alert.Heading className="text-light text-center">
          Your entered details will be automatically saved each time you click
          the 'NEXT' button
        </Alert.Heading>
        <p className="text-center">
          <a
            href="https://nicfost.gov.ng/registration-instructions/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-light font-weight-bold  "
          >
            Read registration instructions here.
          </a>
        </p>
      </Alert>
    );
  };

  let formData = defaultFormData;
  let ongoingJsonFormData = sessionStorage.getItem("registration:ongoing");
  if (ongoingJsonFormData) {
    formData = JSON.parse(ongoingJsonFormData);
  }

  const formSteps = getFormSteps(formData);

  return (
    <Container style={{ alignSelf: "center" }}>
      <main className="main-content h-100 border-radius-lg w-100 my-5">
        <RegistrationHeader />
        <Container>
          <Row>
            <Col md={12}>{informationUpdateNotice()}</Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="mb-2">
                {/* form content start --> */}
                <Wizard
                  steps={formSteps}
                  onStepChanged={(fromStep, toStep, wizardValues) => {
                    SaveData(wizardValues[fromStep?.id], fromStep?.endpoint);
                  }}
                  onCompleted={submitForm}
                  wrapper={
                    <WizardWrapper
                      steps={formSteps}
                      title=""
                      subtitle="Application for Registration as a Certified Food Scientist of Nigeria (CFSN)"
                    />
                  }
                  footer={<WizardFooter />}
                />
              </div>
            </Col>
          </Row>
        </Container>
        <Footer />
      </main>
    </Container>
  );
}
