import React, { Component } from "react";
import { Form, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Swal from "sweetalert2";
import { states } from "../components/States";
import { countries } from "../components/Countries";
import logo from "../assets/images/logo.png";
 
import SHA512 from 'crypto-js/sha512';
import Footer from "../components/Footer";
let FORM_ID = "";
let TOKEN = "";


const baseUrl = process.env.REACT_APP_BASE_URL


class FoodScientist extends Component {
    constructor(props) {
        var today = new Date(),
          date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();
        super(props);
        this.state = {
          currentDate: date,
          title: "",
          tetInst: "",
          gender: "",
          surname: "",
          prevSurname: "",
          firstname: "",
          othername: "",
          courseStudy: "",
          courseStudy2: "",
          courseStudy3: "",
          courseStudy4: "",
          courseStudy5: "",
          dob: "",
          position: "",
          mailingAddres: "",
          phone: "",
          disabled: false,
          nationality: "",
          state: "",
          lga: "",
          boxAll: false,
          address: "",
          password: "",
          confirmPassword: "",
          sponsorName: "",
          sponsorName2: "",
          sponsorNo2: "",
          sponsorNo: "",
          orgName: "",
          orgEmail: "",
          orgPhone: "",
          orgAddress: "",
          qualification: "",
          qualification2: "",
          qualification3: "",
          qualification4: "",
          qualification5: "",
          qualificationYear: "",
          qualificationYear2: "",
          qualificationYear3: "",
          qualificationYear4: "",
          qualificationYear5: "",
          remitaNo: "",
          fileUpload: "",
          city: "",
          email: "",
          nifst: "",
          countryCode: "",
          colorSurname: "black",
          colorFirstName: "black",
          colorTitle: "black",
          colorEmail: "black",
          colorOthername: "black",
          colorNationality: "black",
          colorLga: "black",
          colorPhone: "black",
          colorPassword: "black",
          colorPasswordConf: "black",
          colorSponsor: "black",
          colorSponsorNo: "black",
          colorOrgName: "black",
          colorOrgEmail: "black",
          colorOrgPhone: "black",
          colorAddress: "black",
          // colorCity: "black",
          colorPosition: "black",
          colorState: "black",
          colorGender: "black",
          colorDob: "black",
          colorNIFST: "black",
          colorQualYear: "black",
          colorRemita: "black",
          colorQual: "black",
          colorMailAddress: "black",
          colorCountryCode: "black",
          loading: false,
          isUserId: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleGenderChange = this.handleGenderChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleQualificationChange = this.handleQualificationChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
      }

      handleChange(e) {
        this.setState({ title: e.target.value });
      }
      handleStateChange(e) {
        this.setState({ state: e.target.value });
      }

      handleGenderChange(e) {
        this.setState({ gender: e.target.value });
      }

      handleCountryChange(e) {
        this.setState({ countryCode: e.target.value });
      }

      handleQualificationChange(e) {
        this.setState({ qualification: e.target.value });
      }

      handleCheckChange(e) {
        this.setState({ boxAll: e.target.checked }, () => {
          console.log("This returned true or false", this.state.boxAll);
        });
      }

      getStates() {
        return states.map((state) => {
          return <option value={state}>{state}</option>;
        });
      }

      getCountry() {
        return countries.map((country) => {
          return <option value={country.dial_code}>{country.name}</option>;
        });
      }

      checkAuth(){
        if(localStorage.getItem('userid')){
          this.uploadImage();
        }else{
          this.register();
        }
      }

      checkLogin = async (e) => {
        var obj = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            countryid: this.state.countryCode,
            password: this.state.password,
            telephone: localStorage.getItem("phone")
              ? localStorage.getItem("phone")
              : this.state.phone,
          }),
        };
        fetch(`${baseUrl}/Users/login`, obj)
          .then((response) => response.json())
          .then((responseJson) => {
            if (responseJson.message === "Login Successfull") {
              this.setState({ loading: false });
              localStorage.setItem("userid", responseJson.userid);
              localStorage.setItem("email", responseJson.email);
              TOKEN = responseJson.token;
              localStorage.setItem("token", responseJson.token);
              // this.props.history.push("/dashboard");
              this.uploadImage();
            } else if (
              responseJson.message === "Index 0 out of bounds for length 0"
            ) {
              this.register();
            } else {
              this.setState({ loading: false, disabled: false });
              Swal.fire({
                title: "Error!",
                text: responseJson.message,
                icon: "error",
                confirmButtonText: "OK",
              });
            }
          })
          .catch((error) => {
            this.setState({ loading: false, disabled: false });
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      };

      login = async (e) => {
        var obj = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            countryid: this.state.countryCode,
            password: this.state.password,
            telephone: localStorage.getItem("phone")
              ? localStorage.getItem("phone")
              : this.state.phone,
          }),
        };
        fetch(`${baseUrl}/Users/login`, obj)
          .then((response) => response.json())
          .then((responseJson) => {
            // console.warn(responseJson);
            if (responseJson.message === "Login Successfull") {
              this.setState({ loading: false });
              localStorage.setItem("userid", responseJson.userid);
              localStorage.setItem("email", responseJson.email);
              TOKEN = responseJson.token;
              localStorage.setItem("token", responseJson.token);
              this.uploadImage();
            } else if (
              responseJson.message === "Index 0 out of bounds for length 0"
            ) {
              this.setState({ loading: false });
              Swal.fire({
                title: "Error!",
                text: "Invalid username or password",
                icon: "error",
                confirmButtonText: "OK",
              });
            } else {
              this.setState({ loading: false, disabled: false });
              Swal.fire({
                title: "Error!",
                text: responseJson.message,
                icon: "error",
                confirmButtonText: "OK",
              });
            }
          })
          .catch((error) => {
            this.setState({ loading: false, disabled: false });
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      };

      uploadImage = async () => {
        var obj = {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem('token'),
          },
          body: JSON.stringify({
            imageToBase64String: this.state.fileUpload,
            userid: localStorage.getItem("userid"),
          }),
        };
        await fetch(`${baseUrl}/Registration/uploadRegistrationImage`, obj)
          .then((response) => response.json())
          .then((responseJson) => {
            // console.warn(responseJson);
            if (responseJson.message === "Image Updated Successfully") {
              FORM_ID = responseJson.formid;
              localStorage.setItem("formid", responseJson.formid);
              this.registerScientist();
            }
          })
          .catch((error) => {
            this.setState({ loading: false, disabled: false });
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      };

      register = async (e) => {
        const { password, countryCode, confirmPassword } = this.state;

        this.setState({ loading: true });

        var obj = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
          body: JSON.stringify({
            confirmpassword: confirmPassword,
            countryid: localStorage.getItem("countryCode")
              ? localStorage.getItem("countryCode")
              : countryCode,
            email: localStorage.getItem("email")
              ? localStorage.getItem("email")
              : this.state.email,
            password: password,
            telephone: localStorage.getItem("phone")
              ? localStorage.getItem("phone")
              : this.state.phone,
          }),
        };
        await fetch(`${baseUrl}/Users/newUser`, obj)
          .then((response) => response.json())
          .then((responseJson) => {
            if (responseJson.message === "Registration Successfull") {
              localStorage.setItem("userid", responseJson.userid);
              let req = {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  userid: responseJson.userid,
                }),
              };
              fetch(`${baseUrl}/Users/activateUser`, req)
                .then((response) => response.json())
                .then((responseJson) => {
                  // console.warn(responseJson);
                  if (responseJson.message === "User Activated Successfully") {
                    this.login();
                  } else {
                    this.setState({ loading: false });
                    Swal.fire({
                      title: "Error!",
                      text: responseJson.message,
                      icon: "error",
                      confirmButtonText: "OK",
                    });
                  }
                })
                .catch((error) => {
                  this.setState({ loading: false, disabled: false });
                  Swal.fire({
                    title: "Error!",
                    text: error.message,
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                });
            } else {
              this.setState({ loading: false, disabled: false });
              Swal.fire({
                title: "Error!",
                text: responseJson.message,
                icon: "error",
                confirmButtonText: "OK",
              });
            }
          })
          .catch((error) => {
            this.setState({ loading: false, disabled: false });
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      };

      makeRemitaPayment = () => {
        let merchantId = "2547916";
        let apiKey = "1946"
        let serviceTypeId = "4430731"
        let d = new Date();
        let orderId = d.getTime();
        let totalAmount = "10000";
        let apiHash = SHA512(merchantId+ serviceTypeId+ orderId+totalAmount+apiKey);

        let req = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer remitaConsumerKey=${merchantId},remitaConsumerToken=${apiHash}`,
          },
          body: JSON.stringify({
            "serviceTypeId": `${serviceTypeId}`,
            "amount": `${totalAmount}`,
            "orderId": `${orderId}`,
            "payerName": `${this.state.firstname} ${this.state.surname}`,
            "payerEmail": `${this.state.email}`,
            "payerPhone": `${this.state.phone}`,
            "description": " Membership/ Professional Registration"
          }),
        };
        fetch(`https://remitademo.net/remita/exapp/api/v1/send/api/echannelsvc/merchant/api/paymentinit`, req)
          .then((response) => response.json())
          .then((responseJson) => {
            console.warn(responseJson);
          })
          .catch((error) => {
            this.setState({ loading: false, disabled: false });
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      }

      registerScientist = async () => {
        var obj = {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify({
            DOB: "2022-03-20T10:27:05.181Z",
            additionalqualification: "string",
            amountpaid: "",
            applicationdate: "2022-03-20T10:27:05.182Z",
            applicationstatus: "pending",
            contactaddress: this.state.contactaddress,
            contactemail: this.state.contactemail,
            contacttelephone: this.state.contacttelephone,
            courseofstudy: this.state.courseStudy,
            educationperformance: this.state.educationperformance,
            formid: FORM_ID,
            gender: this.state.gender,
            lastlicensedate: "",
            lastlicensenumber: "",
            lga: this.state.lga,
            licensedate: "2022-03-20T10:27:05.182Z",
            licensenumber: "",
            licenseremarks: "",
            nationality: this.state.nationality,
            nifstregistrationnumber: this.state.nifst,
            organization: this.state.orgName,
            organizationaddress: this.state.orgAddress,
            organizationemail: this.state.orgEmail,
            organizationposition: this.state.position,
            organizationtelephone: this.state.orgPhone,
            othernames: this.state.othername,
            practicecategory: "",
            previoussurname: this.state.prevSurname,
            qualification: this.state.qualification,
            sponsorcfsnno1: this.state.sponsorNo,
            sponsorcfsnno2: this.state.sponsorNo2,
            sponsorname1: this.state.sponsorName,
            sponsorname2: this.state.sponsorName2,
            state: this.state.state,
            surname: this.state.surname,
            tertiaryinstitution: this.state.tetInst,
            title: this.state.title,
            yearofqualification: this.state.yearofqualification
          }),
        };
        fetch(`${baseUrl}/Registration/updateRegistration`, obj)
          .then((response) => response.json())
          .then((responseJson) => {
            console.warn(responseJson);
            if (
              responseJson.message == "Registration Information added Successfully"
            ) {
              this.setState({ loading: false, disabled: false });
              Swal.fire({
                title: "Success",
                text: "Registration successful",
                icon: "success",
                confirmButtonText: "OK",
              }).then(()=> {
                // this.props.history.push("/dashboard");
              })
            }
          })
          .catch((error) => {
            this.setState({ loading: false, disabled: false });
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      };

      componentDidMount() {
        const {
          email,
          firstname,
          surname,
          othername,
          nationality,
          lga,
          phone,
          position,
          password,
          confirmPassword,
          sponsorName,
          orgAddress,
          sponsorNo,
          orgEmail,
          orgName,
          orgPhone,
          mailingAddres,
          address,
          title,
          gender,
          nifst,
          state,
          dob,
          remitaNo,
          qualification,
          qualificationYear,
          countryCode,
          registrationnumber,
        } = this.state;
        if (localStorage.hasOwnProperty("email") && email === "") {
          this.setState({ email: localStorage.getItem("email") });
        } else if (localStorage.hasOwnProperty("firstname") && firstname === "") {
          this.setState({ firstname: localStorage.getItem("firstname") });
        } else if (localStorage.hasOwnProperty("surname") && surname === "") {
          this.setState({ surname: localStorage.getItem("surname") });
        } else if (localStorage.hasOwnProperty("othername") && othername === "") {
          this.setState({ othername: localStorage.getItem("othername") });
        } else if (
          localStorage.hasOwnProperty("nationality") &&
          nationality === ""
        ) {
          this.setState({ nationality: localStorage.getItem("nationality") });
        } else if (localStorage.hasOwnProperty("lga") && lga == "") {
          this.setState({ lga: localStorage.getItem("lga") });
        } else if (localStorage.hasOwnProperty("address") && address === "") {
          this.setState({ address: localStorage.getItem("address") });
        } else if (
          localStorage.hasOwnProperty("title") &&
          title == "-- Select title --"
        ) {
          this.setState({ title: localStorage.getItem("title") });
        } else if (
          localStorage.hasOwnProperty("gender") &&
          gender == "-- Select gender --"
        ) {
          this.setState({ gender: localStorage.getItem("gender") });
        } else if (
          localStorage.hasOwnProperty("state") &&
          state == "-- Select state --"
        ) {
          this.setState({ state: localStorage.getItem("state") });
        } else if (localStorage.hasOwnProperty("dob") && dob == "") {
          this.setState({ dob: localStorage.getItem("dob") });
        } else if (localStorage.hasOwnProperty("nifst") && nifst == "") {
          this.setState({ nifst: localStorage.getItem("nifst") });
        } else if (
          localStorage.hasOwnProperty("nifst") &&
          registrationnumber == ""
        ) {
          this.setState({ registrationnumber: localStorage.getItem("nifst") });
        } else if (localStorage.hasOwnProperty("remita") && remitaNo == "") {
          this.setState({ remitaNo: localStorage.getItem("remita") });
        } else if (
          localStorage.hasOwnProperty("qualification") &&
          qualification == "-- Select qualification --"
        ) {
          this.setState({ qualification: localStorage.getItem("qualification") });
        } else if (
          localStorage.hasOwnProperty("qualificationYear") &&
          qualificationYear == ""
        ) {
          this.setState({
            qualificationYear: localStorage.getItem("qualificationYear"),
          });
        } else if (
          localStorage.hasOwnProperty("countryCode") &&
          countryCode == ""
        ) {
          this.setState({ countryCode: localStorage.getItem("countryCode") });
        } else if (localStorage.hasOwnProperty("phone") && phone == "") {
          this.setState({ phone: localStorage.getItem("phone") });
        } else if (localStorage.hasOwnProperty("mail") && mailingAddres == "") {
          this.setState({ mailingAddres: localStorage.getItem("mail") });
        } else if (localStorage.hasOwnProperty("password") && password == "") {
          this.setState({ password: localStorage.getItem("password") });
        } else if (
          localStorage.hasOwnProperty("confirmpPassword") &&
          confirmPassword == ""
        ) {
          this.setState({
            confirmPassword: localStorage.getItem("confirmPassword"),
          });
        } else if (localStorage.hasOwnProperty("orgEmail") && orgEmail == "") {
          this.setState({ orgEmail: localStorage.getItem("orgEmail") });
        } else if (localStorage.hasOwnProperty("position") && position == "") {
          this.setState({ position: localStorage.getItem("position") });
        } else if (localStorage.hasOwnProperty("orgName") && orgName == "") {
          this.setState({ orgName: localStorage.getItem("orgName") });
        } else if (localStorage.hasOwnProperty("orgPhone") && orgPhone == "") {
          this.setState({ orgPhone: localStorage.getItem("orgPhone") });
        } else if (
          localStorage.hasOwnProperty("sponsorName") &&
          sponsorName == ""
        ) {
          this.setState({ sponsorName: localStorage.getItem("sponsorName") });
        } else if (localStorage.hasOwnProperty("sponsorNo") && sponsorNo == "") {
          this.setState({ sponsorNo: localStorage.getItem("sponsorNo") });
        } else if (localStorage.hasOwnProperty("orgAddress") && orgAddress == "") {
          this.setState({ orgAddress: localStorage.getItem("orgAddress") });
        }
      }

      checkValidation = async (e) => {
        const {
          title,
          gender,
          surname,
          firstname,
          othername,
          dob,
          qualification,
          qualificationYear,
          remitaNo,
          nationality,
          state,
          nifst,
          phone,
          password,
          confirmPassword,
          mailingAddres,
          sponsorName,
          fileUpload,
          boxAll,
          sponsorName2,
          sponsorNo,
          sponsorNo2,
          lga,
          address,
          position,
          orgEmail,
          orgName,
          orgPhone,
          city,
          email,
          countryCode,
          registrationnumber,
        } = this.state;
        this.setState({ loading: true, disabled: true });
        e.preventDefault();

        if (surname === "" && !localStorage.getItem("surname")) {
          Swal.fire({
            title: "Empty",
            text: "Please enter your surname",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorSurname: "red" });
        } else if (firstname == "" && !localStorage.getItem("firstname")) {
          Swal.fire({
            title: "Empty",
            text: "Please veirfy your first name",
            icon: "error",
            confirmButtonText: "OK",
          });

          this.setState({ loading: false, disabled: false, colorFirstName: "red" });
        } else if (othername === "" && !localStorage.getItem("othername")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your other name",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorOthername: "red" });
        } else if (title === "" && !localStorage.getItem("title")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your title",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorTitle: "red" });
        } else if (dob === "" && !localStorage.getItem("dob")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your Date of birth",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorDob: "red" });
        } else if (nationality === "" && !localStorage.getItem("nationality")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your nationality",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({
            loading: false,
            disabled: false,
            colorNationality: "red",
          });
        } else if (gender === "" && !localStorage.getItem("gender")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your gender",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorGender: "red" });
        } else if (state === "" && !localStorage.getItem("state")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your State of Origin",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorState: "red" });
        } else if (lga === "" && !localStorage.getItem("lga")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your Local Government Area",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorLga: "red" });
        } else if (nifst === "" && !localStorage.hasOwnProperty("nifst")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your NIFST number",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorNIFST: "red" });
        } else if (
          qualification === "" &&
          !localStorage.hasOwnProperty("qualification")
        ) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your Qualification",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorQual: "red" });
        } else if (
          qualificationYear === "" &&
          !localStorage.hasOwnProperty("qualificationYear")
        ) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your Year of Qualification",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorQualYear: "red" });
        } else if (
          countryCode === "" &&
          !localStorage.hasOwnProperty("countryCode")
        ) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your Country Code",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({
            loading: false,
            disabled: false,
            colorCountryCode: "red",
          });
        } else if (phone === "" && !localStorage.hasOwnProperty("phone")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your Phone Number",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorPhone: "red" });
        } else if (email === "" ) {
          Swal.fire({
            title: "Empty",
            text: "Please verify your email",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorEmail: "red" });
        }
        else if (password === "") {
          Swal.fire({
            title: "Empty",
            text: "Please specify your Password",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorPassword: "red" });
        } else if (
           confirmPassword === ""
        ) {
          Swal.fire({
            title: "Empty",
            text: "Please Confirm your Password",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({
            loading: false,
            disabled: false,
            colorPasswordConf: "red",
          });
        } else if (confirmPassword !== password) {
          Swal.fire({
            title: "Error",
            text: "Passwords do not match",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({
            loading: false,
            disabled: false,
            colorPasswordConf: "red",
            colorPassword: "red",
          });
        } else if (mailingAddres === "" && !localStorage.getItem("mail")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your Mailing Address",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({
            loading: false,
            disabled: false,
            colorMailAddress: "red",
          });
        } else if (orgName === "" && !localStorage.getItem("orgName")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your Organization Name",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorOrgName: "red" });
        } else if (position === "" && !localStorage.getItem("position")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your position in your Organization",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorPosition: "red" });
        } else if (address === "" && !localStorage.getItem("address")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify address of your Organization",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorAddress: "red" });
        } else if (orgPhone === "" && !localStorage.getItem("orgPhone")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify phone number of your Organization",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorOrgPhone: "red" });
        } else if (orgEmail === "" && !localStorage.getItem("orgEmail")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify email of your Organization",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorOrgEmail: "red" });
        } else if (sponsorName === "" && !localStorage.getItem("sponsorName")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify Name of Your Sponsor",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorSponsor: "red" });
        } else if (sponsorNo === "" && !localStorage.getItem("sponsorNo")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify the CSFN of Your Sponsor",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorSponsorNo: "red" });
        } else if (fileUpload === "") {
          Swal.fire({
            title: "Empty",
            text: "Please upload your recent photo",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorSponsorNo: "red" });
        } else if (boxAll === false) {
          Swal.fire({
            title: "Accept The Terms",
            text: "Please accept the terms and conditions",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false });
        } else {
          this.checkAuth();
        }
      };
    render(){
        const { loading } = this.state;
        return(
            <div>


<div className="text-center">
                <img
              src={logo}
              style={{
                width: "10%",
                position: "relative",
                top: 35,
                alignSelf: "center",
                margin: "0 auto",
              }}
            />
            </div>

                   <h3
              className="nicfostText"
              style={{
                position: "relative",
                fontWeight: "bold",
                top: 55,
                color: "#333",
                textAlign: "center",
              }}
            >
              NIGERIAN COUNCIL OF FOOD SCIENCE AND TECHNOLOGY (NicFosT)
            </h3>
            <h4
              style={{
                position: "relative",
                fontWeight: "bold",
                top: 65,
                color: "#333",
                textAlign: "center",
              }}
            >
              APPLICATION FOR REGISTRATION AS A CERTIFIED FOOD SCIENTIST OF NIGERIA (CFSN)
            </h4>
            <h5
            style={{
                position: "relative",
                fontWeight: "bold",
                top: 120,
                color: "#333",
                // marginLeft: 85,
                textAlign: "center",
              }}
              className="text-danger"
            >
            NOTE: EVERY REGISTERED FOOD SCIENTIST SHOULD SEND IMMEDIATE NOTIFICATION <br/> OF ANY CHANGE OF ADDRESS/DATA SUPPLIED TO THE REGISTRAR OF THE COUNCIL
            </h5>
            <div className="container" style={{ marginTop: 65, padding: 85 }}>
              <div style={{ marginTop: 25 }}></div>
              <form className="row">
                <label
                  className="mb-3 h4"
                  style={{ color: "green" }}
                  htmlFor="floatingInputCustom"
                >
                  Personal Information
                </label>
                <br />
                <br />
                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorSurname }}
                    className="form-label"
                  >
                    Surname <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control w-50 shadow-none"
                      type="text"
                      required="required"
                      onChange={(e) => this.setState({ surname: e.target.value })}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label"
                  >
                    Previous Surname (If applicable)
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control w-50 shadow-none"
                      type="text"
                      onChange={(e) => this.setState({ prevSurname: e.target.value })}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorFirstName }}
                    className="form-label"
                  >
                    First name <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control w-50 shadow-none"
                      type="text"
                      required="required"
                      onChange={(e) => this.setState({ firstname: e.target.value })}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorOthername }}
                    className="form-label"
                  >
                    Other name(s) <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      required="required"
                      onChange={(e) => this.setState({ othername: e.target.value })}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorTitle }}
                    className="form-label"
                  >
                    Title <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <select
                      className="form-control"
                      aria-label="Select title"
                      onChange={this.handleChange}
                      style={{ color: this.state.colorTitle }}
                    >
                      <option
                        value={
                          localStorage.getItem("title")
                            ? this.state.title
                            : "-- Select title --"
                        }
                        selected
                      >
                        {localStorage.getItem("title")
                          ? localStorage.getItem("title")
                          : "-- Select title --"}
                      </option>
                      <option value="mr">Mr.</option>
                      <option value="mrs">Mrs.</option>
                      <option value="ms">Ms.</option>
                      <option value="dr">Dr.</option>
                      <option value="prof">Prof.</option>
                    </select>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorDob }}
                    className="form-label"
                  >
                    DOB <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      type="date"
                      className="form-control w-50 shadow-none"
                      onChange={(e) => this.setState({ dob: e.target.value })}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label"
                    style={{ color: this.state.colorNationality }}
                  >
                    Nationality <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      type="text"
                      className="form-control shadow-none"
                      onChange={(e) =>
                        this.setState({ nationality: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label"
                    style={{ color: this.state.colorGender }}
                  >
                    Select Gender <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <select
                      className="form-control shadow-none"
                      aria-label="Select"
                      onChange={this.handleGenderChange}
                      style={{ color: this.state.colorGender }}
                    >
                      <option
                        value={
                          localStorage.getItem("gender")
                            ? localStorage.getItem("gender")
                            : "gender"
                        }
                        selected
                      >
                        {localStorage.getItem("gender")
                          ? localStorage.getItem("gender")
                          : "-- Select gender --"}
                      </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorState }}
                    className="form-label"
                  >
                    Select State <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <select
                      className="form-control shadow-none"
                      aria-label="Select state"
                      onChange={this.handleStateChange}
                      style={{ color: this.state.colorState }}
                    >
                      <option
                        value={
                          localStorage.getItem("state")
                            ? localStorage.getItem("state")
                            : "-- Select state --"
                        }
                        selected
                      >
                        {localStorage.getItem("state")
                          ? localStorage.getItem("state")
                          : "-- Select state --"}
                      </option>

                      {this.getStates()}
                    </select>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorLga }}
                    className="form-label"
                  >
                    LGA <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      required="required"
                      onChange={(e) => this.setState({ lga: e.target.value })}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorNIFST }}
                    className="form-label"
                  >
                    NIFST Registration Number <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      required={true}
                      onChange={(e) => this.setState({ nifst: e.target.value })}
                    />
                  </div>
                </div>

                <hr />
                <label
                  className="mb-3 h4"
                  style={{ color: "green" }}
                  htmlFor="floatingInputCustom"
                >
                  Qualification
                </label>
                <br />
                <br />

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorNIFST }}
                    className="form-label"
                  >
                    Course of study
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      required="required"
                      onChange={(e) => this.setState({ courseStudy: e.target.value })}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorNIFST }}
                    className="form-label"
                  >
                    Tertiary Institution
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      required="required"
                      onChange={(e) => this.setState({ tetInst: e.target.value })}
                    />
                  </div>
                </div>


                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorQual }}
                    className="form-label"
                  >
                    Qualification <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <select
                      className="form-control shadow-none"
                      aria-label="Select"
                      onChange={this.handleQualificationChange}
                      style={{ color: this.state.colorGender }}
                    >
                      <option

                        selected disabled
                      >
                         -- Select qualification --
                      </option>
                      <option value="SSCE">SSCE</option>
                      <option value="OND">OND</option>
                      <option value="BSC">BSC</option>
                      <option value="MSC">MSC</option>
                      <option value="PHD">PHD</option>
                    </select>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorQualYear }}
                    className="form-label"
                  >
                    Year of Qualification <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      required="required"
                      onChange={(e) =>
                        this.setState({ qualificationYear: e.target.value })
                      }
                    />
                  </div>
                </div>

                <hr />

                <label
                  className="mb-3 h4"
                  style={{ color: "green" }}
                  htmlFor="floatingInputCustom"
                >
                  Contact Information
                </label>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorCountryCode }}
                    className="form-label"
                  >
                    Country code <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <select
                      className="form-control shadow-none"
                      aria-label="Floating label select example"
                      onChange={this.handleCountryChange}
                    >
                      <option value="choose" selected="selected">
                        -- Select country code --
                      </option>
                      {this.getCountry()}
                    </select>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorPhone }}
                    className="form-label"
                  >
                    Phone <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="phone"
                      required="required"
                      onChange={(e) => this.setState({ phone: e.target.value })}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label className="form-label" style={{color:this.state.colorEmail}}>
                    Email <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="email"
                      required="required"
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                  </div>
                </div>

                {/* {localStorage.getItem('userid') ? null : */}
                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorPassword }}
                    className="form-label"
                  >
                    Pasword <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      required="required"
                      onChange={(e) => this.setState({ password: e.target.value })}
                    />
                  </div>
                </div>
                 {/* } */}

                {/* {localStorage.getItem('userid') ? null : */}
                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorPasswordConf }}
                    className="form-label"
                  >
                    Confirm Pasword <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      required="required"
                      onChange={(e) =>
                        this.setState({ confirmPassword: e.target.value })
                      }
                    />
                  </div>
                </div>
                 {/* } */}

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorMailAddress }}
                    className="form-label"
                  >
                    Mailing Address <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>

                    <textarea
                      className="form-control shadow-none"
                      type="text"
                      required="required"
                      onChange={(e) =>
                        this.setState({ mailingAddres: e.target.value })
                      }
                    ></textarea>
                  </div>
                </div>

                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label"
                    style={{ color: this.state.colorGender }}
                  >
                    Category of Practice <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <select
                      className="form-control shadow-none"
                      aria-label="Select"
                      onChange={this.handleGenderChange}
                      style={{ color: this.state.colorGender }}
                    >
                      <option>

                       -- Select practice category --
                      </option>
                      <option value="ACCADEMIC/RESEARCH">ACADEMIC/RESEARCH</option>
                      <option value="GOVERNMENT/REGULATORY">GOVERNMENT/REGULATORY</option>
                      <option value="INDUSTRY">INDUSTRY</option>
                      <option value="PRIVATE PRACTICE">PRIVATE PRACTICE</option>
                      <option value="NGO">NGO</option>
                      <option value="INTERNATIONAL DEVELOPMENT">INTERNATIONAL DEVELOPMENT</option>
                    </select>
                  </div>
                </div>

                <hr />
                <label
                  className="mb-3 h4"
                  style={{ color: "green" }}
                  htmlFor="floatingInputCustom"
                >
                  Place of work
                </label>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorOrgName }}
                    className="form-label"
                  >
                    Name of Organization/Institution{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      onChange={(e) => this.setState({ orgName: e.target.value })}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorPosition }}
                    className="form-label"
                  >
                    Position <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      onChange={(e) => this.setState({ position: e.target.value })}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorAddress }}
                    className="form-label"
                  >
                    Address <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      onChange={(e) => this.setState({ address: e.target.value })}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorOrgPhone }}
                    className="form-label"
                  >
                    Organization Telephone <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      onChange={(e) => this.setState({ orgPhone: e.target.value })}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorOrgEmail }}
                    className="form-label"
                  >
                    Organization Email <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      onChange={(e) => this.setState({ orgEmail: e.target.value })}
                    />
                  </div>
                </div>

                <hr />

                <label
                  className="h4"
                  htmlFor="floatingInputCustom"
                  style={{ color: "green" }}
                >
                  Sponsor Information
                </label>
                <br />
                <br />

                <label className="h5" htmlFor="floatingInputCustom">
                  Sponsor 1
                </label>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorSponsor }}
                    className="form-label"
                  >
                    Sponsor Name <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      onChange={(e) =>
                        this.setState({ sponsorName: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorSponsorNo }}
                    className="form-label"
                  >
                    Current CFSN Number <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      onChange={(e) => this.setState({ sponsorNo: e.target.value })}
                    />
                  </div>
                </div>

                <label className="h5" htmlFor="floatingInputCustom">
                  Sponsor 2
                </label>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label className="form-label">Sponsor Name</label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      onChange={(e) =>
                        this.setState({ sponsorName2: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label className="form-label">Current CFSN Number</label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      onChange={(e) =>
                        this.setState({ sponsorNo2: e.target.value })
                      }
                    />
                  </div>
                </div>
                <hr />
                <label
                  className="h5 mb-5"
                  style={{ color: "green" }}
                  htmlFor="floatingInputCustom"
                >
                  Upload Your Recent Image
                </label>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <input
                    className="form-control shadow-none"
                    type="file"
                    required="required"
                    onChange={(e) => this.setState({ fileUpload: e.target.value })}
                  />
                </div>

                <div className="row" style={{ marginTop: 25 }}>
                  <Form.Floating className="mb-3">
                    <input
                      class="form-check-input shadow-none"
                      type="checkbox"
                      id="checkBoxUS"
                      name="US"
                      value="US"
                      onChange={this.handleCheckChange}
                    />{" "}
                    I hereby certify that I am not a registered and practicing
                    member of an allied profession and the particulars furnished
                    herein are true
                  </Form.Floating>
                  <h6>
                    TAKE NOTICE: THE NICFOST SHALL MAKE A CLAIM AND RECOVER ALL COSTS OF LITIGATION INCURRED BY IN IN DEFENCE OF ANY COURT
                    ACTION INSTITUTED AGAINST IT AT THE INSTANCE OF ANY CERTIFIED FOOD SCIENTIST AND/OR LICENSED FOOD SCIENTIST PREMISES AND
                    WHEREBY THE SUIT IS STRUCK OUT, WITHDRAWN OR THE FOOD SCIENTIST OR THE FOOD SCIENTIST PREMISES LOSES THE CASE.

                  </h6>
                </div>

                <div
                className="text-center"
                  style={{
                    margin: "auto",
                    width: "100%",
                    marginTop: 45,
                    marginBottom: "15vh",
                  }}
                >
                  <button
                    disabled={this.state.disabled}
                    style={{
                      alignSelf: "center",
                      width: "60%",
                      backgroundColor: "#003314",
                    }}
                    className="btn btn-success btn-lg"

                    onClick={(e) => this.makeRemitaPayment()}
                  >
                    {loading ? (
                      <Spinner animation="border" variant="light" size="sm" />
                    ) : (
                      <span className="font-weight-bold">
                        {/* APPLY <i class="fas fa-chevron-right"></i> */}
                        SUBMIT APPLICATION
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
            <Footer />
            </div>
        )
    }
}

export default FoodScientist;
