import React, { useEffect, useState } from "react";
import {
  checkRegistrationPaymentStatus,
  makePayment,
} from "../Helper/paymentHelper";
import RegistrationFooter from "../components/registration/WizardFooterV3";
import RegistrationHeader from "../components/registration/RegistrationHeader";
import SvgSpinners3DotsMove from "../components/SvgSpinners3DotsMove";
import "../../src/assets/css/PaymentSummary.css"; // Import the custom CSS file

export default function PaymentSummary() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  let currentPath = window.location.pathname;
  const [data, setData] = useState({});
  const [pending, setPending] = useState(true);
  const [lastRefresh, setLastRefresh] = useState(
    sessionStorage.getItem("lastRefresh") || new Date().toISOString()
  );
  const [countdown, setCountdown] = useState(30);

  const sessionData = JSON.parse(
    sessionStorage.getItem("registration:ongoing")
  );

  function registrationPaymentStatus() {
    setPending(true);
    const url = `${baseUrl}/v2/registration/register/registration-information?userId=${sessionData.userId}`;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => await res.json())
      .then((response) => {
        setData(response.paymentInformation);
        setPending(false);
        if (response.data.paymentStatus === "complete") {
          window.location.href = `${currentPath}#/new-dashboard`;
        }
        const refreshTime = new Date().toISOString();
        setLastRefresh(refreshTime);
        sessionStorage.setItem("lastRefresh", refreshTime);
      })
      .catch((error) => {
        setPending(false);
        console.warn(error, "from checkpaymentStatus");
      });
  }

  useEffect(() => {
    registrationPaymentStatus(); // Initial call

    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          registrationPaymentStatus();
          return 30;
        }
        return prevCountdown - 1;
      });
    }, 1000); // 1 second

    return () => {
      clearInterval(countdownInterval);
    };
  }, []); // Empty dependency array ensures this runs once on mount

  function mapStatus(status) {
    return status === "pending_confirmation" ? "Pending Confirmation" : status;
  }

  return (
    <div>
      <RegistrationHeader />
      <div className="payment-summary-container">
        <div className="payment-summary-content">
          <h2 className="title">Pending Payment</h2>
          {pending ? (
            <div className="spinner-container">
              <SvgSpinners3DotsMove className="spinner" />
            </div>
          ) : data ? (
            <div>
              <p>
                <strong>Order ID:</strong> {data.rrr}
              </p>
              <p>
                <strong>Transaction Time:</strong> {data.transactionTime}
              </p>
              <p>
                <strong>Amount:</strong> ₦{data.amount}
              </p>
              <p>
                <strong>Status:</strong> {mapStatus(data.paymentStatus)}
              </p>
              <p>
                <strong>Next Refresh In:</strong> {countdown} seconds
              </p>
              <p className="refresh-note">
                You don't need to do anything. When your payment is confirmed,
                you will be redirected to the dashboard.
              </p>
            </div>
          ) : (
            <div>No data available</div>
          )}
        </div>
      </div>
    </div>
  );
}
