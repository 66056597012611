import React, { Component, useState } from "react";
import { Spinner, Alert, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { states } from "./States";
import { countries } from "./Countries";
import Swal from "sweetalert2";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import coat from "../assets/images/coat.png";
import Sidebar from './Sidebar';
import moment from 'moment'
import Modal from 'react-modal';



let LICENCE_FILEBASE64 = "";
let FORM_ID = "";

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const item = {
    data: [],
          renewData: [],
          foodScientistData: [],
          practiceCategory: [],
          feeType: [],
          title: "",
          tertiaryinstitution: "",
          gender: "",
          surname: "",
          prevSurname: "",
          firstname: "",
          othername: "",
          courseStudy: "",
          dob: "",
          orgPosition: "",
          mailingAddres: "",
          phone: "",
          disabled: false,
          nationality: "",
          categoryPractice: "",
          state: "",
          lga: "",
          boxAll: false,
          address: "",
          password: "",
          confirmPassword: "",
          sponsorName: "",
          sponsorName2: "",
          sponsorNo2: "",
          sponsorNo: "",
          orgName: "",
          orgEmail: "",
          orgPhone: "",
          orgAddress: "",
          qualification: "",
          qualificationYear: "",
          // remitaNo: "",
          fileUpload: "",
          city: "",
          email: "",
          nifst: "",
          countryCode: "",
          colorSurname: "black",
          colorFirstName: "black",
          colorTitle: "black",
          colorEmail: "black",
          colorPractice: "black",
          colorOthername: "black",
          colorNationality: "black",
          colorTetiary: "black",
          colorLga: "black",
          colorPhone: "black",
          colorPassword: "black",
          colorPasswordConf: "black",
          colorSponsor: "black",
          colorSponsorNo: "black",
          colorOrgName: "black",
          colorOrgEmail: "black",
          colorOrgPhone: "black",
          colorAddress: "black",
          // colorCity: "black",
          colorPosition: "black",
          colorState: "black",
          colorGender: "black",
          colorDob: "black",
          colorNIFST: "black",
          colorQualYear: "black",
          // colorRemita: "black",
          colorQual: "black",
          colorMailAddress: "black",
          colorCountryCode: "black",
          colorStudy: "black",
          loading: true,
          isLoading: true,
          isRenewalLoading: false,
          isUserId: false,
          // hideForm: false,
          postsPerPage: 10,
          currentPage: 1,
          fees: '',
  }


const RenewLicenceModal= ({children}) => {
    let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  // const [isRenewalLoading, setIsRenewalLoading] = useState(false)
  // const [renewData, setRenewData] = useState([])
  // const [loading, setLoading ] = useState(false )

  function openModal() {
    // passInFunction(true)
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  // console.log(showModal, "from modal") 

  return (
    <>
    <span onClick={openModal} className="text-decoration-none text-light py-3" >{children}</span>
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      
    {/* Start of Renewal Modal */}
    <div className="text-dark">
      <div className="text-center">
        <h5 className="text-light text-center font-weight-bold text-dark mb-4">Renewal Form for Licence</h5>
      </div>

      <div className="container" style={{ marginTop: 18, padding: 9 }}>
        <form className="row">
          <label
            className="mb-3 h4"
            style={{ color: "#145973" }}
            htmlFor="floatingInputCustom"
          >
            Personal Information
          </label>
          <br />
          <br />
          <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
            <label
              style={{ color: item?.colorSurname }}
              className="form-label"
            >
              Surname <span className="text-danger">*</span>
            </label>
            <div className="input-group input-group-outline mb-3">
              <label className="form-label"></label>
              <input
                className="form-control w-50 shadow-none"
                type="text"
                required="required"
                defaultValue={item.surname}
                onChange={(e) => this.setState({ surname: e.target.value })}
              />
            </div>
          </div>

          </form>
        </div>  
    </div>
    
    
    {/* End of Renewal Modal */}
    <button onClick={closeModal}>close</button>
    </Modal>
    </>
  )
}

export default RenewLicenceModal;
