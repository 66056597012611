import React from "react";

const WizardTab = React.forwardRef(
  (
    { title, icon, shape, color = "#2196f3", isActive, index, onClick },
    ref
  ) => {
    const stepClasses = isActive ? "active" : "";
    const cursorStyle = shape === "square" ? "default" : "";
    const [isChecked, setIsChecked] = React.useState(false);
    React.useEffect(() => {
      if (isActive) {
        setIsChecked(true);
      }
    }, [isActive]);

    const iconColor = () => {
      if (isActive && isChecked) {
        return "text-success-emphasis";
      }

      // if (isChecked) {
      //   return "text-success";
      // }

      return "text-success";
    };

    React.useImperativeHandle(ref, () => ({
      setChecked: (value) => {
        setIsChecked(value);
      },
    }));
    // check if icon type string other wise render react node
    const handelIcon = () => {
      if (!icon) return <span className={iconColor()}>{index + 1}</span>;
      if (typeof icon === "string") {
        return <i className={`${icon} ${iconColor()}`}></i>;
      }
      return icon;
    };

    return (
      <li key={index} className={stepClasses}>
        <a
          className={isActive ? "active" : ""}
          style={{ cursor: cursorStyle }}
          onClick={onClick}
        >
          <div
            className={`wizard-icon-circle md ${isChecked ? "checked" : ""} ${
              shape === "square" ? "square_shape" : ""
            }`}
            role="tab"
            tabIndex={isActive ? 0 : undefined}
            id={`step-${index}`}
            aria-controls={`step-${index}`}
            aria-disabled={isActive}
            aria-selected={isActive}
            style={{
              borderColor: isChecked ? color : "",
            }}
          >
            <div
              className={`wizard-icon-container ${
                shape === "square" ? "square_shape" : ""
              }`}
              style={{
                borderColor: isActive ? "var(--bs-success-text-emphasis)" : "",
                borderWidth: isActive ? "3px" : "",
                borderStyle: isActive ? "solid" : "",
                backgroundColor: isActive ? color : "",
              }}
            >
              <span className="wizard-icon">
                {/* check if icon type string other wise render react node */}
                {handelIcon()}
              </span>
            </div>
          </div>
          <span
            className={`stepTitle ${isActive ? "active" : ""}`}
            style={{
              textTransform: "uppercase",
              color: isChecked ? color : "text-success-emphasis",
              marginTop: "8px",
            }}
          >
            {title}
          </span>
        </a>
      </li>
    );
  }
);

export default WizardTab;

