import React, { Component } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner, Card, FloatingLabel, Form } from "react-bootstrap";
import logo from "../assets/images/logo.png"
import { Link } from "react-router-dom";
import Swal from "sweetalert2";


const baseUrl = process.env.REACT_APP_BASE_URL


class ChangePassword extends Component{
    constructor(props) {
        super(props);
        this.state = {
          otp: "",
          loading: false,
          disabled: false
        };
      }

      verifyOTP(){
        const { otp } = this.state;
        if(otp.trim() === localStorage.getItem("code")){
          this.props.history.push("/new-password")
        }else{
          Swal.fire({
            title: "Error",
            text: "Invalid Activation Code",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }

      resendOTP = async () => {
        // console.warn(localStorage.getItem("reset-phone"));
        // console.warn(localStorage.getItem("user-reset-id"));
        this.setState({loading: true, disabled: true})
        var obj = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            countryid: "+234",
            telephone: localStorage.getItem("reset-phone"),
            userid: localStorage.getItem("user-reset-id")
          }),
        };
        await fetch(`${baseUrl}/Users/resendActivationCode`, obj)
          .then((response) => response.json())
          .then((responseJson) => {
            console.warn(responseJson);
            if (responseJson.message === "Activation code generated successfully") {
              localStorage.setItem("code", responseJson.activationcode)
              this.setState({ loading: false, disabled: false });
              Swal.fire({
                title: "Success",
                text: "Activation code regenerated successfully",
                icon: "success",
                confirmButtonText: "OK",
              });
              // this.props.history.push("/enter-otp")
            } else if (
              responseJson.message === "Index 0 out of bounds for length 0"
            ) {
              this.setState({ loading: false, disabled: false });
              Swal.fire({
                title: "Error!",
                text: "Unable to generate verification code.",
                icon: "error",
                confirmButtonText: "OK",
              });
            } else {
              this.setState({ loading: false, disabled: false });
              Swal.fire({
                title: "Error!",
                text: responseJson.message,
                icon: "error",
                confirmButtonText: "OK",
              });
            }
          })
          .catch((error) => {
            this.setState({ loading: false, disabled: false });
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          });
        // }
      };

      render() {
        const { loading, disabled } = this.state;
        return (
          <div className="loginContainer">
            <div
              className="d-flex justify-content-center"
              style={{
                backgroundColor: "rgba(0,0,0,0.7)",
                width: "100%",
                position: "absolute",
                top: 0,
                bottom: 0,
              }}
            >
              <Card style={{ backgroundColor: 'rgba(255,255,255,0.7)' }} className="d-flex justify-content-center cardContainer loginContainer-content">
                <img
                  src={logo}
                  style={{
                    width: "15%",
                    position: "absolute",
                    top: 25,
                    alignSelf: "center",
                  }}
                />

                <h5 className="nicfostText" style={{position: 'relative', fontWeight:'bold', top: 35, color:'#333', textAlign:'center'}}>NIGERIAN COUNCIL OF FOOD SCIENCE AND TECHNOLOGY<br/>(NiCFoST)</h5>
                  <div className="mb-3"></div>
                <h6 className="nicfostText" style={{position:'relative', marginLeft: 13, textAlign:'center', top: 45}}>Enter OTP sent to your phone</h6>

                <div className="container" style={{ marginTop: 50 }}>
                  <Form>
                    <Form.Floating className="mb-3">
                      <input
                        className="form-control shadow-none"
                        type="text"
                        id="otp"
                        onChange={(e) => this.setState({ otp: e.target.value })}
                      />
                      <label htmlFor="otp">OTP</label>
                    </Form.Floating>

                    <Link onClick={(e) => this.resendOTP()} style={{ textDecoration: "none" }}>
                      <h6
                        style={{
                          textAlign: "right",
                          textTransform: "capitalize",
                          marginTop: 10,
                          color: "red",
                          fontSize: 13,
                        }}
                      >
                        Resend OTP
                      </h6>
                    </Link>

                    <div className="d-grid gap-2 mb-3" style={{ marginTop: 30 }}>
                      <button
                        style={{backgroundColor: '#003314'}}
                        className="btn btn-success btn-lg"
                        onClick={(e) => this.verifyOTP()}
                        disabled={disabled}
                      >
                        {loading ? (
                          <Spinner animation="border" variant="light" size="sm" />
                        ) : (
                          "Verify OTP"
                        )}
                      </button>
                    </div>
                  </Form>
                </div>
              </Card>
            </div>
          </div>
        );
      }
    }


export default ChangePassword
