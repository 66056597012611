import { useFormikContext } from 'formik';
import React, { useState} from 'react'
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";

export default function Select({ name, label, placeholder, options, defaultVal, value, index}) {
  const { handleChange, values, errors } =  useFormikContext()
  const [err, setErr] = useState(false)

  const getErrorMessage = (name) => {
    if(errors?.education?.[index]){
      const key = name.split(`[${index}].`)[1]
      return errors?.education?.[index][key]
    }

    return errors[name]
  }

  const  handleBlur = (e) => {
    const { name } = e.target;
    if(errors[name]){
      setErr(true)
    }
  }

  return (
    <div>
      <FloatingLabel label={label} className="mb-3">
        <Form.Select
          name={name}
          onChange={handleChange}
          isInvalid={err && errors[name]}
          value={value ? value : values[name]}
          aria-label={placeholder}
          isValid={values[name] && !errors[name]}
          onBlur={handleBlur}        
        >
          <option defaultValue="" selected>{defaultVal}</option>
          { options.map((option, ind) => (
            <option key={option + ind} value={option}>
              {option}
            </option>
        ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {getErrorMessage(name)}
        </Form.Control.Feedback>
      </FloatingLabel>
    </div>
  )
}
