import React, { Component } from "react";
import NewDashboardLayout from '../components/NewDashboardlayout';
import moment from "moment";
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";

const baseUrl = process.env.REACT_APP_BASE_URL

class NewPremises extends Component {
    constructor(props) {
        super(props);
        this.state = {
          data: [],
          error: false,
          isLoading: true,
          currentPage: 1,
          postsPerPage: 15,
					noRecords: false
        }
      }
    
      showTable = () => {
        const { postsPerPage, currentPage, data } = this.state;
        const indexOfLastPost = currentPage * postsPerPage;
        const indexOfFirstPost = indexOfLastPost - postsPerPage;
        const currentPosts = data.reverse().slice(indexOfFirstPost, indexOfLastPost);
    
        try {
          return currentPosts.map((item, index) => {
    
						console.log(item, "===========================   Malam honourable and noble");
						
            return (
              <tr>
                <td>{item?.organisationname}</td>
                <td className="d-none d-xl-table-cell">{item?.contactphone}</td>
                <td className="d-none d-md-table-cell"> {item?.addressstreet}</td>
                <td className="d-none d-md-table-cell">{item.inspectionstatus.toLowerCase() === "approved" ? 'badge bg-success mt-2' : item.inspectionstatus.toLowerCase()=="pending" ? "badge bg-warning text-dark mt-2" : 'btn bg-danger text-light font-weight-bold mt-2'}>{(item.inspectionstatus)}</td>
                <td className="d-none d-md-table-cell">{moment(item.transactiontime).format('LL') === "Invalid date" ? null :moment(item.transactiontime).format('LL') }</td>
                <td><span className="badge bg-warning"> {moment(item.inspectiondate).format('LL') === "Invalid date" ? null :moment(item.inspectiondate).format('LL') }</span></td>
                <td className="d-none d-md-table-cell font-weight-bolder">...</td>
              </tr>
            );
          });
        } catch (e) {
          Swal.fire({
            title: "Error",
            text: e.message,
            type: "error",
          });
        }
      };
    
      getInspections = async () => {
        this.setState({ isLoading: true})
        let obj = {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        // console.warn(localStorage.getItem('userid'));
         await fetch(`${baseUrl}/Inspection/getInspectionByUserID/${localStorage.getItem('userid')}`, obj)
          .then((response) => response.json())
          .then((responseJson) => {
            console.warn(responseJson);
            if (responseJson.status === 401) {
                this.setState({ isLoading: false });
                Swal.fire({
                  title: "Session Expired",
                  text: "Session expired. Please login",
                  icon: "error",
                  confirmButtonText: "OK",
                }).then(() => {
                  this.props.history.push("/login");
                });
              }else if(responseJson.length === 0){
                this.setState({ noRecords: true, isLoading: false })
              }else {
              this.setState({data: responseJson, isLoading: false})
              }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      }
    
      componentDidMount() {
        this.getInspections();
      }

      // getUser = async () => {
      //   const url = `${baseUrl}/Users/id/${localStorage.getItem('userid')}`;
      //   // this.setState({isLoading: true});
      //   await fetch(url, {
      //     method: 'GET',
      //     headers: {
      //       Accept: "application/json",
      //       "Content-Type": "application/json",
      //       Authorization: "Bearer " + localStorage.getItem("token"),
      //     },
      //   })
      //     .then(res => res.json())
      //     .then(res => {
      //      console.warn(res);
      //      this.setState({email: res.email, phone: res.telephone})
      //     })
      //     .catch(error => {
      //       this.setState({error: true, loading: false});
      //       alert(error);
      //     });
      // }
    
      render() {
        return (
          <NewDashboardLayout PageName="Premises">
            <div className="row">
              <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                <div className="card flex-fill" >
                  <div className="d-flex justify-content-between" style={{backgroundColor: "rgb(6 38 1)"}}>
										<div className="py-1 mt-4 mx-4">
											<h6 className="text-sm font-weight-normal mb-1">
												<Link to="/new-apply-premises-inspection" className="btn btn-warning font-weight-bold">Apply For Premises</Link>
											</h6>
										</div>
                    <div className='form-label-group w-25 mt-4 mx-4 text-secondary'>
                      <input type="text"id="inputPassword" className="form-control shadow-none py-2 pl-2 pr-6 rounded-2" placeholder="Search" />
                      <label for="inputPassword" />
                    </div>
                  </div>
                  <table className="table table-hover my-0">
                    <thead>
                      <tr classNameName='px-2'>
                        <th>Name</th>
                        <th className="d-none d-xl-table-cell">Phone</th>
                        <th className="d-none d-xl-table-cell">Address</th>
                        <th>Status</th>
                        <th className="d-none d-md-table-cell">Application Date</th>
                        <th className="d-none d-md-table-cell">Inspection Date</th>
                        <th className="d-none d-md-table-cell">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.showTable()}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </NewDashboardLayout>
        )
      }
}

export default NewPremises;
