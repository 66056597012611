import React, { Component } from 'react';
import { Spinner } from "react-bootstrap";
import Footer from "../components/Footer";
import Sidebar from '../components/Sidebar';

class Logout extends Component {

  componentDidMount(){
    localStorage.clear();
    if(localStorage.getItem("token") === null ){
      this.props.history.push("/login")
    }
  }

  render() {
    return (
      <div className="container">
        <Sidebar />
         <div className="text-center mt-6 d-flex justify-content-center">
        <Spinner animation="border" variant="danger" size="lg" />
        </div>
      </div>
    );
  }
}

export default Logout;
