import React, { PureComponent } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import Swal from "sweetalert2";
import Footer from "../components/Footer";
 
import remita from "../assets/images/remita.png";
import Sidebar from '../components/Sidebar';
// import SHA512 from 'crypto-js/sha512';
let CryptoJS = require("crypto-js");


const baseUrl = process.env.REACT_APP_BASE_URL


class FoodScientistRenwalPayment extends PureComponent {
  constructor(props){
    super(props);
    this.state = {
      rrr : "",
      isLoading : false,
      loading: false,
      paymentDescription: null,
      feeType: [],
      fees: "",
    }
  }

  componentDidMount(){
    this.getFeeType();
  }

  getFeeType = async () => {
    const url = `${baseUrl}/Fees/getFeesByPaymentType/individual`;
    this.setState({isLoading: true});
    await fetch(url, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(res => res.json())
      .then(res => {
        if(res.status === 401){
          Swal.fire({
            title: "Session Expired",
            text: "Session expired. Please login",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            this.props.history.push("/login");
          });
        }else{
        this.setState({ feeType: res })
        // console.warn(res);
        res.map((item) => {
          if(item.description === "Membership/Individual Fee"){
            localStorage.setItem("description", item.description)
            localStorage.setItem("remita-id", item.remitaid)
            localStorage.setItem("amount", item.amount)
            localStorage.setItem("payment_category", item.paymentcategory)
            localStorage.setItem("paymentsubcategory", item.paymentsubcategory)
            this.makeRemitaPayment();
            // console.warn(item.amount);
            // console.warn(item.description);
            // console.warn(item.remitaid);
          }
        })
      }
      })
      .catch(error => {
        this.setState({error: true, loading: false});
        alert(error);
      });
  }


  makeRemitaPayment = () => {
    let merchantId = "9554487021";
    let apiKey = "520436"
    let serviceTypeId = localStorage.getItem("remita-id")
    let d = new Date();
    let orderId = d.getTime();
    let totalAmount = localStorage.getItem("amount");
    let apiHash = CryptoJS.SHA512(merchantId+serviceTypeId+orderId+totalAmount+apiKey);

    let req = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `remitaConsumerKey=${merchantId},remitaConsumerToken=${apiHash}`,
      },
      body: JSON.stringify({
        "serviceTypeId": `${serviceTypeId}` ,
        "amount": `${totalAmount}`,
        "orderId": orderId,
        "payerName": `${localStorage.getItem("othername")} ${localStorage.getItem("surname")}`,
        "payerEmail": localStorage.getItem("email"),
        "payerPhone": localStorage.getItem("phone"),
        "description": localStorage.getItem("description")
      }),
    };

    fetch(`https://login.remita.net/remita/exapp/api/v1/send/api/echannelsvc/merchant/api/paymentinit`, req)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 401) {
            this.setState({ loading: false });
            Swal.fire({
              title: "Session Expired",
              text: "Session expired. Please login",
              icon: "error",
              confirmButtonText: "OK",
            }).then(() => {
              this.props.history.push("/login");
            });
          }
        else if(responseJson.status === "Payment Reference generated"){
          this.setState({rrr: responseJson.RRR})
          console.warn(responseJson);
          localStorage.setItem("rrr", responseJson.RRR)
          this.initializePayment()
        }
      })
      .catch((error) => {
        this.setState({ loading: false, disabled: false });
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  }


 showFeeTypes(){
   return this.state.feeType.map((item) => {
     return (
       <center>
       <h4 className="text-center">{item.description === "Membership/Individual Fee" ? `${item.description.toUpperCase()} (N${item.amount})` : ''}</h4>
       </center>
   )
   });
 }


  initializePayment = async () => {

    let d = new Date();
    let orderId = d.getTime();

     const obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      body: JSON.stringify({
        amount: localStorage.getItem("amount"),
        description: localStorage.getItem("description"),
        formid: localStorage.getItem("formid"),
        formtype: localStorage.getItem("userid") ? "renewal" : "registration",
        message: "Payment Initialized",
        orderid: orderId,
        payer: `${localStorage.getItem("othername")} ${localStorage.getItem("surname")}`,
        paymentcategory: localStorage.getItem("payment_category"),
        paymentid: "string",
        paymentsubcategory: localStorage.setItem("paymentsubcategory"),
        rrr: localStorage.getItem("rrr"),
        userid: localStorage.getItem("userid")
      }),
    };
    await fetch(`${baseUrl}/Payments/initPayment`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        console.warn(responseJson);
        if (responseJson.status === 401) {
            this.setState({ loading: false });
            Swal.fire({
              title: "Session Expired",
              text: "Session expired. Please login",
              icon: "error",
              confirmButtonText: "OK",
            }).then(() => {
              this.props.history.push("/login");
            });
          } else {
            // this.retrievePayments();
          }
      })
      .catch((error) => {
        this.setState({ loading: false, disabled: false });
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };


  render(){
    const { isLoading } = this.state;
    return(
      <div className="container">

      <Sidebar />

      <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
  <div className="bg-light card mx-auto mt-5" style={{width: '20%', padding: 36}}>
  <img className="card-img category-img" src={remita} alt="Remita Payment" />
  </div>

  <div className="container" style={{ padding: 13, position: 'relative', right: -360, top: 45}}>

  <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
    <label
      style={{ color: "black" }}
      className="form-label"
    >
      Payment Information <span className="text-danger">*</span>
    </label>
    <div className="input-group input-group-outline mb-3">
      <label className="form-label"></label>

        <center>
        {this.showFeeTypes()}
        </center>
    </div>
    <a className="btn btn-success form-control" target="_blank" href={process.env.PUBLIC_URL + "remita.html"} style={{ padding: 13,}} >Click Here to Pay With Remita</a>
    </div>
    </div>
     <div style={{marginTop: 63, marginBottom: 18}} />
       <Footer />
   </main>
      </div>
    )
  }
}

export default FoodScientistRenwalPayment
