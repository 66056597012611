import PersonalInformationV3 from "./PersonalInformationV3";
import Qualifications from "./Qualifications";
import WorkInformation from "./WorkInformationV3";
import PaymentInformation from "./PaymentInformation"; 
import Summary from "./Summary";
import * as yup from "yup";

function calculateAge(dateOfBirth) {
  const dob = new Date(dateOfBirth);
  const currentDate = new Date();
  const age = currentDate.getFullYear() - dob.getFullYear();

  if (
    currentDate.getMonth() < dob.getMonth() ||
    (currentDate.getMonth() === dob.getMonth() &&
      currentDate.getDate() < dob.getDate())
  ) {
    return age - 1; // Subtract 1 if the birthday hasn't occurred yet
  }

  return age;
}

let defaultFormData = {
  accountInformation: {
    email: "",
    phone: "",
    // membershipType: "",
    password: "",
    confirmPassword: "",
    // mailingAddress: "",
  },
  personalInformation: {
    title: "",
    surname: "",
    otherNames: "",
    previousSurname: "",
    dob: "",
    gender: "",
    nationality: "Nigerian",
    state: "",
    lga: "",
    lgas: [],
    contactAddress: "",
    // passportPhoto: "",
  },
  workInformation: {
    organizationName: "",
    organizationPosition: "",
    organizationTelephone: "",
    organizationEmail: "",
    organizationAddress: ""
  },
  payment: {
    paymentType: "",
    agreement: false,
  },
  qualification: {
    categoryOfPractice: "",
    nifstNumber: "",
    nifstCertificateImage: "",
    education: [
      {
        courseOfStudy: "",
        institution: "",
        qualification: "",
        year: "",
        certificateImage: "",
      },
    ],
  },
};

const getFormSteps = (formData) => {
  return [
    {
      id: "personalInformation",
      endpoint: "register-personal-information",
      icon: "fas fa-question",
      component: <PersonalInformationV3 />,
      initialValues: formData?.personalInformation,
      validationSchema: yup.object().shape({
        title: yup.string().required("please select a title"),
        surname: yup.string().required("please provide your surname"),
        otherNames: yup.string().required("please provide your other name(s)"),
        previousSurname: yup.string().notRequired(),
        dob: yup
          .date()
          .max(new Date(), "Date of birth cannot be in the future")
          .required("Date of birth is required"),
        gender: yup.string().required("please select your gender"),
        nationality: yup.string().required("please provide nationality"),
        state: yup.string().required("please select your state of origin"),
        lga: yup.string().required("LGA is required when State is filled"),
        contactAddress: yup.string().required("Contact address is required"),
        passportPhoto: yup
          .string()
          .nullable()
          .required("please select a recent passport photograph"),
      }),
    },
    {
      id: "qualification",
      endpoint: "register-qualifications",
      icon: "fas fa-certificate",
      component: <Qualifications />,
      initialValues: formData?.qualification,
      validationSchema: yup.object().shape({
        categoryOfPractice: yup
          .string()
          .required("please provide your category of practice"),
        nifstNumber: yup.string().when("dob", {
          is: (dob) => calculateAge(dob) < 60,
          then: (schema) => schema.required("please provide NIFST number"),
          otherwise: (schema) => schema.notRequired(),
        }),
        nifstCertificateImage: yup.string().notRequired(),
        education: yup.array().of(
          yup.object().shape({
            courseOfStudy: yup
              .string()
              .required("please provide your course of study"),
            institution: yup
              .string()
              .required("please provide the name of the institution"),
            qualification: yup
              .string()
              .required("please select your qualification"),
            year: yup
              .string()
              .required("please provide the year of completion"),
            certificateImage: yup
              .string()
              .nullable()
              .required("please select the certificate image"),
          })
        ),
      }),
    },
    {
      id: "workInformation",
      endpoint: "register-work-information",
      icon: "fas fa-briefcase",
      component: <WorkInformation />,
      initialValues: formData?.workInformation,
      validationSchema: yup.object().shape({
        organizationName: yup
          .string()
          .notRequired(),
        organizationPosition: yup.string().notRequired(),
        organizationTelephone: yup.string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .notRequired(),
        organizationEmail: yup
          .string()
          .email("please provide a valid email address")
          .notRequired(), 
        organizationAddress: yup.string().notRequired(),
      }),
    },
    {
      id: "summary",
      endpoint: "initiate-payment",
      icon: "far fa-sticky-note",
      component: <Summary />,
    },
    {
      id: "PaymentInformation",
      endpoint: "complete-registration",
      icon: "far fa-credit-card",
      component: <PaymentInformation />,
      initialValues: formData.workInformation,
      validationSchema: yup.object().shape({
        paymentType: yup.string().required("please select a payment type"),
        agreement: yup
          .bool()
          .required("please confirm that all information is correct")
          .oneOf([true], "terms must be accepted"),
      }),
    },
  ];
};

export { getFormSteps, defaultFormData };
