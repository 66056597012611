import React, { PureComponent } from 'react';

 

class Portal extends PureComponent {

  componentDidMount(){
    window.location.replace('https://portal.nicfost.gov.ng');
  }
  render(){
    return(
      <div>

      </div>
    )
  }
}

export default Portal
