import React, { Component } from 'react';
import Footer from "../components/Footer";
import { Spinner } from "react-bootstrap";
import Sidebar from '../components/Sidebar';
import Swal from "sweetalert2";
let CryptoJS = require("crypto-js");


class VerifyPayments extends Component{

constructor(props) {
  super(props);
  this.state = {
    rrr: "",
    loading: false,
  }
}

checkPaymentStatus = async () => {
  if(this.state.rrr === ""){
    Swal.fire({
      title: "Error!",
      text: 'Please enter a valid RRR Number',
      icon: "error",
      confirmButtonText: "OK",
    });
  }else{
  this.setState({loading: true});
  let merchantId = "2547916";
  let apiKey = "1946"
  let rrr = this.state.rrr;
  let apiHash = CryptoJS.SHA512(rrr + apiKey + merchantId);

  let req = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `remitaConsumerKey=${merchantId},remitaConsumerToken=${apiHash}`,
    },
  };

  await fetch(`https://remitademo.net/remita/exapp/api/v1/send/api/echannelsvc/${merchantId}/${rrr}/${apiHash}/status.reg`, req)
    .then((response) => response.json())
    .then((responseJson) => {
      console.warn(responseJson);
      this.setState({loading: false});
      Swal.fire({
      title: '<strong>PAYMENT STATUS</u></strong>',
      icon: 'info',
      html:
        '<b>RRR:</b> ' + responseJson.RRR + '<br/><br/>'+
        '<b>AMOUNT:</b> ' +responseJson.amount+ '<br/><br/>'+
        '<b>STATUS:</b> ' + responseJson.message+ '<br/><br/>'+
        '<b>DATE:</b> ' + responseJson.transactiontime,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText:
        'OK',
      confirmButtonAriaLabel: 'Thumbs up, great!',
      cancelButtonText:
        '<i class="fa fa-thumbs-down"></i>',
      cancelButtonAriaLabel: 'Thumbs down'
})
    })
    .catch((error) => {
      this.setState({ loading: false, disabled: false });
      Swal.fire({
        title: "Error!",
        text: error.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    });
  }
}

  render() {
    const { loading, disabled } = this.state;
    return (
      <div className="g-sidenav-show">
       <Sidebar />
       <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg " style={{width: '80%', float: 'right'}}>
       <div class="container-fluid px-4">
         <div class="rown">
           <div class="col-12">
             <div class="card my-3">
               <div class="card-header pb-0">
                 <div class="d-flex flex-wrap align-items-center justify-content-between">
                   <h5>Verify Payment</h5>
                 </div>
               </div>
               <div class="card-body">
               <form id="payment-form">
                  <div class="form-floating mb-3 mt-3">
                     <input type="text" class="form-control" onChange={(e) =>
                       this.setState({ rrr: e.target.value })
                     } placeholder="Enter RRR"/>
                     <label for="rrr">Enter RRR</label>
                  </div>
                  <button
                    disabled={ disabled }
                    style={{
                      alignSelf: "center",
                      width: "100%",
                      backgroundColor: "#003314",
                    }}
                    className="btn btn-success btn-lg"
                    onClick={(e) => this.checkPaymentStatus(e)}
                  >
                    {loading ? (
                      <Spinner animation="border" variant="light" size="sm" />
                    ) : (
                      <span className="font-weight-bold">
                        {/* APPLY <i class="fas fa-chevron-right"></i> */}
                        VERIFY PAYMENT
                      </span>
                    )}
                  </button>
                </form>
               </div>
               </div>
               </div>
               </div>
               </div>
                <Footer />
               </main>
               </div>
             );
  }
}

export default VerifyPayments;
