import React, { Component } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner, Card, FloatingLabel, Form } from "react-bootstrap";
import logo from "../assets/images/logo.png"
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
 

const baseUrl = process.env.REACT_APP_BASE_URL


class PasswordOTP extends Component{
    constructor(props) {
        super(props);
        this.state = {
          phone: "",
          countryid: "+234",
          userid: "",
          loading: false,
          disabled: false
        };
      }

      validatePhoneNumber = () => {
        const { phone } = this.state;
        if(phone !== ""){
          this.getUserPhone();
        }else{
          Swal.fire({
            title: "Error",
            text: "Please enter your phone number",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }

      getUserPhone = async () => {
        const { countryid, phone } = this.state;
        let val = phone.slice(1)
        // console.warn(val);
        localStorage.setItem("reset-phone", val);
        const url = `${baseUrl}/Users/telephone/${countryid}/${val}`;
        this.setState({loading: true});
        await fetch(url, {
          method: 'GET',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(res => res.json())
          .then(res => {
            // console.warn(res);
            if(res.message === "Data Retrieved"){
              this.setState({ userid: res.userid }, () => {
                localStorage.setItem("user-reset-id", res.userid)
                this.sendOTP();
              })
            }else{
              Swal.fire({
                title: "Error",
                text: "Invalid phone number",
                icon: "error",
                confirmButtonText: "OK",
              });
              this.setState({error: true, loading: false});
            }
          })
          .catch(error => {
            this.setState({error: true, loading: false});
            alert(error);
          });
      }


      sendOTP = async () => {
        const { userid, phone, countryid } = this.state;
        this.setState({loading: true, disabled: true})
        var obj = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            countryid: countryid,
            telephone: phone,
            userid: userid
          }),
        };
        await fetch(`${baseUrl}/Users/changePasswordOTP`, obj)
          .then((response) => response.json())
          .then((responseJson) => {
            // console.warn(responseJson);
            if (responseJson.message === "Verification Code generated successfully") {
              localStorage.setItem("code", responseJson.verificationcode)
              this.setState({ loading: false });
              this.props.history.push("/enter-otp")
            } else if (
              responseJson.message === "Index 0 out of bounds for length 0"
            ) {
              this.setState({ loading: false, disabled: false });
              Swal.fire({
                title: "Error!",
                text: "Unable to generate verification code.",
                icon: "error",
                confirmButtonText: "OK",
              });
            } else {
              this.setState({ loading: false, disabled: false });
              Swal.fire({
                title: "Error!",
                text: responseJson.message,
                icon: "error",
                confirmButtonText: "OK",
              });
            }
          })
          .catch((error) => {
            this.setState({ loading: false, disabled: false });
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      };



      render() {
        const { loading, disabled } = this.state;
        return (
          <div className="loginContainer">
            <div
              className="d-flex justify-content-center"
              style={{
                backgroundColor: "rgba(0,0,0,0.7)",
                width: "100%",
                position: "absolute",
                top: 0,
                bottom: 0,
              }}
            >
              <Card style={{ backgroundColor: 'rgba(255,255,255,0.7)' }} className="d-flex justify-content-center cardContainer loginContainer-content">
                <img
                  src={logo}
                  style={{
                    width: "15%",
                    position: "absolute",
                    top: 25,
                    alignSelf: "center",
                  }}
                />

                <h5 className="nicfostText" style={{position: 'relative', fontWeight:'bold', top: 5, color:'#333', textAlign:'center'}}>NIGERIAN COUNCIL OF FOOD SCIENCE AND TECHNOLOGY<br/>(NiCFoST)</h5>
                  <div className="mb-3"></div>
                <h6 className="nicfostText" style={{position:'relative', marginLeft: 13, textAlign:'center', top: 45}}>Enter your registered phone number</h6>

                <div className="container" style={{ marginTop: 50 }}>
                  <Form>
                    <Form.Floating className="mb-3">
                      <input
                        className="form-control shadow-none"
                        type="phone"
                        id="phone"
                        onChange={(e) => this.setState({ phone: e.target.value })}
                      />
                      <label htmlFor="phone">Phone</label>
                    </Form.Floating>

                    <div className="d-grid gap-2 mb-3" style={{ marginTop: 30 }}>
                      <button
                        style={{backgroundColor: '#003314'}}
                        type="button"
                        className="btn btn-success btn-lg"
                        onClick={(e) => this.validatePhoneNumber()}
                        disabled={disabled}
                      >
                        {loading ? (
                          <Spinner animation="border" variant="light" size="sm" />
                        ) : (
                          "Send OTP"
                        )}
                      </button>
                    </div>
                  </Form>

                </div>
              </Card>
            </div>
          </div>
        );
      }
    }


export default PasswordOTP
