import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Alert from "react-bootstrap/Alert";
import { useFormikContext } from "formik";


export default function PaymentInformation() {
  const { values, handleChange, errors } = useFormikContext();


  return (
    <Container>
      <h4>Payment</h4>
      <Row>
        <Col md={12}>
          <Alert variant="light">
            <Alert.Heading>NOTE</Alert.Heading>
            <p>
              NiCFoST SHALL MAKE A CLAIM AND RECOVER ALL COSTS OF LITIGATION
              INCURRED BY IN DEFENSE OF ANY COURT ACTION INSTITUTED AGAINST IT
              AT THE INSTANCE OF ANY CERTIFIED FOOD SCIENTIST OF NIGERIA AND/OR
              LICENSED FOOD BUSINESS PREMISES AND WHEREBY THE SUIT IS STRUCK
              OUT, WITHDRAWN OR THE FOOD SCIENTIST OF NIGERIA OR THE FOOD
              BUSINESS PREMISES LOSES THE CASE.
            </p>
          </Alert>
        </Col>

        <Col md={12} className="mb-3">
  
        </Col>
      </Row>
    </Container>
  );
}
