import React, { useRef, useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import AvatarPlaceholder from "../../assets/images/avatar.jpg";
import { useFormikContext } from "formik";
import { states } from "../../assets/data/json-data";
import { Input, Select } from "../UI";

export default function PersonalInformation() {
  const { values, setValues, errors } = useFormikContext();
  const formContext = useFormikContext();
  const passportPhotoRef = useRef(null);
  const [lgas, setLgas] = useState([]);
  const [showModal, setShowModal] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [fileLabel, setFileLabel] = useState("");

  useEffect(() => {
    const state = states.find((state) => state.name === values?.state);
    if (state) setLgas(state.locals);
  }, [values?.state]);

  const handleFileChange = (e) => {
    const file = e.target?.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        setPreviewImage(reader.result);
        passportPhotoRef.current.src = reader.result;
        setValues((initVal) => ({ ...initVal, passportPhoto: base64String }));
        window.sessionStorage.setItem("passportPhoto", reader.result);
      };

      reader.readAsDataURL(file);
      setFileLabel(file?.name);
    }
  };

  const titleOptions = ["Mr.", "Mrs.", "Ms.", "Dr.", "Prof."];
  const stateOptions = states.map((state) => state.name);
  const lgaOptions = lgas.map((lgas) => lgas.name);

  const ongoingPics = JSON.parse(
    window.sessionStorage.getItem("registration:ongoing")
  )?.personalInformation?.passportPhoto;

  return (
    <>
      <Row>
        <h4>Personal Information</h4>
      </Row>
      <Row>
        <Col md={8} className="border-end">
          <Row>
            <Col md={6}>
              <Select
                name="title"
                label="Title *"
                placeholder="Selet Title *"
                defaultVal="Select Title"
                options={titleOptions}
                formContext={formContext}
              />
            </Col>
            <Col md={6}>
              <Input
                name="surname"
                type="text"
                label="Surname *"
                placeholder=""
                formContext={formContext}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Input
                name="otherNames"
                type="text"
                label="Other Name(s) *"
                placeholder="Other Name(s) *"
                formContext={formContext}
              />
            </Col>
            <Col md={6}>
              <Input
                name="previousSurname"
                type="text"
                label="Previous Surname (if applicable)"
                placeholder="Previous Surname (If applicable)"
                formContext={formContext}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Input
                name="dob"
                type="date"
                label="Date Of Birth *"
                placeholder="Date Of Birth *"
                formContext={formContext}
              />
            </Col>
            <Col md={6}>
              <Select
                name="gender"
                type="text"
                label="Gender *"
                placeholder="Gender *"
                defaultVal="Select Gender"
                options={["Male", "Female"]}
                formContext={formContext}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Input
                name="nationality"
                type="text"
                label="Nationality *"
                placeholder="Nationality *"
                formContext={formContext}
              />
            </Col>
            <Col md={6}>
              <Select
                name="state"
                type="text"
                label="State of Origin *"
                placeholder="State of Origin *"
                defaultVal="Select State"
                options={stateOptions}
                formContext={formContext}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Select
                disabled={true}
                name="lga"
                type="text"
                label="LGA *"
                placeholder="LGA *"
                defaultVal="Select LGA"
                options={lgaOptions}
                formContext={formContext}
              />
            </Col>
            <Col md={6}>
              <Input
                name="contactAddress"
                type="text"
                label="Contact Address *"
                placeholder="Contact Address *"
                formContext={formContext}
              />
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>
              Recent Passport Photograph (png/jpg) *
              <div
                className="mt-2"
                style={{ height: "320px" }}
                onClick={() => setShowModal(true)}
              >
                <Image
                  ref={passportPhotoRef}
                  alt="passport photograph"
                  className="h-100 d-inline-block cursor-pointer"
                  src={
                    previewImage ||
                    (ongoingPics?.includes(process.env.REACT_APP_BASE_URL) &&
                      ongoingPics) ||
                    AvatarPlaceholder
                  }
                  thumbnail
                />
              </div>
            </Form.Label>
            <Form.Control
              name="passportPhoto"
              isValid={values.passportPhoto}
              onChange={handleFileChange}
              isInvalid={!!errors.passportPhoto}
              label={fileLabel}
              accept="images/*"
              type="file"
            />
            <Form.Control.Feedback type="invalid">
              {errors.passportPhoto}
            </Form.Control.Feedback>
          </Form.Group>

          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Passport Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {showModal && (
                <Image
                  className="h-100 w-100"
                  src={
                    previewImage ||
                    window.sessionStorage.getItem("passportPhoto") ||
                    AvatarPlaceholder
                  }
                  fluid
                />
              )}
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
    </>
  );
}
