import React, { Component } from "react";
// import "../App.css";

class Footer extends Component {
  render() {
    return (
      <footer>
        {/* <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="">
                <h6 className="footer-heading text-uppercase text-white">
                  Information
                </h6>
                <ul className="list-unstyled footer-link mt-4">
                  <li>
                    <a href="https://www.nicfost.gov.ng/?page_id=387">
                      NICFOST Act
                    </a>
                  </li>
                  <li>
                    <a href="https://www.nicfost.gov.ng/?page_id=393">Vision</a>
                  </li>
                  <li>
                    <a href="https://www.nicfost.gov.ng/?page_id=393">
                      Mission
                    </a>
                  </li>
                  <li>
                    <a href="https://www.nicfost.gov.ng/?page_id=399">
                      Mandate
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="">
                <h6 className="footer-heading text-uppercase text-white">
                  Ressources
                </h6>
                <ul className="list-unstyled footer-link mt-4">
                  <li>
                    <a href="https://www.nicfost.gov.ng/?page_id=325">
                      Downloads{" "}
                    </a>
                  </li>
                  <li>
                    <a href="https://www.nicfost.gov.ng/?page_id=325">
                      Photo Gallery
                    </a>
                  </li>
                  <li>
                    <a href="https://www.nicfost.gov.ng/?page_id=325">Events</a>
                  </li>
                  <li>
                    <a href="">Zeeko API</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-2">
              <div>
                <h6 className="footer-heading text-uppercase text-white">
                  Help
                </h6>
                <ul className="list-unstyled footer-link mt-4">
                  <li>
                    <a href="">Legal Unit </a>
                  </li>
                  <li>
                    <a href="">ICT Unit</a>
                  </li>
                  <li>
                    <a href="">Internal Audit</a>
                  </li>
                  <li>
                    <a href="">Media/PR Unit</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="">
                <h6 className="footer-heading text-uppercase text-white">
                  Contact Us
                </h6>
                <p className="contact-info mt-4">
                  Plot 1234, Ibrahim Badamasi Babangida Way, Off Mohamadu Buhari
                  Way, Maitama, Abuja.
                </p>
                <p className="contact-info text-light">08012345678</p>
                <p className="contact-info text-light">08012345678</p>
                <p className="contact-info text-light">08012345678</p>
                <div className="mt-5">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <a href="#">
                        <i className="fab facebook footer-social-icon fa-facebook-f"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#">
                        <i className="fab facebook footer-social-icon fa-facebook-f"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#">
                        <i className="fab facebook footer-social-icon fa-facebook-f"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#">
                        <i className="fab facebook footer-social-icon fa-facebook-f"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}

            {/* <div className="text-center mt-5"> */}
              <p className="footer-alt f-14 text-center text-dark font-weight-bold pb-5">2024 © NiCFoST | All Rights Reserved</p>

          </footer>
        )
    }
}

export default Footer;
