import { useState, useEffect } from "react";
import { useWizard, Wizard } from "react-formik-step-wizard";
import WizardTab from "./WizardTab";

export default function WizardHeader({ title, subtitle, steps }) {
  const wizard = useWizard();

  const color = "white";
  const shape = "citcle";
  const stepSize = "md";

  const { activeStep, totalSteps, stepNumber } = wizard;
  const [progressBarStyle, setProgressBarStyle] = useState({
    backgroundColor: "white",
    width: "0%",
    color: "white",
  });

  useEffect(() => {
    setProgressBarStyle({
      ...progressBarStyle,
      width: `${(stepNumber / totalSteps) * 100}%`,
    });
  }, [stepNumber]);

  const renderTabs = () => {
    return steps.map((step, index) => {
      const isActive = index === stepNumber - 1;
      let title = step.id.replace(/([A-Z])/g, " $1").trim();
      let icon = step?.icon;

      return (
        <WizardTab
          key={index}
          title={title}
          icon={icon}
          shape={shape}
          color={color}
          isActive={isActive}
          index={index}
        />
      );
    });
  };

  return (
    <div className={`react-form-wizard ${stepSize} horizontal`}>
      <div className="wizard-header bg-success py-4">
        {typeof title === "string" ? (
          <>
            <h3 className="wizard-title">{title}</h3>
            <h3 className="wizard-title">{subtitle}</h3>
          </>
        ) : (
          title
        )}
      </div>
      <div className="wizard-navigation bg-success">
        <div className="wizard-progress-with-circle">
          <div className="wizard-progress-bar" style={progressBarStyle}></div>
        </div>
        <ul
          className={`form-wizard-steps wizard-nav wizard-nav-pills ${shape} ${stepSize} pb-4`}
        >
          {renderTabs()}
        </ul>
      </div>
      <div className="wizard-tab-content border">{activeStep.component}</div>
    </div>
  );
}
