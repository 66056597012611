import React from "react";
import { Row, Col } from "reactstrap";
import { useWizard } from "react-formik-step-wizard";
import { useFormikContext } from "formik";

export default function Summary() {
  const context = useFormikContext();

  const wizard = useWizard();
  const values = {
    personalInformation: wizard.values.personalInformation,
    workInformation: wizard.values.workInformation,
    qualification: wizard.values.qualification,
  };

  function capitalizeFirstLetter(name) {
    if (name && typeof name === "string") {
      return name.charAt(0).toUpperCase() + name.slice(1);
    } else {
      return name;
    }
  }

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    if (isNaN(date.getTime())) {
      console.error("Invalid date format");
      return null;
    }
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    // Format as DD-MM-YYYY
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }


  return (
    <>
      <Row>
        <Col md={6}>
          <section className="font-weight-bold">
            <h3>Personal Information</h3>

            <p className="font-weight-normal">
              <strong>Title: </strong>{" "}
              {values.personalInformation &&
                values.personalInformation.title &&
                values.personalInformation.title}
            </p>

            <p className="font-weight-normal">
              <strong>Surname: </strong>{" "}
              {values.personalInformation &&
                values.personalInformation.surname &&
                capitalizeFirstLetter(values.personalInformation.surname)}
            </p>

            <p className="font-weight-normal">
              <strong>Other Names: </strong>{" "}
              {values.personalInformation &&
              values.personalInformation.otherNames
                ? capitalizeFirstLetter(values.personalInformation.otherNames)
                : "N/A"}
            </p>

            <p className="font-weight-normal">
              <strong>Previous Surname: </strong>{" "}
              {values.personalInformation &&
              values.personalInformation.previousSurname
                ? capitalizeFirstLetter(
                    values.personalInformation.previousSurname
                  )
                : "N/A"}
            </p>

            <p className="font-weight-normal">
              <strong>Date of Birth: </strong>{" "}
              {values.personalInformation &&
                values.personalInformation.dob &&
                formatDate(values.personalInformation.dob)}
            </p>

            <p className="font-weight-normal">
              <strong>Gender: </strong>{" "}
              {values.personalInformation &&
                values.personalInformation.gender &&
                values.personalInformation.gender}
            </p>

            <p className="font-weight-normal">
              <strong>Nationality: </strong>{" "}
              {values.personalInformation &&
                values.personalInformation.nationality &&
                values.personalInformation.nationality}
            </p>

            <p className="font-weight-normal">
              <strong>State of Origin: </strong>{" "}
              {values.personalInformation &&
                values.personalInformation.state &&
                values.personalInformation.state}
            </p>

            <p className="font-weight-normal">
              <strong>Local Government Area: </strong>{" "}
              {values.personalInformation &&
                values.personalInformation.lga &&
                values.personalInformation.lga}
            </p>

            <p className="font-weight-normal">
              <strong>Contact Address: </strong>{" "}
              {values.personalInformation &&
                values.personalInformation.contactAddress &&
                values.personalInformation.contactAddress}
            </p>

          </section>
        </Col>
        <Col md={6}>
          <section>
            <h3>Work Information</h3>
            <p className="font-weight-normal">
              <strong>Name of Organization/Institution: </strong>{" "}
              {values.workInformation &&
                values.workInformation.organizationName &&
                capitalizeFirstLetter(values.workInformation.organizationName)}
            </p>
            <p className="font-weight-normal">
              <strong>Position: </strong>{" "}
              {values.workInformation && values.workInformation.organizationPosition
                ? capitalizeFirstLetter(values.workInformation.organizationPosition
                  )
                : "N/A"}
            </p>
            <p className="font-weight-normal">
              <strong>Organization Telephone / Others: </strong>{" "}
              {values.workInformation && values.workInformation.organizationTelephone
                ? values.workInformation.organizationTelephone
                : "N/A"}
            </p>
            <p className="font-weight-normal">
              <strong>Organization Email: </strong>{" "}
              {values.workInformation && values.workInformation.organizationEmail
                ? values.workInformation.organizationEmail
                : "N/A"}
            </p>
            <p className="font-weight-normal">
              <strong>Organizational Address: </strong>{" "}
              {values.workInformation && values.workInformation.organizationAddress
                ? values.workInformation.organizationAddress
                : "N/A"}
            </p>
          </section>

          <section>
            <h3>Qualification Information</h3>

            <p className="font-weight-normal">
              <strong>Category Of Practice </strong>{" "}
              {values.qualification &&
                values.qualification.categoryOfPractice &&
                values.qualification.categoryOfPractice}
            </p>

            <p className="font-weight-normal">
              <strong>NIFST Number</strong>{" "}
              {values.qualification &&
                values.qualification.nifstNumber &&
                values.qualification.nifstNumber}
            </p>

            <h5>Education</h5>

            {values?.qualification?.education?.length &&
              values?.qualification?.education?.map((obj) => (
                <>
                  <p className="font-weight-normal">
                    <strong>Course Of Study: </strong>{" "}
                    {obj.courseOfStudy &&
                      capitalizeFirstLetter(obj.courseOfStudy)}
                  </p>

                  <p className="font-weight-normal">
                    <strong>Institution: </strong>{" "}
                    {obj.institution && capitalizeFirstLetter(obj.institution)}
                  </p>
                  <p className="font-weight-normal">
                    <strong>Qualification: </strong>{" "}
                    {obj.qualification && obj.qualification}
                  </p>
                  <p className="font-weight-normal">
                    <strong>Year: </strong> {obj.year && obj.year}
                  </p>
                  <hr />
                </>
              ))}
          </section>
        </Col>
      </Row>
    </>
  );
}
