import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Alert, Form, Spinner } from "react-bootstrap";
 
import moment from 'moment';
import { states } from "../components/States";
import Footer from "../components/Footer";
import Sidebar from '../components/Sidebar';
import NumberFormat from 'react-number-format';
import logo from "../assets/images/logo.png";
import coat from "../assets/images/coat.png";
import profileImage from '../assets/images/invoice.jpeg';
let CryptoJS = require("crypto-js");

const baseUrl = process.env.REACT_APP_BASE_URL



class ManageInspections extends Component {

  constructor(props){
    super(props);
    this.state = {
      data: [],
      inspectionData: [],
      postsPerPage: 10,
      currentPage: 1,
      isLoading: false,
      isPremisesLoading: false,
      isInspectionLoading: false,
      isDisabled: false,
      noRecords: false,
      gender: '',
      processEquipm: '',
      productbrands: '',
      licenceNo: '',
      nifst: '',
      regNo: '',
      acceptConsent: false,
      email: '',
      phone: '',
      amount: '',
      description: '',
      remitaid: '',
      name: '',
      paymentCategory: '',
      paymentSubcategory: '',

      //COLOR VARIABLES
      colorGender: 'black',
      colorLicesNum: 'black',
      colorNicFostNo: 'black',
      colorNIFST: 'black'
    }
    this.handleGenderChange = this.handleGenderChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
  }

  handleGenderChange(e){
    this.setState({gender: e.target.value})
  }

  handleCheckChange(e) {
    this.setState({ acceptConsent: e.target.checked }, () => {
    });
  }

  // static getDerivedStateFromError(error){
  //    return this.setState({ hasError: true})
  // }
  //
  // componentDidCatch(error){
  //   console.log("error occurred")
  // }
  //
  // print(){
  //   window.print()
  // }

  showMessage = () => {
    // alert(Object.keys(this.state.inspe).length)
    {this.state.inspectionData.length === 0 &&
    Swal.fire({
      title: "Notice",
      text: "You need to apply for inspection before your premises registration can be approved.",
      icon: "warning",
      confirmButtonText: "OK",
    })
  }
  }

  registerPremises = async (value) => {
    let inputVal = value.split(",");
    this.setState({amount:inputVal[3], description:inputVal[9], remitaid: inputVal[10].trim(), name:inputVal[7], paymentCategory:inputVal[11], paymentSubcategory:inputVal[12]})
    // console.warn(inputVal[3]);
    // console.warn(inputVal[9]);

    let date = new Date();
    if(this.state.licenceNo === ""){
      Swal.fire({
        title: "Empty",
        text: "Please indicate your licence number",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ colorLicesNum: 'red' })
    }else if(this.state.nifst === ""){
      Swal.fire({
        title: "Empty",
        text: "Please specify your NiCFoST Reg Number",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ colorNIFST: 'red' })
    }else if(this.state.gender === ""){
      Swal.fire({
        title: "Empty",
        text: "Please specify your gender",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ colorGender: 'red' })
    }else if (this.state.acceptConsent === false) {
      Swal.fire({
        title: "Verify Accuracy",
        text: "Verify accuracy of your information by ticking the bos",
        icon: "error",
        confirmButtonText: "OK",
      });
    }else{
    this.setState({isPremisesLoading: true, isDisabled: true})
    var obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        applicationdate: date.getTime(),
        businessdescription: inputVal[5],
        businesstype: inputVal[6],
        companysize: inputVal[4].trim(),
        formtype: "registration",
        gender: this.state.gender,
        inspectionfees: inputVal[3],
        leadfoodscientistlicensenumber: this.state.licenceNo,
        leadfoodscientistname: inputVal[7],
        lga: inputVal[0],
        locationaddress: inputVal[8],
        nationality: "Nigeria",
        organisationname: inputVal[2],
        productbrands: this.state.productbrands,
        registrationnumber: this.state.nifst,
        stateofororigin: inputVal[1],
        userid: localStorage.getItem('userid')
      }),
    };
    fetch(`${baseUrl}/Premises/addPremises`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.warn(responseJson);
        if (
          responseJson.message == "New Premises added Successfully"
        ) {
          // this.initializePayment()
          this.makeRemitaPayment()
        }else if(responseJson.status == 401){
          this.props.history.push("/login")
        }
      })
      .catch((error) => {
        this.setState({isPremisesLoading: false, isDisabled: false});
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
    }
  };

  getUser = async () => {
    const url = `${baseUrl}/Users/id/${localStorage.getItem('userid')}`;
    // this.setState({isLoading: true});
    await fetch(url, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(res => res.json())
      .then(res => {
       console.warn(res);
       this.setState({email: res.email, phone: res.telephone})
      })
      .catch(error => {
        this.setState({error: true, loading: false});
        alert(error);
      });
  }

  makeRemitaPayment = () => {
    let merchantId = "9554487021";
    let apiKey = "520436"
    let serviceTypeId = parseFloat(this.state.remitaid);
    let d = new Date();
    let orderId = d.getTime();
    let totalAmount = `${this.state.amount}`;
    let apiHash = CryptoJS.SHA512(merchantId+serviceTypeId+orderId+totalAmount+apiKey);

    let req = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `remitaConsumerKey=${merchantId},remitaConsumerToken=${apiHash}`,
      },
      body: JSON.stringify({
        "serviceTypeId": `${serviceTypeId}` ,
        "amount": `${this.state.amount}`,
        "orderId": orderId,
        "payerName": `${this.state.name}`,
        "payerEmail": `${this.state.email}`,
        "payerPhone": `${this.state.phone}`,
        "description": `${this.state.description}`
      }),
    };

    fetch(`https://login.remita.net/remita/exapp/api/v1/send/api/echannelsvc/merchant/api/paymentinit`, req)
      .then((response) => response.json())
      .then((responseJson) => {
        console.warn(responseJson);
        if (responseJson.status === 401) {
            this.setState({ loading: false });
            Swal.fire({
              title: "Session Expired",
              text: "Session expired. Please login",
              icon: "error",
              confirmButtonText: "OK",
            }).then(() => {
              this.props.history.push("/login");
            });
          }
        else if(responseJson.status === "Payment Reference generated"){
          this.setState({rrr: responseJson.RRR})
          localStorage.setItem("rrr", responseJson.RRR);
          this.initializePayment()
        }
      })
      .catch((error) => {
        this.setState({ loading: false, disabled: false });
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  }

    initializePayment = async () => {
      let d = new Date();
      let orderId = d.getTime();

       const obj = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        body: JSON.stringify({
          amount: localStorage.getItem("amount"),
          description: this.state.description,
          formid: '',
          formtype: "registration",
          message: "Payment Initialized",
          orderid: orderId,
          payer: `${this.state.name}`,
          paymentcategory: `${this.state.paymentCategory}`,
          paymentid: "string",
          paymentsubcategory: `${this.state.paymentSubcategory}`,
          rrr: localStorage.getItem("rrr"),
          userid: localStorage.getItem("userid")
        }),
      };
      await fetch(`${baseUrl}/Payments/initPayment`, obj)
        .then((response) => response.json())
        .then((responseJson) => {
          console.warn(responseJson);
          if (responseJson.status === 401) {
              this.setState({ loading: false });
              Swal.fire({
                title: "Session Expired",
                text: "Session expired. Please login",
                icon: "error",
                confirmButtonText: "OK",
              }).then(() => {
                this.props.history.push("/login");
              });
            } else {
              Swal.fire({
                title: "Success",
                text: "Premises Registration Successful",
                icon: "success",
                confirmButtonText: "OK",
              }).then(()=> {
                window.location.replace("remita.html")
              })
              this.setState({isPremisesLoading: false, isDisabled: false})
            }
        })
        .catch((error) => {
          this.setState({ loading: false, disabled: false });
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    };



  getStates() {
    return states.map((state) => {
      return <option value={state}>{state}</option>;
    });
  }


  getUserInspectionDetails = (recid) => {
    const url = `${baseUrl}/Inspection/getInspectionByRecID/${recid}`;
    this.setState({isInspectionLoading: true});
    fetch(url, {
      method: 'GET',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(res => res.json())
      .then(res => {
        // console.warn(res);
        this.setState({
          isInspectionLoading: false,
          inspectionData: res,
        });
      })
      .catch(error => {
        this.setState({error: true, isInspectionLoading: false});
        alert(error);
      });
  }

  AlertDismissibleExample = () => {
      return (
        <div style={{ position: 'relative', top: 0, width: '100%', marginLeft: 0 }}>
        <Alert className="text-light font-weight-bold" variant="warning" onClose={() => this.toggleInfo()} dismissible>
          <Alert.Heading className="text-light">Important Information!</Alert.Heading>
          <p style={{fontWeight: 'bold'}}>
           EVERY REGISTERED FOOD SCIENTIST SHOULD SEND IMMEDIATE NOTIFICATION OF ANY CHANGE OF ADDRESS/DATA SUPPLIED TO THE REGISTRAR OF THE COUNCIL.
          </p>
        </Alert>
        </div>
      )
    }


  showPagination = () => {
  const { postsPerPage, data } = this.state;
  const pageNumbers = [];
  const totalPosts = data.length;
  for(let i = 1; i<= Math.ceil(totalPosts/postsPerPage); i++){
    pageNumbers.push(i)
  }

   const paginate = (pageNumbers) => {
     this.setState({currentPage: pageNumbers})
   }

    return(
      <nav>
      <ul className="pagination">
      {pageNumbers.map(number => (
        <li key={number} className={this.state.currentPage === number ? 'page-item active' : 'page-item'}>
        <button onClick={()=> paginate(number)} className="page-link">
          { number }
        </button>
       </li>
     ))}
      </ul>
      </nav>
    )
  }

  showTable = () => {
    const { postsPerPage, currentPage, data } = this.state;
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

    try {
      return currentPosts.map((item, index) => {
        let val = item.companysize.split(",")
        return (
            <tbody>
            <tr>
           <td>
           <span className="text-xs font-weight-bold">{postsPerPage * (currentPage -1)+index+1 }</span>
           </td>
           <td className="text-xs text-secondary text-capitalize font-weight-bold">{item.organisationname}</td>
           <td className="text-xs text-secondary text-capitalize font-weight-bold">{item.contactphone}</td>
           <td className="text-xs text-secondary text-capitalize font-weight-bold">{item.addressstreet}</td>
           <td className={item.inspectionstatus.toLowerCase() === "approved" ? 'badge bg-success mt-2' : item.inspectionstatus.toLowerCase()=="pending" ? "badge bg-warning text-dark mt-2" : 'btn bg-danger text-light font-weight-bold mt-2'}>{(item.inspectionstatus)}</td>
           <td className="text-xs font-weight-bold">{moment(item.transactiontime).format('LL') === "Invalid date" ? null :moment(item.transactiontime).format('LL') }</td>
           <td className="text-xs font-weight-bold">{moment(item.inspectiondate).format('LL') === "Invalid date" ? null :moment(item.inspectiondate).format('LL') }</td>
           <td>
          <button className="btn btn-primary-2 mb-0" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false"><span class="iconify" data-icon="charm:menu-meatball" style={{fontSize: 'large'}} ></span></button>
          <ul className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="#dropdownMenuButton2">

          <li className="mb-2" id ={`${item.recid}`}  onClick={() => this.getUserInspectionDetails(`${item.recid}`)} data-bs-toggle="modal" data-bs-target="#viewInspection">
            <a className="dropdown-item border-radius-md" href="javascript:;">
              <div className="d-flex py-1">
                  <h6 className="text-sm font-weight-normal mb-1">
                    <span className="font-weight-bold">View</span>
                  </h6>
              </div>
            </a>
          </li>


          {item.inspectionstatus.toLowerCase() === "approved" &&
          <li className="mb-2" data-bs-toggle="modal" data-bs-target="#addPremises">
            <a className="dropdown-item border-radius-md" href="javascript:;">
              <div className="d-flex py-1">
                  <h6 className="text-sm font-weight-normal mb-1">
                    <span className="font-weight-bold">Apply For Premises</span>
                  </h6>
              </div>
            </a>
          </li>
        }
        </ul>
        </td>
        <td></td>
     </tr>
     {/* Start of Apply For Premises */}
     <div class="modal fade" id="addPremises" tabindex="-1" aria-labelledby="addPremises" aria-hidden="true">
       <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
         <div class="modal-content">
           <div class="modal-header bg-success d-flex align-items-center justify-content-between">
             <h5 class="modal-title font-weight-bold text-light">APPLICATION FORM FOR REGISTRATION OF PREMISES</h5>
             <button type="button" class="btn btn-link m-0 p-0 text-light fs-4" data-bs-dismiss="modal" aria-label="Close"><span class="iconify" data-icon="carbon:close"></span></button>
           </div>
           <div class="modal-body">
           <div class="card-body">

             {this.AlertDismissibleExample()}

   <div className="container" style={{ marginTop: 0, padding: 9 }}>
     <div style={{ marginTop: 25 }}></div>
     <form className="row">
       <label
         className="mb-3 h4"
         style={{ color: "green" }}
         htmlFor="floatingInputCustom"
       >
         Personal Information
       </label>
       <br />
       <br />
       <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
         <label
           className="form-label text-dark"
         >
           Name of Lead Food Scientist
         </label>
         <div className="input-group input-group-outline mb-3">
           <label className="form-label"></label>
           <input
             className="form-control shadow-none"
             type="text"
             value={item.contactname}
           />
         </div>
       </div>

       <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
         <label
           style={{ color: this.state.colorLicesNum }}
           className="form-label"
         >
           Licence Number <span className="text-danger">*</span>
         </label>
         <div className="input-group input-group-outline mb-3">
           <label className="form-label"></label>
           <input
             className="form-control shadow-none"
             type="text"
             // defaultValue={localStorage.getItem("licence_number")}
             onChange={(e) =>
               this.setState({ licenceNo: e.target.value })
             }
           />
         </div>
       </div>

       <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
         <label
           style={{ color: this.state.colorNIFST }}
           className="form-label"
         >
           NiCFoST Registration Number <span className="text-danger">*</span>
         </label>
         <div className="input-group input-group-outline mb-3">
           <label className="form-label"></label>
           <input
             className="form-control shadow-none"
             type="text"
             // defaultValue={localStorage.getItem("reg_number")}
             onChange={(e) => this.setState({ nifst: e.target.value })}
           />
         </div>
       </div>

       <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
         <label
           className="form-label text-dark"
         >
           Nationality
         </label>
         <div className="input-group input-group-outline mb-3">
           <label className="form-label"></label>
           <input
             type="text"
             className="form-control shadow-none"
             value="Nigeria"
           />
         </div>
       </div>

       <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
         <label
           className="form-label"
           style={{ color: this.state.colorGender }}>
           Select Gender <span className="text-danger">*</span>
         </label>
         <div className="input-group input-group-outline mb-3">
           <label className="form-label"></label>
           <select
             className="form-control shadow-none"
             aria-label="Select"
             onChange={this.handleGenderChange}
           >
             <option selected disabled>
                -- Select gender --
             </option>
             <option value="male">Male</option>
             <option value="female">Female</option>
           </select>
         </div>
       </div>

       <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
         <label
           className="form-label text-dark"
         >
           Select State
         </label>
         <div className="input-group input-group-outline mb-3">
           <label className="form-label"></label>
            <input
            className="form-control shadow-none"
            type="text"
            value={item.addressstate}
            />
         </div>
       </div>

       <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
         <label
           className="form-label text-dark"
         >
           LGA
         </label>
         <div className="input-group input-group-outline mb-3">
           <label className="form-label"></label>
           <input
             className="form-control shadow-none"
             type="text"
             value={item.addresslga}
           />
         </div>
       </div>

       <hr />

       <div className="row" style={{ padding: 15 }}>
         <label
           className="mb-3 h4"
           style={{ color: "green" }}
           htmlFor="floatingInputCustom"
         >
           Orgnainzation Information
         </label>

         <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
           <label
             className="form-label text-dark"
           >
             Name of Organization / Company{" "}
           </label>
           <div className="input-group input-group-outline mb-3">
             <label className="form-label"></label>
             <input
               className="form-control shadow-none"
               type="phone"
               value={item.organisationname}
             />
           </div>
         </div>

         <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
           <label
             className="form-label text-dark"
           >
             Address of Business Premises{" "}
           </label>
           <div className="input-group input-group-outline mb-3">
             <label className="form-label"></label>

             <input
               className="form-control shadow-none"
               type="text"
               value={item.addressstreet} />
           </div>
         </div>

         <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
           <label
             className="form-label text-dark"
           >
             Nature of Business
           </label>
           <div className="input-group input-group-outline mb-3">
             <label className="form-label"></label>
             <input
             className="form-control shadow-none"
             type="text"
             value={item.businesstype}
             />
           </div>
         </div>

         <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
           <label
             className="form-label text-dark"
           >
             Description of process indicating major equipment and
             product
           </label>
           <div className="input-group input-group-outline mb-3">
             <label className="form-label"></label>

             <textarea
               className="form-control shadow-none"
               type="text"
               rows={2}
               onChange={(e) =>
                 this.setState({ processEquipm: e.target.value })
               }
             ></textarea>
           </div>
         </div>

         <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
           <label className="form-label text-dark">
             List product brands with their unit packaging sizes if
             applicable
           </label>
           <div className="input-group input-group-outline mb-3">
             <label className="form-label"></label>
             <textarea
               className="form-control shadow-none"
               type="text"
               rows={2}
               onChange={(e) =>
                 this.setState({ productbrands: e.target.value })
               }
             ></textarea>
           </div>
         </div>

         <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
           <label
             className="form-label text-dark"
           >
             Company size
           </label>
           <div className="input-group input-group-outline mb-3">
             <label className="form-label"></label>
              <input
              className="form-control shadow-none"
              value={`${val[4]}`}
              />
           </div>
         </div>


         <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
           <label
             className="form-label text-dark"
           >
             Payment Description
           </label>
           <div className="input-group input-group-outline mb-3">
             <label className="form-label"></label>
              <input
              className="form-control shadow-none"
              value={val[2]}
              />
           </div>
         </div>

         <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
           <label
             className="form-label text-dark"
           >
             Amount
           </label>
           <div className="input-group input-group-outline mb-3">
             <label className="form-label"></label>
              <input
              className="form-control shadow-none"
              value={val[1]}
              />
           </div>
         </div>

       </div>
       <hr />



       <div className="row" style={{ marginTop: 25 }}>
         <Form.Floating className="mb-3">
           <input
             class="form-check-input shadow-none"
             type="checkbox"
             id="checkBoxUS"
             name="US"
             value="US"
             onChange={this.handleCheckChange}
           />{" "}
           I hereby certify that the information given above is true
         </Form.Floating>
       </div>

       <div
         className="text-center"
         style={{
           margin: "auto",
           width: "100%",
           marginTop: 45,
           marginBottom: 0,
         }}
       >
         <button
           type="button"
           disabled={this.state.isDisabled}
           style={{
             alignSelf: "center",
             width: "100%",
             backgroundColor: "#003314",
           }}
           id={`${item.addresslga},${item.addressstate},${item.organisationname},${val[1]},${val[4]},${item.businessdescription},${item.businesstype},${item.contactname},${item.addressstreet},${val[2]}}`}
           className="btn btn-success btn-lg"
           onClick={(e) => this.registerPremises(`${item.addresslga},${item.addressstate},${item.organisationname},${val[1]},${val[4]},${item.businessdescription},${item.businesstype},${item.contactname},${item.addressstreet},${val[2]},${val[5]}, ${val[0]}, ${val[3]}`)}
           >
           {this.state.isPremisesLoading ? (
             <Spinner animation="border" variant="light" size="sm" />
           ) : (
             <span className="font-weight-bold">
               SUBMIT APPLICATION
             </span>
           )}
         </button>
       </div>
     </form>
   </div>
   </div>

           </div>
            <div class="modal-footer">
             <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
           </div>
           </div>
           </div>
           </div>

     {/* End of Apply For Premises */}

     </tbody>
            );
      });
    } catch (e) {
      Swal.fire({
        title: "Error",
        text: e.message,
        type: "error",
      })
    }
  };


  getInspections = async () => {
    this.setState({ isLoading: true})
    let obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    // console.warn(localStorage.getItem('userid'));
     await fetch(`${baseUrl}/Inspection/getInspectionByUserID/${localStorage.getItem('userid')}`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        console.warn(responseJson);
        if (responseJson.status === 401) {
            this.setState({ isLoading: false });
            Swal.fire({
              title: "Session Expired",
              text: "Session expired. Please login",
              icon: "error",
              confirmButtonText: "OK",
            }).then(() => {
              this.props.history.push("/login");
            });
          }else if(responseJson.length === 0){
            this.setState({ noRecords: true, isLoading: false })
          }else {
          this.setState({data: responseJson, isLoading: false})
          }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  }

  componentDidMount(){
    this.getInspections();
    this.getUser();

  }

  render(){

    const { isInspectionLoading, noRecords, inspectionData,disabled, isLoading } = this.state;

    return(
      <div className="g-sidenav-show">
      <Sidebar />
      <div className="text-center container-fluid px-4 d-flex justify-content-between" style={{width: '70%', justifyContent: 'space-evenly', alignItems: 'center', position: 'relative', top: 63, left: 81, marginBottom: 27 }}>
      <div>
      <img src={logo} className="navbar-brand-img" alt="main_logo" style={{ width: 81 }} />
      </div>
      <div>
      <h4 className="font-weight-bold text-center" style={{ position: 'relative', left: 36}}>NIGERIAN COUNCIL OF FOOD SCIENCE AND TECHNOLOGY (NiCFoST)</h4>
      </div>
      <div>
      <img src={coat} className="navbar-brand-img h-100" style={{ width: 126, position: 'relative', left: 126 }} alt="main_logo" />
      </div>
      </div>
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg " style={{width: '80%', float: 'right'}}>
      <div className="container-fluid justify-content-center py-4" style={{ width: '100%', position: 'relative', left: 0 }}>
      <div class="container-fluid px-4">
        <div class="rown">
          <div class="col-12">
            <div class="card my-3">
              <div class="card-header pb-0 bg-success">
                <div class="d-flex flex-wrap" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                  <h5 className="mb-4 text-light font-weight-bold">My Inspection Applications</h5>
                  <Link to="/add-inspection"><button className="btn btn-warning font-weight-bold">Apply For Inspection</button></Link>
                </div>
              </div>
              <div class="card-body">

        {isLoading ?  <center><Spinner animation="border" variant="success" size="lg" style={{ alignSelf: 'center' }} className="text-center" /></center> :
      <div class="table-responsive p-0 pb-2">
      <table id="table" className="table align-items-center justify-content-center mb-0">
        <thead>
            <tr>
            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">S/N</th>
            <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">Name</th>
            <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">Phone</th>
            <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">Address</th>
            <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">Status</th>
            <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">Application Date</th>
            <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">Inspection Date</th>
            <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">Action</th>
            </tr>
        </thead>
        {this.showTable()}
      </table>
      {noRecords && <center><p>No records found</p></center>}
      </div> }

      <div style={{float: 'right'}}>
      {this.showPagination()}
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>

       <Footer /><br/><br/>

       {/* Start of View Modal */}
       <div class="modal fade" id="viewInspection" tabindex="-1" aria-labelledby="viewInspection" aria-hidden="true">
         <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
           <div class="modal-content">
             <div class="modal-header d-flex align-items-center justify-content-between">
               <h5 class="modal-title">Inspection Details</h5>
               <button type="button" class="btn btn-link m-0 p-0 text-dark fs-4" data-bs-dismiss="modal" aria-label="Close"><span class="iconify" data-icon="carbon:close"></span></button>
             </div>
             <div class="modal-body">
             <div className="row">
               <div clasNames="d-flex px-3">
                 { isInspectionLoading ? <center><Spinner animation="border" className="text-center" variant="success" size="lg" /></center>  :
    <div className="d-flex flex-column">
    {inspectionData.length > 0 && inspectionData.map((item) => {
     return (
       <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg " style={{width: !localStorage.getItem("token") ? '90%' : '100%', position: localStorage.getItem("token") ? 'relative' : '', right: 0, padding: 18, float: !localStorage.getItem("token") ? '' : 'right', marginBottom: 90 }}>
         <div className="container-fluid px-4">
           <div className="rown">
             <div className="col-12">
               <div className="card my-3 mb-4">
                 <div className="card-header pb-0 bg-success">
                   <div className="text-center">
                     <h5 className="text-light text-center font-weight-bold mb-4">{`Inspection Registration Details`}</h5>
                   </div>
                 </div>
                {/* <div class="card-body"> */}
                <div className="container" style={{ marginTop: 18, padding: 9 }}>
                  <div style={{ marginTop: 0 }}></div>
                  <form className="row">

              <label
                className="mb-3 h4"
                style={{ color: "green" }}
                htmlFor="floatingInputCustom"
              >
                Orgnainzation Information
              </label>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorSurname }}
                    className="form-label"
                  >
                    Organization Name <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control w-50 shadow-none"
                      type="text"
                      required="required"
                      value={item.organisationname}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label text-dark"
                  >
                    Address
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <textarea
                      className="form-control w-50 shadow-none"
                      type="text"
                      value={item.addressstreet}
                    ></textarea>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label text-dark"
                  >
                    Address LGA <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      value={item.addresslga}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label"
                  >
                    Address State <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      value={item.addressstate}
                    />
                  </div>
                </div>


                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label text-dark"
                  >
                    Business Type
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      value={item.businesstype}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label text-dark"
                  >
                    Business Description
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      type="text"
                      className="form-control shadow-none"
                      value={item.businessdescription}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label text-dark"
                  >
                    Product Brand
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      type="text"
                      className="form-control shadow-none"
                      value={item.productbrands}
                    />
                  </div>
                </div>

                <label
                  className="mb-3 h4"
                  style={{ color: "green" }}
                  htmlFor="floatingInputCustom"
                >
                  Contact Information
                </label>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label text-dark"
                  >
                    Contact Name
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      type="text"
                      className="form-control shadow-none"
                      value={item.contactname}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label text-dark"
                  >
                    Contact Email
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      required="required"
                      value={item.contactemail}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label text-dark"
                  >
                    Contact Phone
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      value={item.contactphone}
                    />
                  </div>
                </div>

                <label
                  className="mb-3 h4"
                  style={{ color: "green" }}
                  htmlFor="floatingInputCustom"
                >
                  Official Remarks
                </label>
                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label text-dark"
                  >
                    Status
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className={item.inspectionstatus.toLowerCase() === "pending" ? 'text-dark form-control bg-warning shadow-none' : item.inspectionstatus.toLowerCase() === "approved" ? 'text-light form-control bg-success' : item.inspectionstatus.toLowerCase() === "rejected" ? 'text-light form-control bg-danger' : ''}
                      type="text"
                      value={item.inspectionstatus}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label text-dark"
                  >
                    Remarks
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      disabled
                      className="form-control shadow-none"
                      type="text"
                      value={item.remarks}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label text-dark"
                  >
                    Application Date
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      value={moment(item.applicationdate).format('LL')}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label text-dark"
                  >
                    Inspection Date
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      value={moment(item.inspectiondate).format('LL') === 'Invalid date' ? '' : moment(item.inspectiondate).format('LL')}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label text-dark"
                  >
                    Approval Date
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      disabled
                      className="form-control shadow-none"
                      type="text"
                      value={moment(item.reportdate).format("LL")}
                    />
                  </div>
                </div>
       </form>
     </div>

     </div>
     </div>
     </div>
     </div>
                      {/*   </div>*/}
                       </main>
                   )
                   })}
                 </div>
               }
               </div>
               <span className="pt-3"><hr class="dark horizontal my-3" /></span>

               {/*
               <div className="d-flex flex-column px-3">
                 <h6 className="mb-3 text-sm">Applicant's History</h6>

                 <span className="mb-2 text-xs">Date Applied: <span class="text-dark font-weight-bold ms-sm-2">8 Feb, 2022</span></span>
                 <span className="mb-2 text-xs">Created By: <span class="text-dark ms-sm-2 font-weight-bold">info@byteworks.com</span></span>
                 <span className="mb-2 text-xs">Updated By: <span class="text-dark ms-sm-2 font-weight-bold">info@byteworks.com</span></span>
               </div>
               */}
             </div>
             </div>
             <div class="modal-footer">
               <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
             </div>
           </div>
         </div>
       </div>
       {/* End of View Modal */}

      </main>

      </div>
    )
  }
}

export default ManageInspections;
