import React, { Component } from "react";
import { Spinner, Form } from "react-bootstrap";
 
import "bootstrap/dist/css/bootstrap.min.css";
import { states } from "../components/States";
import Swal from "sweetalert2";
import Footer from "../components/Footer";
import Sidebar from '../components/Sidebar';
import moment from 'moment';


const baseUrl = process.env.REACT_APP_BASE_URL


class RenewPremises extends Component {

    constructor(props){
      super(props);
      this.state = {
        data: [],
        renew: [],
        businessType: [],
        feeType: [],
        renewData: [],
        premisesData: [],
        postsPerPage: 10,
        currentPage: 1,
        loading: true,
        isLoading: false,
        hasError: false,
        rrr: "",
        isVerifiedLoading: false,
        gender: "",
        phone: "",
        email: "",
        password: "",
        confirmPassword: "",
        countryCode: "",
        productBrands: "",
        processEquipm: "",
        name: "",
        licenceNo: "",
        orgAddress: "",
        natureBus: "",
        disabled: false,
        nationality: "",
        state: "",
        lga: "",
        boxAll: false,
        orgName: "",
        regType: "",
        nifst: "",
        companySize: "",
        colorOrgAddress: "black",
        colorNationality: "black",
        colorLga: "black",
        colorLicesNum: "black",
        colorOrgName: "black",
        colorAddress: "black",
        colorProcessEquip: "black",
        colorState: "black",
        colorGender: "black",
        colorNIFST: "black",
        colorQualYear: "black",
        colorNatureBus: "black",
        colorSurname: "black",
        colorRegType: "black",
        colorCompany: "black",
        hideForm: false,
      }
      this.handleChange = this.handleChange.bind(this);
      this.handleStateChange = this.handleStateChange.bind(this);
      this.handleGenderChange = this.handleGenderChange.bind(this);
      this.handleCheckChange = this.handleCheckChange.bind(this);
      this.handleCompnaySizeChange = this.handleCompnaySizeChange.bind(this);
      this.handleBusNatureChange = this.handleBusNatureChange.bind(this);
      this.handleCountryChange = this.handleCountryChange.bind(this);
    }

    handleChange(e) {
      this.setState({ title: e.target.value });
    }
    handleBusNatureChange(e) {
      this.setState({ natureBus: e.target.value });
    }
    handleStateChange(e) {
      this.setState({ state: e.target.value });
    }

    handleGenderChange(e) {
      this.setState({ gender: e.target.value });
    }
    handleCompnaySizeChange(e){
      this.setState({ companySize: e.target.value });
    }

    handleCheckChange(e) {
      this.setState({ boxAll: e.target.checked }, () => {
      });
    }

    handleCountryChange(e) {
      this.setState({ countryCode: e.target.value });
    }

    getBusinessType = async () => {
      const url = `${baseUrl}/Lookups/GetBusinessType`;
      this.setState({isLoading: true});
      await fetch(url, {
        method: 'GET',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(res => res.json())
        .then(res => {
          this.setState({ businessType: res})
        })
        .catch(error => {
          this.setState({error: true, loading: false});
          alert(error);
        });
    }

    getFeeType = async () => {
      const url = `${baseUrl}/Fees/getFeesByPaymentType/corporate`;
      this.setState({isLoading: true});
      await fetch(url, {
        method: 'GET',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(res => res.json())
        .then(res => {
          // console.warn(res);
          this.setState({ feeType: res })
        })
        .catch(error => {
          this.setState({error: true, loading: false});
          alert(error);
        });
    }


    getStates() {
      return states.map((state) => {
        return <option value={state}>{state}</option>;
      });
    }

    showBusinessTypes(){
      return this.state.businessType.map((item) => {
        return <option value={item.referencename}>{item.referencename}</option>;
      });
    }
    showFeeTypes(){
      return this.state.feeType.map((item) => {
        return (
        <option disabled={item.description === "Corporate Annual Practice License Renewal" ? `` : 'disabled'} value={`${item.paymentsubcategory}, ${item.description}, ${item.amount}`}>{item.description === "Corporate Annual Practice License Renewal" ? `${item.paymentsubcategory.toUpperCase()} (N${item.amount})` : ''}</option>
      )
      });
    }

    getUserDetails = (recid) => {
      const url = `${baseUrl}/Premises/getPremisesByRecID/${recid}`;
      this.setState({isLoading: true});
      fetch(url, {
        method: 'GET',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(res => res.json())
        .then(res => {
          this.setState({
            isLoading: false,
            premisesData: res,
          });
        })
        .catch(error => {
          this.setState({error: true, loading: false});
          alert(error);
        });
    }

    getUserRenewDetails = (recid) => {
      const url = `${baseUrl}/Premises/getPremisesByRecID/${recid}`;
      this.setState({isLoading: true});
      fetch(url, {
        method: 'GET',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(res => res.json())
        .then(res => {
          this.setState({
            isLoading: false,
            renewData: res,
          });
        })
        .catch(error => {
          this.setState({error: true, loading: false});
          alert(error);
        });
    }


    //SHOW PAGINATION
    showPagination = () => {
    const { postsPerPage, data } = this.state;
    const pageNumbers = [];
    const totalPosts = data.length;
    for(let i = 1; i<= Math.ceil(totalPosts/postsPerPage); i++){
      pageNumbers.push(i)
    }

     const paginate = (pageNumbers) => {
       this.setState({currentPage: pageNumbers})
     }

      return(
        <nav>
        <ul className="pagination">
        {pageNumbers.map(number => (
          <li key={number} className={this.state.currentPage === number ? 'page-item active' : 'page-item'}>
          <button onClick={()=> paginate(number)} className="page-link">
            { number }
          </button>
         </li>
       ))}
        </ul>
        </nav>
      )
    }

    // SHOW TABLE
    showTable = () => {
      const { postsPerPage, currentPage, data } = this.state;
      const indexOfLastPost = currentPage * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      const currentPosts = data.reverse().slice(indexOfFirstPost, indexOfLastPost);

      try {
        return currentPosts.map((item, index) => {
          return (
              <tbody>
              <tr>
             <td>
             <span className="text-xs font-weight-bold">{postsPerPage * (currentPage -1)+index+1 }</span>
             </td>
             <td className="text-xs text-secondary text-capitalize font-weight-bold">{item.organisationname}</td>
             <td className="text-xs font-weight-bold">{item.companysize}</td>
             <td className="text-xs font-weight-bold">{(item.businesstype)}</td>
             <td className="text-xs font-weight-bold">{moment(item.applicationdate).format('LL')}</td>
             <td className="text-xs font-weight-bold">{moment(item.licensedate).format('LL') === "Invalid date" ?null : moment(item.licensedate).format('LL')}</td>
             <td>
            <button className="btn btn-primary-2 mb-0" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false"><span class="iconify" data-icon="charm:menu-meatball" style={{fontSize: 'large'}} ></span></button>
            <ul className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="#dropdownMenuButton2">
            <li className="mb-2" id = { item.recid } onClick={() => this.getUserDetails(item.recid)} data-bs-toggle="modal" data-bs-target="#viewPayments">
              <a className="dropdown-item border-radius-md" href="javascript:;">
                <div className="d-flex py-1">
                    <h6 className="text-sm font-weight-normal mb-1">
                      <span className="font-weight-bold">View</span>
                    </h6>
                </div>
              </a>
            </li>
            {postsPerPage * (currentPage -1)+index+1 === 1  &&
            <li className="mb-2" id = { item.recid } onClick={() => this.getUserRenewDetails(item.recid)} data-bs-toggle="modal" data-bs-target="#verifyPayments">
              <a className="dropdown-item border-radius-md" href="javascript:">
                <div className="d-flex py-1">
                    <h6 className="text-sm font-weight-normal mb-1">
                      <span className="font-weight-bold">Renew</span>
                    </h6>
                </div>
              </a>
            </li>
          }
            <li className="mb-2" id = { item.recid } onClick={() => this.getUserPaymentDetails(item.recid)} data-bs-toggle="modal" data-bs-target="#printLicence">
              <a className="dropdown-item border-radius-md" >
                <div className="d-flex py-1">
                    <h6 className="text-sm font-weight-normal mb-1">
                      <span className="font-weight-bold">Print Licence</span>
                    </h6>
                </div>
              </a>
            </li>

          </ul>
          </td>
          <td></td>
       </tr>
       </tbody>
              );
        });
      } catch (e) {
        Swal.fire({
          title: "Error",
          text: e.message,
          type: "error",
        })
      }
    };

    // CHECK VALIDATION
    checkValidation = async (e) => {
      const {
        gender,
        name,
        licenceNo,
        processEquipm,
        nationality,
        natureBus,
        state,
        orgAddress,
        nifst,
        boxAll,
        lga,
        companySize,
        orgName,
      } = this.state;
      this.setState({ loading: true, disabled: true });
      e.preventDefault();

      if (name === "" && !localStorage.getItem("leadfoodscientistname")) {
        Swal.fire({
          title: "Empty",
          text: "Please enter name of lead technologist",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ loading: false, disabled: false, colorSurname: "red" });
      } else if (licenceNo === "" && !localStorage.getItem("licence_number") || localStorage.getItem("licence_number") === "" ) {
        Swal.fire({
          title: "Empty",
          text: "Please specify your license number",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ loading: false, disabled: false, colorLicesNum: "red" });
      } else if (nifst === "" && !localStorage.getItem("reg_number")) {
        Swal.fire({
          title: "Empty",
          text: "Please specify your registration number",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ loading: false, disabled: false, colorNIFST: "red" });
      } else if (nationality === "" && !localStorage.getItem("nationality")) {
        Swal.fire({
          title: "Empty",
          text: "Please specify your nationality",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({
          loading: false,
          disabled: false,
          colorNationality: "red",
        });
      } else if (gender === "") {
        Swal.fire({
          title: "Empty",
          text: "Please specify your gender",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ loading: false, disabled: false, colorGender: "red" });
      } else if (state === "" ) {
        Swal.fire({
          title: "Empty",
          text: "Please specify your State of Origin",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ loading: false, disabled: false, colorState: "red" });
      } else if (lga === "" && !localStorage.getItem("lga")) {
        Swal.fire({
          title: "Empty",
          text: "Please specify your Local Government Area",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ loading: false, disabled: false, colorLga: "red" });
      } else if (orgName === "" && !localStorage.getItem("orgName")) {
        Swal.fire({
          title: "Empty",
          text: "Please specify your Organization Name",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ loading: false, disabled: false, colorOrgName: "red" });
      } else if (orgAddress === "" && !localStorage.getItem("orgAddress")) {
        Swal.fire({
          title: "Empty",
          text: "Please specify address of your Organization",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({
          loading: false,
          disabled: false,
          colorOrgAddress: "red",
        });
      } else if (natureBus === "") {
        Swal.fire({
          title: "Empty",
          text: "Please specify the nature of your business",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ loading: false, disabled: false, colorNatureBus: "red" });
      } else if (processEquipm === "" && !localStorage.getItem("natureBus")) {
        Swal.fire({
          title: "Empty",
          text: "Please give a description of your process",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({
          loading: false,
          disabled: false,
          colorProcessEquip: "red",
        });
      } else if (companySize === "" ) {
        Swal.fire({
          title: "Empty",
          text: "Please specify your company size",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ loading: false, disabled: false, colorCompany: "red" });
      } else if (boxAll === false) {
        Swal.fire({
          title: "Accept The Terms",
          text: "Please accept the terms and conditions",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ loading: false, disabled: false });
      } else {
        this.renewPremises();
      }
    };

    // RENEW PREMISES
    renewPremises = async () => {
      let date = new Date();
      let fees = this.state.companySize.split(",")
      localStorage.setItem("amount",fees[2].trim())
      localStorage.setItem("description",fees[1].trim())
      localStorage.setItem("category",fees[0].trim())

      let obj = {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },

        body: JSON.stringify({
          RRR: "",
          applicationdate: `${date.getTime()}`,
          businessdescription: this.state.processEquipm !== ""  ? this.state.processEquipm : localStorage.getItem("processEquipm"),
          businesstype: this.state.natureBus !== "" ? this.state.natureBus : localStorage.getItem("natureBus"),
          companysize: this.state.companySize !== "" ? fees[0].trim() : localStorage.getItem("companySize"),
          formtype: localStorage.getItem('userid') ? 'renewal' : 'registration',
          gender: this.state.gender !== "" ? this.state.gender : localStorage.getItem("gender"),
          inspectionfees: fees[2].trim(),
          leadfoodscientistlicensenumber: this.state.licenceNo !== "" ? this.state.licenceNo : localStorage.getItem("licence_number"),
          // leadfoodscientistlicensenumber: this.state.licenceNo,
          leadfoodscientistname: this.state.name !== "" ? this.state.name : localStorage.getItem("leadfoodscientistname"),
          lga: this.state.lga !== "" ? this.state.lga : localStorage.getItem("lga"),
          locationaddress: this.state.orgAddress !== "" ? this.state.orgAddress : localStorage.getItem("orgAddress"),
          nationality: this.state.nationality !== "" ? this.state.nationality : localStorage.getItem("nationality"),
          organisationname: this.state.orgName !== "" ? this.state.orgName : localStorage.getItem("orgName"),
          productbrands: this.state.productBrands !== "" ? this.state.productBrands : localStorage.getItem("productBrands"),
          recid: localStorage.getItem("recid"),
          registrationnumber: this.state.nifst !== "" ? this.state.nifst : localStorage.getItem("reg_number"),
          stateofororigin:this.state.state !== "" ? this.state.state : localStorage.getItem("state"),
        }),
      };
      fetch(`${baseUrl}/Premises/updatePremises`, obj)
        .then((response) => response.json())
        .then((responseJson) => {
          console.warn(responseJson);
          if (responseJson.message === "Premises updated Successfully") {
            // localStorage.setItem("lead_food_scientist",this.state.name )
            // localStorage.setItem("licence_number",this.state.licenceNo )
            // localStorage.setItem("reg_number",this.state.nifst )
            // localStorage.setItem("nationality",this.state.nationality )
            // localStorage.setItem("gender",this.state.gender )
            // localStorage.setItem("state",this.state.state )
            // localStorage.setItem("lga",this.state.lga )
            // localStorage.setItem("orgName",this.state.orgName )
            // localStorage.setItem("orgAddress",this.state.orgAddress )
            // localStorage.setItem("processEquipm",this.state.processEquipm )
            // localStorage.setItem("productBrands",this.state.productBrands )
            // localStorage.setItem("natureBus",this.state.natureBus )
            // localStorage.setItem("companySize",this.state.companySize )
            this.setState({ loading: false, disabled: false });
            Swal.fire({
              title: "Success",
              text: "Premises Registration Successful",
              icon: "success",
              confirmButtonText: "OK",
            }).then(()=>{
                  // window.location = "/register-premises-payment"
                  // $("#verifyPayments").modal("hide");
                // window.location.reload().then(()=> {
                  this.props.history.push("/register-premises-payment");
                // })
            });
          } else if (responseJson.status === 401) {
            this.props.history.push("/login");
          }
        })
        .catch((error) => {
          this.setState({ loading: false, disabled: false });
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    };



    // RETRIEVE PREVIOUS PREMISES
    retrievePremisesRegistration = async () => {
      let obj = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await fetch(`${baseUrl}/Premises/getPremisesByUserID/${localStorage.getItem("userid")}`, obj)
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === 401) {
              this.setState({ loading: false });
              Swal.fire({
                title: "Session Expired",
                text: "Session expired. Please login",
                icon: "error",
                confirmButtonText: "OK",
              }).then(() => {
                this.props.history.push("/login");
              });
            }else{
              console.warn(responseJson);
            this.setState({data: responseJson, loading: false})
            }
        })
        .catch((error) => {
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }

    componentDidMount(){
      this.getBusinessType()
      this.getFeeType();
      this.retrievePremisesRegistration();
    }

    render(){

      const { loading, isLoading, premisesData, renewData } = this.state;
      return(
        <div className="container">
        <Sidebar />

        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg " style={{width: '80%', float: 'right'}}>


        <div className="container-fluid justify-content-center py-4" style={{ width: '100%', position: 'relative', left: 0 }}>


        <div class="container-fluid px-4">
          <div class="rown">
            <div class="col-12">
              <div class="card my-3">
                <div class="card-header pb-0" style={{ backgroundColor: '#00264C'}}>
                  <div class="d-flex flex-wrap align-items-center">
                    <h5 className="mb-4 text-light">My Premises Registration History</h5>
                  </div>
                </div>
                <div class="card-body">

          {loading ?  <center><Spinner animation="border" variant="success" size="lg" /></center> :
        <div class="table-responsive p-0 pb-2">
        <table id="table" className="table align-items-center justify-content-center mb-0">
          <thead>
              <tr>
              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">S/N</th>
              <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">Company name</th>
              <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">Company size</th>
              <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">Business type</th>
              <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">Application date</th>
              <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">Approval date</th>
              <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">Action</th>
              </tr>
          </thead>
          {this.showTable()}
        </table>
        </div> }

        <div style={{float: 'right'}}>
        {this.showPagination()}
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>

         <Footer /><br/><br/>

         {/* Start of View Modal */}
         <div class="modal fade" id="viewPayments" tabindex="-1" aria-labelledby="viewRunning" aria-hidden="true">
           <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
             <div class="modal-content">
               <div class="modal-header d-flex align-items-center justify-content-between" style={{ backgroundColor: '#00264C'}}>
                 <h5 class="modal-title text-light">View Record</h5>
                 <button type="button" class="btn btn-link m-0 p-0 text-light fs-4" data-bs-dismiss="modal" aria-label="Print"><i style={{ fontSize: 27, color: '#fff'}} class="material-icons opacity-10 text-light">print</i></button>
                 <button type="button" class="btn btn-link m-0 p-0 text-light fs-4" data-bs-dismiss="modal" aria-label="Close"><span class="iconify" data-icon="carbon:close"></span></button>
               </div>
                 { isLoading ? <center><Spinner animation="border" style={{ padding: 3 }} variant="success" size="lg" /></center> :
               <div class="modal-body">
               { premisesData.length > 0 && premisesData.map((item) => {
                 return (
                           <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg " style={{width: !localStorage.getItem("token") ? '90%' : '100%', position: localStorage.getItem("token") ? 'relative' : '', right: 0, padding: 18, float: !localStorage.getItem("token") ? '' : 'right', marginBottom: 90 }}>
                             <div className="container-fluid px-4">
                               <div className="rown">
                                 <div className="col-12">
                                   <div className="card my-3 mb-4">
                                     <div className="card-header pb-0 bg-success">
                                       <div className="text-center">
                                         <h5 className="text-light text-center font-weight-bold mb-4">Application Form for the Registration of Food Business Premises</h5>
                                       </div>
                                     </div>
                                    {/* <div class="card-body"> */}

                             <div className="container" style={{ marginTop: 0, padding: 9 }}>
                               <div style={{ marginTop: 25 }}></div>
                               <form className="row">
                                 <label
                                   className="mb-3 h4"
                                   style={{ color: "green" }}
                                   htmlFor="floatingInputCustom"
                                 >
                                   Personal Information
                                 </label>
                                 <br />
                                 <br />
                                 <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                   <label
                                     style={{ color: this.state.colorSurname }}
                                     className="form-label"
                                   >
                                     Name of Lead Food Scientist / Licence Number{" "}
                                     <span className="text-danger">*</span>
                                   </label>
                                   <div className="input-group input-group-outline mb-3">
                                     <label className="form-label"></label>
                                     <input
                                       className="form-control shadow-none"
                                       type="text"
                                       value={item.leadfoodscientistname.toUpperCase()}
                                     />
                                   </div>
                                 </div>

                                 <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                   <label
                                     style={{ color: this.state.colorLicesNum }}
                                     className="form-label"
                                   >
                                     Licence Number <span className="text-danger">*</span>
                                   </label>
                                   <div className="input-group input-group-outline mb-3">
                                     <label className="form-label"></label>
                                     <input
                                       className="form-control shadow-none"
                                       type="text"
                                       value={item.licensenumber}
                                     />
                                   </div>
                                 </div>

                                 <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                   <label
                                     style={{ color: this.state.colorNIFST }}
                                     className="form-label"
                                   >
                                     Registration Number <span className="text-danger">*</span>
                                   </label>
                                   <div className="input-group input-group-outline mb-3">
                                     <label className="form-label"></label>
                                     <input
                                       className="form-control shadow-none"
                                       type="text"
                                       value={item.registrationnumber}
                                     />
                                   </div>
                                 </div>

                                 <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                   <label
                                     className="form-label"
                                     style={{ color: this.state.colorNationality }}
                                   >
                                     Nationality <span className="text-danger">*</span>
                                   </label>
                                   <div className="input-group input-group-outline mb-3">
                                     <label className="form-label"></label>
                                     <input
                                       type="text"
                                       className="form-control shadow-none"
                                       value={item.nationality.toUpperCase()}
                                     />
                                   </div>
                                 </div>

                                 <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                   <label
                                     className="form-label"
                                     style={{ color: this.state.colorNationality }}
                                   >
                                     State <span className="text-danger">*</span>
                                   </label>
                                   <div className="input-group input-group-outline mb-3">
                                     <label className="form-label"></label>
                                     <input
                                       type="text"
                                       className="form-control shadow-none"
                                       value={item.stateofororigin.toUpperCase()}
                                     />
                                   </div>
                                 </div>


                                 <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                   <label
                                     style={{ color: this.state.colorLga }}
                                     className="form-label"
                                   >
                                     LGA <span className="text-danger">*</span>
                                   </label>
                                   <div className="input-group input-group-outline mb-3">
                                     <label className="form-label"></label>
                                     <input
                                       className="form-control shadow-none"
                                       type="text"
                                       value={item.lga.toUpperCase()}
                                     />
                                   </div>
                                 </div>

                                 <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                   <label
                                     style={{ color: this.state.colorLga }}
                                     className="form-label"
                                   >
                                     Gender <span className="text-danger">*</span>
                                   </label>
                                   <div className="input-group input-group-outline mb-3">
                                     <label className="form-label"></label>
                                     <input
                                       className="form-control shadow-none"
                                       type="text"
                                       value={item.gender.toUpperCase()}
                                     />
                                   </div>
                                 </div>

                                 <hr />

                                 <div className="row" style={{ padding: 15 }}>
                                   <label
                                     className="mb-3 h4"
                                     style={{ color: "green" }}
                                     htmlFor="floatingInputCustom"
                                   >
                                     Orgnainzation Information
                                   </label>

                                   <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                     <label
                                       style={{ color: this.state.colorOrgName }}
                                       className="form-label"
                                     >
                                       Name of Organization / Company{" "}
                                       <span className="text-danger">*</span>
                                     </label>
                                     <div className="input-group input-group-outline mb-3">
                                       <label className="form-label"></label>
                                       <input
                                         className="form-control shadow-none"
                                         type="phone"
                                         value={item.organisationname.toUpperCase()}
                                       />
                                     </div>
                                   </div>

                                   <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                     <label
                                       style={{ color: this.state.colorOrgAddress }}
                                       className="form-label"
                                     >
                                       Address of Business Premises{" "}
                                       <span className="text-danger">*</span>
                                     </label>
                                     <div className="input-group input-group-outline mb-3">
                                       <label className="form-label"></label>

                                       <textarea
                                         className="form-control shadow-none"
                                         type="text"
                                         value={item.locationaddress.toUpperCase()}
                                       ></textarea>
                                     </div>
                                   </div>


                                   <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                     <label
                                       style={{ color: this.state.colorProcessEquip }}
                                       className="form-label"
                                     >
                                       Description of process indicating major equipment and
                                       product <span className="text-danger">*</span>
                                     </label>
                                     <div className="input-group input-group-outline mb-3">
                                       <label className="form-label"></label>

                                       <textarea
                                         className="form-control shadow-none"
                                         type="text"
                                         rows={5}
                                         required="required"
                                         value={item.businessdescription.toUpperCase()}
                                       ></textarea>
                                     </div>
                                   </div>

                                   <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                     <label className="form-label text-dark">
                                       List product brands with their unit packaging sizes if
                                       applicable <span className="text-danger">*</span>
                                     </label>
                                     <div className="input-group input-group-outline mb-3">
                                       <label className="form-label"></label>

                                       <textarea
                                         className="form-control shadow-none"
                                         type="text"
                                         rows={5}
                                         value={item.productbrands.toUpperCase()}
                                       ></textarea>
                                     </div>
                                   </div>

                                   <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                     <label
                                       style={{ color: "black" }}
                                       className="form-label"
                                     >
                                       Business type <span className="text-danger">*</span>
                                     </label>
                                     <div className="input-group input-group-outline mb-3">
                                       <label className="form-label"></label>
                                       <input
                                         className="form-control shadow-none"
                                         type="phone"
                                         value={item.businesstype}
                                       />
                                     </div>
                                     </div>

                                   <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                     <label
                                       style={{ color: "black" }}
                                       className="form-label"
                                     >
                                       Company size <span className="text-danger">*</span>
                                     </label>
                                     <div className="input-group input-group-outline mb-3">
                                       <label className="form-label"></label>
                                       <input
                                         className="form-control shadow-none"
                                         type="phone"
                                         value={item.companysize}
                                       />
                                     </div>
                                     </div>
                                 </div>
                                 <hr />

                                   {!localStorage.getItem('userid') &&
                                 <label
                                   className="h4"
                                   htmlFor="floatingInputCustom"
                                   style={{ color: "#145973" }}
                                 >
                                   Create Login Details <br/>
                                   <span className="text-danger" style={{ fontSize: 18}}>Use this account to login to your dashboard for tracking, renewal and subsequent applications.</span><br/><br/>
                                 </label>
                               }
                                 {!localStorage.getItem('userid') &&
                                 <br />
                               }
                                 {!localStorage.getItem('userid') &&
                                 <br />
                               }

                                   {!localStorage.getItem('userid') &&
                                 <div className="col-sm-6 col-lg-4 col-md-6 mb-3" style={{ display: 'none'}}>
                                   <label
                                     style={{ color: this.state.colorCountryCode }}
                                     className="form-label"
                                   >
                                     Country <span className="text-danger">*</span>
                                   </label>
                                   <div className="input-group input-group-outline mb-3">
                                     <label className="form-label"></label>
                                     <select
                                       className="form-control shadow-none"
                                       aria-label="Floating label select example"
                                       onChange={this.handleCountryChange}
                                     >
                                       <option value="choose" selected="selected">
                                         -- Select country code --
                                       </option>
                                       {this.getCountry()}
                                     </select>
                                   </div>
                                 </div>
                               }

                                   {!localStorage.getItem('userid') &&
                                 <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                   <label
                                     style={{ color: this.state.colorPhone }}
                                     className="form-label"
                                   >
                                     Phone <span className="text-danger">*</span>
                                   </label>
                                   <div className="input-group input-group-outline mb-3">
                                     <label className="form-label"></label>
                                     <input
                                       className="form-control shadow-none"
                                       type="phone"
                                       required="required"
                                       onChange={(e) => this.setState({ phone: e.target.value })}
                                     />
                                   </div>
                                 </div>
                               }

                               <label
                                 className="h4"
                                 htmlFor="floatingInputCustom"
                                 style={{ color: "#145973" }}
                               >
                                 Official
                               </label>
                               <br />
                               <br />

                               <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                 <label className="form-label">Application Status</label>
                                 <div className="input-group input-group-outline mb-3">
                                   <label className="form-label"></label>
                                   <input
                                     className={item.applicationstatus === "approved" ? "form-control shadow-none bg-success text-center text-uppercase font-weight-bold text-light" : item.applicationstatus === "pending" ? "form-control text-center text-uppercase font-weight-bold text-light shadow-none bg-warning" : item.applicationstatus === "rejected" ? "form-control text-center text-uppercase font-weight-bold text-light shadow-none bg-danger": "form-control shadow-none"}
                                     type="text"
                                     value={item.applicationstatus}
                                   />
                                 </div>
                               </div>

                               <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                 <label className="form-label">Licence Date</label>
                                 <div className="input-group input-group-outline mb-3">
                                   <label className="form-label"></label>
                                   <input
                                     className="form-control shadow-none"
                                     type="text"
                                     value={`${item.licensedate}`}
                                   />
                                 </div>
                               </div>
                               <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                 <label className="form-label">Licence Number</label>
                                 <div className="input-group input-group-outline mb-3">
                                   <label className="form-label"></label>
                                   <input
                                     className="form-control shadow-none"
                                     type="text"
                                     value={`${item.licensenumber}`}
                                   />
                                 </div>
                               </div>
                               <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                 <label className="form-label">Licence Remarks</label>
                                 <div className="input-group input-group-outline mb-3">
                                   <label className="form-label"></label>
                                   <input
                                     className="form-control shadow-none"
                                     type="text"
                                     value={`${item.licenseremarks}`}
                                   />
                                 </div>
                               </div>


                                 {!localStorage.getItem('userid') &&
                                 <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                   <label
                                     style={{ color: this.state.colorPassword }}
                                     className="form-label"
                                   >
                                     Password <span className="text-danger">*</span>
                                   </label>
                                   <div className="input-group input-group-outline mb-3">
                                     <label className="form-label"></label>
                                     <input
                                       className="form-control shadow-none"
                                       type="text"
                                       required="required"
                                       onChange={(e) => this.setState({ password: e.target.value })}
                                     />
                                   </div>
                                 </div>
                                  }

                                 {!localStorage.getItem('userid') &&
                                 <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                   <label
                                     style={{ color: this.state.colorPasswordConf }}
                                     className="form-label"
                                   >
                                     Confirm Password <span className="text-danger">*</span>
                                   </label>
                                   <div className="input-group input-group-outline mb-3">
                                     <label className="form-label"></label>
                                     <input
                                       className="form-control shadow-none"
                                       type="text"
                                       required="required"
                                       onChange={(e) =>
                                         this.setState({ confirmPassword: e.target.value })
                                       }
                                     />
                                   </div>
                                 </div>
                                  }

                               </form>
                             </div>

                             </div>
                             </div>
                             </div>
                             </div>
                          {/*   </div>*/}
                           </main>
               )})}
               </div>
               }
               <div class="modal-footer">
                 <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
               </div>
             </div>
           </div>
         </div>
         {/* End of View Modal */}

         {/* Start of Renewal Modal */}
         <div class="modal fade" id="verifyPayments" tabindex="-1" aria-labelledby="viewRunning" aria-hidden="true">
           <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
             <div class="modal-content">
               <div class="modal-header d-flex align-items-center justify-content-between" style={{ backgroundColor: '#00264C'}}>
                 <h5 class="modal-title text-light font-weight-bold">Renew Licence</h5>
                 <button type="button" class="btn btn-link m-0 p-0 text-light fs-4" data-bs-dismiss="modal" aria-label="Close"><span class="iconify" data-icon="carbon:close"></span></button>
               </div>
                { isLoading ? <center><Spinner animation="border" style={{ padding: 9}} variant="success" size="lg" /></center>  :
               <div class="modal-body">
               <div class="container-fluid px-4">
                { renewData.length > 0 && renewData.map((item) => {
                  localStorage.setItem("lead_food_scientist",`${item.leadfoodscientistname}` )
                  localStorage.setItem("licence_number",`${item.leadfoodscientistlicensenumber}` )
                  localStorage.setItem("reg_number",`${item.registrationnumber}` )
                  localStorage.setItem("nationality",`${item.nationality}` )
                  localStorage.setItem("gender",`${item.gender}` )
                  localStorage.setItem("state",`${item.stateofororigin}` )
                  localStorage.setItem("lga",`${item.lga}` )
                  localStorage.setItem("orgName",`${item.organisationname}` )
                  localStorage.setItem("orgAddress",`${item.locationaddress}` )
                  localStorage.setItem("processEquipm",`${item.businessdescription}` )
                  localStorage.setItem("productBrands",`${item.productbrands}` )
                  localStorage.setItem("natureBus",`${item.businesstype}` )
                  localStorage.setItem("companySize",`${item.companysize}` )
                  localStorage.setItem("recid",`${item.recid}` )
                  return(
                    <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg " style={{width: !localStorage.getItem("token") ? '90%' : '100%', position: localStorage.getItem("token") ? 'relative' : '', right: 0, padding: 18, float: !localStorage.getItem("token") ? '' : 'right', marginBottom: 90 }}>
                      <div className="container-fluid px-4">
                        <div className="rown">
                          <div className="col-12">
                            <div className="card my-3 mb-4">
                              <div className="card-header pb-0 bg-success">
                                <div className="text-center">
                                  <h5 className="text-light text-center font-weight-bold mb-4">Renewal Form for the Registration of Food Business Premises</h5>
                                </div>
                              </div>
                             {/* <div class="card-body"> */}

                             <div className="container" style={{ marginTop: 0, padding: 9 }}>
                               <div style={{ marginTop: 25 }}></div>
                               <form className="row">
                                 <label
                                   className="mb-3 h4"
                                   style={{ color: "green" }}
                                   htmlFor="floatingInputCustom"
                                 >
                                   Personal Information
                                 </label>
                                 <br />
                                 <br />
                                 <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                   <label
                                     style={{ color: this.state.colorSurname }}
                                     className="form-label"
                                   >
                                     Name of Lead Food Scientist / Licence Number{" "}
                                     <span className="text-danger">*</span>
                                   </label>
                                   <div className="input-group input-group-outline mb-3">
                                     <label className="form-label"></label>
                                     <input
                                       className="form-control shadow-none"
                                       type="text"
                                       defaultValue={item.leadfoodscientistname}
                                       onChange={(e) => this.setState({ name: e.target.value })}
                                     />
                                   </div>
                                 </div>

                                 <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                   <label
                                     style={{ color: this.state.colorLicesNum }}
                                     className="form-label"
                                   >
                                     Licence Number <span className="text-danger">*</span>
                                   </label>
                                   <div className="input-group input-group-outline mb-3">
                                     <label className="form-label"></label>
                                     <input
                                       className="form-control shadow-none"
                                       type="text"
                                       defaultValue={item.leadfoodscientistlicensenumber}
                                       onChange={(e) =>
                                         this.setState({ licenceNo: e.target.value })
                                       }
                                     />
                                   </div>
                                 </div>

                                 <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                   <label
                                     style={{ color: this.state.colorNIFST }}
                                     className="form-label"
                                   >
                                     Registration Number <span className="text-danger">*</span>
                                   </label>
                                   <div className="input-group input-group-outline mb-3">
                                     <label className="form-label"></label>
                                     <input
                                       className="form-control shadow-none"
                                       type="text"
                                       defaultValue={item.registrationnumber}
                                       onChange={(e) => this.setState({ nifst: e.target.value })}
                                     />
                                   </div>
                                 </div>

                                 <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                   <label
                                     className="form-label"
                                     style={{ color: this.state.colorNationality }}
                                   >
                                     Nationality <span className="text-danger">*</span>
                                   </label>
                                   <div className="input-group input-group-outline mb-3">
                                     <label className="form-label"></label>
                                     <input
                                       type="text"
                                       className="form-control shadow-none"
                                       onChange={(e) =>
                                         this.setState({ nationality: e.target.value })
                                       }
                                       defaultValue={item.nationality}
                                     />
                                   </div>
                                 </div>

                                 <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                   <label
                                     className="form-label"
                                     style={{ color: this.state.colorGender }}
                                   >
                                     Select Gender <span className="text-danger">*</span>
                                   </label>
                                   <div className="input-group input-group-outline mb-3">
                                     <label className="form-label"></label>
                                     <select
                                       className="form-control shadow-none"
                                       aria-label="Select"
                                       onChange={this.handleGenderChange}
                                       style={{ color: this.state.colorGender }}
                                     >
                                       <option selected>
                                          -- Select gender --
                                       </option>
                                       <option value="male">Male</option>
                                       <option value="female">Female</option>
                                     </select>
                                   </div>
                                 </div>

                                 <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                   <label
                                     style={{ color: this.state.colorState }}
                                     className="form-label"
                                   >
                                     Select State <span className="text-danger">*</span>
                                   </label>
                                   <div className="input-group input-group-outline mb-3">
                                     <label className="form-label"></label>
                                     <select
                                       className="form-control shadow-none"
                                       aria-label="Select state"
                                       onChange={this.handleStateChange}
                                       style={{ color: this.state.colorState }}
                                     >
                                       <option
                                          value="select state"
                                         selected>
                                          -- Select state --
                                       </option>

                                       {this.getStates()}
                                     </select>
                                   </div>
                                 </div>

                                 <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                   <label
                                     style={{ color: this.state.colorLga }}
                                     className="form-label"
                                   >
                                     LGA <span className="text-danger">*</span>
                                   </label>
                                   <div className="input-group input-group-outline mb-3">
                                     <label className="form-label"></label>
                                     <input
                                       className="form-control shadow-none"
                                       type="text"
                                       onChange={(e) => this.setState({ lga: e.target.value })}
                                       defaultValue={item.lga}
                                     />
                                   </div>
                                 </div>

                                 <hr />

                                 <div className="row" style={{ padding: 15 }}>
                                   <label
                                     className="mb-3 h4"
                                     style={{ color: "green" }}
                                     htmlFor="floatingInputCustom"
                                   >
                                     Orgnainzation Information
                                   </label>

                                   <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                     <label
                                       style={{ color: this.state.colorOrgName }}
                                       className="form-label"
                                     >
                                       Name of Organization / Company{" "}
                                       <span className="text-danger">*</span>
                                     </label>
                                     <div className="input-group input-group-outline mb-3">
                                       <label className="form-label"></label>
                                       <input
                                         className="form-control shadow-none"
                                         type="phone"
                                         defaultValue={item.organisationname}
                                         onChange={(e) =>
                                           this.setState({ orgName: e.target.value })
                                         }
                                       />
                                     </div>
                                   </div>

                                   <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                     <label
                                       style={{ color: this.state.colorOrgAddress }}
                                       className="form-label"
                                     >
                                       Address of Business Premises{" "}
                                       <span className="text-danger">*</span>
                                     </label>
                                     <div className="input-group input-group-outline mb-3">
                                       <label className="form-label"></label>

                                       <textarea
                                         className="form-control shadow-none"
                                         type="text"
                                         defaultValue={item.locationaddress}
                                         onChange={(e) =>
                                           this.setState({ orgAddress: e.target.value })
                                         }
                                       ></textarea>
                                     </div>
                                   </div>

                                   <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                     <label
                                       style={{ color: this.state.colorNatureBus }}
                                       className="form-label"
                                     >
                                       Nature of Business <span className="text-danger">*</span>
                                     </label>
                                     <div className="input-group input-group-outline mb-3">
                                       <label className="form-label"></label>
                                       <select
                                         className="form-control shadow-none"
                                         aria-label="Select"
                                         onChange={this.handleBusNatureChange}
                                         style={{ color: this.state.colorNatureBus }}
                                       >
                                         <option selected>
                                         -- Select category --
                                         </option>
                                         {this.showBusinessTypes()}
                                       </select>
                                     </div>
                                   </div>

                                   <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                     <label
                                       style={{ color: this.state.colorProcessEquip }}
                                       className="form-label"
                                     >
                                       Description of process indicating major equipment and
                                       product <span className="text-danger">*</span>
                                     </label>
                                     <div className="input-group input-group-outline mb-3">
                                       <label className="form-label"></label>

                                       <textarea
                                         className="form-control shadow-none"
                                         type="text"
                                         rows={5}
                                         required="required"
                                         defaultValue={item.businessdescription}
                                         onChange={(e) =>
                                           this.setState({ processEquipm: e.target.value })
                                         }
                                       ></textarea>
                                     </div>
                                   </div>

                                   <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                     <label className="form-label text-dark">
                                       List product brands with their unit packaging sizes if
                                       applicable <span className="text-danger">*</span>
                                     </label>
                                     <div className="input-group input-group-outline mb-3">
                                       <label className="form-label"></label>

                                       <textarea
                                         className="form-control shadow-none"
                                         type="text"
                                         rows={5}
                                         defaultValue={item.productbrands}
                                         onChange={(e) =>
                                           this.setState({ productBrands: e.target.value })
                                         }
                                       ></textarea>
                                     </div>
                                   </div>

                                   <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                     <label
                                       style={{ color: this.state.colorCompany }}
                                       className="form-label"
                                     >
                                       Company size <span className="text-danger">*</span>
                                     </label>
                                     <div className="input-group input-group-outline mb-3">
                                       <label className="form-label"></label>
                                       <select
                                         className="form-control shadow-none"
                                         aria-label="Select"
                                         onChange={this.handleCompnaySizeChange}
                                         style={{ color: this.state.colorCompany }}
                                       >
                                         <option
                                         selected >
                                         -- Select company size --
                                         </option>
                                         {this.showFeeTypes()}
                                       </select>
                                     </div>
                                   </div>
                                 </div>
                                 <hr />

                                   {!localStorage.getItem('userid') &&
                                 <label
                                   className="h4"
                                   htmlFor="floatingInputCustom"
                                   style={{ color: "#145973" }}
                                 >
                                   Create Login Details <br/>
                                   <span className="text-danger" style={{ fontSize: 18}}>Use this account to login to your dashboard for tracking, renewal and subsequent applications.</span><br/><br/>
                                 </label>
                               }
                                 {!localStorage.getItem('userid') &&
                                 <br />
                               }
                                 {!localStorage.getItem('userid') &&
                                 <br />
                               }

                                   {!localStorage.getItem('userid') &&
                                 <div className="col-sm-6 col-lg-4 col-md-6 mb-3" style={{ display: 'none'}}>
                                   <label
                                     style={{ color: this.state.colorCountryCode }}
                                     className="form-label"
                                   >
                                     Country <span className="text-danger">*</span>
                                   </label>
                                   <div className="input-group input-group-outline mb-3">
                                     <label className="form-label"></label>
                                     <select
                                       className="form-control shadow-none"
                                       aria-label="Floating label select example"
                                       onChange={this.handleCountryChange}
                                     >
                                       <option value="choose" selected="selected">
                                         -- Select country code --
                                       </option>
                                       {this.getCountry()}
                                     </select>
                                   </div>
                                 </div>
                               }

                                   {!localStorage.getItem('userid') &&
                                 <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                   <label
                                     style={{ color: this.state.colorPhone }}
                                     className="form-label"
                                   >
                                     Phone <span className="text-danger">*</span>
                                   </label>
                                   <div className="input-group input-group-outline mb-3">
                                     <label className="form-label"></label>
                                     <input
                                       className="form-control shadow-none"
                                       type="phone"
                                       required="required"
                                       onChange={(e) => this.setState({ phone: e.target.value })}
                                     />
                                   </div>
                                 </div>
                               }


                                 {!localStorage.getItem('userid') &&
                                 <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                   <label
                                     style={{ color: this.state.colorPassword }}
                                     className="form-label"
                                   >
                                     Password <span className="text-danger">*</span>
                                   </label>
                                   <div className="input-group input-group-outline mb-3">
                                     <label className="form-label"></label>
                                     <input
                                       className="form-control shadow-none"
                                       type="text"
                                       required="required"
                                       onChange={(e) => this.setState({ password: e.target.value })}
                                     />
                                   </div>
                                 </div>
                                  }

                                 {!localStorage.getItem('userid') &&
                                 <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                   <label
                                     style={{ color: this.state.colorPasswordConf }}
                                     className="form-label"
                                   >
                                     Confirm Password <span className="text-danger">*</span>
                                   </label>
                                   <div className="input-group input-group-outline mb-3">
                                     <label className="form-label"></label>
                                     <input
                                       className="form-control shadow-none"
                                       type="text"
                                       required="required"
                                       onChange={(e) =>
                                         this.setState({ confirmPassword: e.target.value })
                                       }
                                     />
                                   </div>
                                 </div>
                                  }

                                 <div className="row" style={{ marginTop: 25 }}>
                                   <Form.Floating className="mb-3">
                                     <input
                                       class="form-check-input shadow-none"
                                       type="checkbox"
                                       id="checkBoxUS"
                                       name="US"
                                       value="US"
                                       onChange={this.handleCheckChange}
                                     />{" "}
                                     I hereby certify that the information given above is true
                                   </Form.Floating>
                                 </div>

                                 <div
                                   className="text-center"
                                   style={{
                                     margin: "auto",
                                     width: "100%",
                                     marginTop: 45,
                                     marginBottom: 0,
                                   }}
                                 >
                                   <button
                                     disabled={this.state.disabled}
                                     style={{
                                       alignSelf: "center",
                                       width: "100%",
                                       backgroundColor: "#003314",
                                     }}
                                     className="btn btn-success btn-lg"
                                     onClick={(e) => this.checkValidation(e)}
                                   >
                                     {loading ? (
                                       <Spinner animation="border" variant="light" size="sm" />
                                     ) : (
                                       <span className="font-weight-bold">
                                         SUBMIT APPLICATION
                                       </span>
                                     )}
                                   </button>
                                 </div>
                               </form>
                             </div>

                             </div>
                             </div>


                      </div>
                      </div>
                   {/*   </div>*/}
                    </main>
                                       )
                   })

                 }
                       </div>
               </div>
             }

               <div class="modal-footer">
                 <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
               </div>
             </div>
           </div>
         </div>
         {/* End of Renewal Modal */}

         {/* Start of Print Licence Modal */}
         <div class="modal fade" id="printLicence" tabindex="-1" aria-labelledby="printLicence" aria-hidden="true">
           <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
             <div class="modal-content">
               <div class="modal-header d-flex align-items-center justify-content-between bg-secondary">
                 <h5 class="modal-title text-light font-weight-bold">Print Licence</h5>
                 <button type="button" class="btn btn-link m-0 p-0 text-dark fs-4" data-bs-dismiss="modal" aria-label="Print"><i style={{ fontSize: 27, color: '#fff'}} class="material-icons opacity-10 text-light">print</i></button>
                 <button type="button" class="btn btn-link m-0 p-0 text-light fs-4" data-bs-dismiss="modal" aria-label="Close"><span class="iconify" data-icon="carbon:close"></span></button>
               </div>
                { isLoading ? <Spinner animation="border" style={{ position:'relative', right:0, left: 220, top: 0 }} className="text-center" variant="danger" size="sm" />  :
               <div class="modal-body">
               <div class="container-fluid px-4">
                <h3>Print Licence</h3>
                       </div>
               </div>
             }

               <div class="modal-footer">
                 <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
               </div>
             </div>
           </div>
         </div>
         {/* End of Print Licence Modal */}

        </main>

        </div>
      )
    }
}

export default RenewPremises;
