import { useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react'
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";

export default function Input({type, name, label, placeholder, inputLength, value, index}) {
  const { values, handleChange, errors }  = useFormikContext()
  const [err, setErr] = useState(false)
  

  const getErrorMessage = (name) => {
    if(errors?.education?.[index]){
      const key = name.split(`[${index}].`)[1]
      return errors?.education?.[index][key]
    }

    return errors[name]
  }
    
  const handleBlur = (e) => {
    if(errors[name]){
      setErr(true)
    }
  };


  return (
    <FloatingLabel label={label} className="mb-3">
      <Form.Control
        type={type}
        name={name}
        maxLength={inputLength && inputLength}
        value={ value ? value : values[name]}
        onChange={ handleChange }
        onBlur={handleBlur}        
        isInvalid={err && errors[name]}
        isValid={values[name] && !errors[name]}
        placeholder={placeholder}
      />
      <Form.Control.Feedback type="invalid" className={ `text-danger ${Number.isInteger(index) && "d-block"}`} >
        { getErrorMessage(name) }
      </Form.Control.Feedback>
    </FloatingLabel>
  )
}

