import React from "react";
import { Container, Row, Col } from "reactstrap";
import { useFormikContext } from "formik";
import { Input } from "../UI";

export default function WorkInformation() {
  const formContext = useFormikContext();

  return (
    <Container>
      <h4>Work Information <small className="text-danger"> { `   (Fill if currently employed)` }</small></h4>
      <Row>
        <Col md={6}>
          <Input
            name="organizationName"
            type="text"
            label="Name of Organization/Institution"
            placeholder="Name of Organization/Institution"
            formContext={formContext}
          />
        </Col>
        <Col md={6}>
          <Input
            name="organizationPosition"
            type="text"
            label="Organization Position"
            placeholder="Position"
            formContext={formContext}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Input
            name="organizationTelephone"
            type="number"
            label="Organization Telephone / Others"
            placeholder="Organization Telephone / Others"
            formContext={formContext}
          />
        </Col>
        <Col md={6}>
          <Input
            name="organizationEmail"
            type="email"
            label="Organization Email"
            placeholder="Organization Email"
            formContext={formContext}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Input
            name="organizationAddress"
            type="text"
            label="Organizational Address"
            placeholder="Organizational Address"
            formContext={formContext}
          />
        </Col>
      </Row>
    </Container>
  );
}
