import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const currentPath = window.location.pathname;

export async function fetchLicenceRRR(licenseId) {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  };

  const url = `${baseUrl}/v2/license/license/${licenseId}/generate-rrr`;

  const retryLimit = 5;

  for (let i = 0; i < retryLimit; i++) {
    try {
      const responseJson = await axios.post(url, null, { headers: headers });

      if (responseJson.rrrgenerated) {
        localStorage.setItem("rrr", responseJson.rrr);
        return responseJson.rrr;
      }
    } catch (error) {
      console.log("error retrieving rrr", error);
    }
  }

  // only retry fetching RRR only `retryLimit` times
  // else pop-up modal and route to dashboard
  Swal.fire({
    title: "Error",
    text: "Unable to retrieve your RRR. Please Contact the administrator for assistance.",
    icon: "error",
    confirmButtonText: "OK",
  }).then(() => {
    window.location.href = `${currentPath}#/dashboard`;
  });

  return false;
}

export async function makeLicensePayment(licenseId, rrr) {
  if (!rrr) rrr = await fetchLicenceRRR(licenseId, rrr);

  const url = `${baseUrl}/v2/remita/checkRRRStatus/${rrr}`;

  try {
    const response = await axios.put(url);
    const statusCheckResponse = response.data;

    if (!statusCheckResponse || statusCheckResponse.isSuccessful === false) {
      const msg =
        statusCheckResponse?.message ??
        "Unable to contact Remita. Please try again.";
      Swal.fire({
        title: "Error",
        text: msg,
        icon: "error",
        confirmButtonText: "OK",
      });

      return;
    }

    switch (statusCheckResponse.paymentStatus) {
      case "complete":
        window.location.href = `${currentPath}#/dashboard`;
        window.location.reload();
        break;
      case "pending_confirmation":
        window.location.href = `${currentPath}#/pending-payment`;
        break;
      case "failed":
        let reason =
          statusCheckResponse?.remitaMessage.toLowerCase() ??
          "reason was not unspecified.";
        Swal.fire({
          title: "Error",
          text: "Remita failed to launch! Reason cited: '" + reason + "'",
          icon: "error",
          confirmButtonText: "OK",
        });
      default:
        var paymentEngine = window.RmPaymentEngine.init({
          key: process.env.REACT_APP_REMITA_KEY,
          processRrr: true,
          transactionId: uuidv4(),
          extendedData: {
            customFields: [
              {
                name: "rrr",
                value: statusCheckResponse.rrr,
              },
            ],
          },
          onSuccess: (res) => localStorage.setItem("paymentSuccessful", res),
          onError: (error) => console.error(error),
          onClose: () => {},
        });

        paymentEngine.openIframe();
    }
  } catch (error) {
    Swal.fire({
      title: "Error",
      text: "Failed to initialize payment. Please try again later.",
      icon: "error",
      confirmButtonText: "OK",
    }).then(() => {
      window.location.href = `${currentPath}#/dashboard`;
    });
  }
}

export function makePayment(paymentType, endpoint) {
  if (
    paymentType === "licence" &&
    (localStorage.getItem("rrr")?.length <= 4 || !localStorage.getItem("rrr"))
  ) {
  }

  const url = `${baseUrl}/v2/remita/checkRRRStatus/${localStorage.getItem(
    "rrr"
  )}`;

  fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => await res.json())
    .then((res) => {
      if (res?.paymentStatus === "complete") {
        window.location.href = `${currentPath}#/dashboard`;
        window.location.reload();
      } else {
        const statusCheckResponse = res;

        if (
          !statusCheckResponse ||
          statusCheckResponse.isSuccessful === false
        ) {
          Swal.fire({
            title: "Error",
            text:
              statusCheckResponse?.message ??
              "Unable to contact Remita. Please try again.",
            icon: "error",
            confirmButtonText: "OK",
          });

          return;
        }

        if (statusCheckResponse?.paymentStatus === "pending_confirmation") {
          window.location.href = `${currentPath}#/pending-payment`;
        } else if (statusCheckResponse?.paymentStatus === "failed") {
          let reason =
            statusCheckResponse?.remitaMessage.toLowerCase() ??
            "reason was not unspecified.";
          Swal.fire({
            title: "Error",
            text: "Remita failed to launch. Reason cited: '" + reason + "'",
            icon: "error",
            confirmButtonText: "OK",
          });

          return;
        } else {
          var paymentEngine = window.RmPaymentEngine.init({
            key: process.env.REACT_APP_REMITA_KEY,
            processRrr: true,
            transactionId: uuidv4(),
            extendedData: {
              customFields: [
                {
                  name: "rrr",
                  value: statusCheckResponse.rrr,
                },
              ],
            },
            onSuccess: (response) => {
              localStorage.setItem("paymentSuccessful", response, true);
              if (paymentType === "registration") {
                checkRegistrationPaymentStatus(endpoint);
              }
            },
            onError: (error) => {
              console.warn("callback onError", error);
            },
            onClose: () => {},
          });

          paymentEngine.openIframe();
        }
      }
    });
}

const checkRegistrationPaymentStatus = async (endpoint) => {
  try {
    const res = await axios.post(
      `${baseUrl}/v2/registration/register/${endpoint}`,
      { userId: JSON.parse(sessionStorage.getItem("registration:userId")) }
    );

    if (res?.data?.paymentInformation?.paymentStatus === "complete") {
      window.location.href = `${currentPath}#/dashboard`;
      window.location.reload();
    } else if (
      res?.data?.paymentInformation?.paymentStatus === "pending_confirmation"
    ) {
      window.location.href = `${currentPath}#/pending-payment`;
    }
  } catch (error) {
    if (error?.response?.data?.paymentStatus === "pending_payment") {
      Swal.fire({
        title: "Error",
        text: "Pls proceed to payment",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      Swal.fire({
        title: "Error",
        text:
          `Payment ${error?.response?.data?.paymentStatus}` ||
          "Contact the administrator",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }
};
