import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import AvatarPlaceholder from "../../assets/images/avatar.jpg";
import FilePlaceholder from "../../assets/images/fileImage.png";
import { useFormikContext } from "formik";
import { FieldArray } from "formik";
import { Select, Input } from "../UI";
import Modal from "react-bootstrap/Modal";

export default function Qualifications() {
  const { values, setValues, handleChange, setErrors, errors } =
    useFormikContext();
  const formContext = useFormikContext();
  const [qualificationCount, setQualificationCount] = useState(1);

  const handleCertificateFileChange = (e) => {
    const file = e.target?.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        window.sessionStorage.setItem("NIFST certificate", reader.result);
        setValues((initVal) => ({
          ...initVal,
          nifstCertificateImage: base64String || null,
        })); // Set the base64 string or null in your state
      };

      reader.readAsDataURL(file);
    }
  };

  const practiceOptions = [
    "ACADEMIA/RESEARCH",
    "GOVERNMENT/REGULATORY/POLICY MAKING",
    "INDUSTRY/TRADE/COMMERCE",
    "PRIVATE PRACTICE",
    "NGO",
    "INTERNATIONAL DEVELOPMENT",
  ];

  const handleRemoveQualification = (index) => {
    setQualificationCount((initValues) => initValues - 1);
    if (index > 0) values?.education?.splice(index, 1);
  };

  const handleAddQualification = () => {
    if (qualificationCount <= 10) {
      setQualificationCount((initValues) => initValues + 1);
      values?.education?.push({
        courseOfStudy: "",
        institution: "",
        qualification: "",
        year: "",
        certificateImage: "",
      });
    }
  };

  function calculateAge(dateOfBirth) {
    const dob = new Date(dateOfBirth);
    const currentDate = new Date();
    const age = currentDate.getFullYear() - dob.getFullYear();

    if (
      currentDate.getMonth() < dob.getMonth() ||
      (currentDate.getMonth() === dob.getMonth() &&
        currentDate.getDate() < dob.getDate())
    ) {
      return age - 1; // Subtract 1 if the birthday hasn't occurred yet
    }

    return age;
  }

  const ongoing = JSON.parse(
    window.sessionStorage.getItem("registration:ongoing")
  );
  const ongoingDOB = ongoing?.personalInformation?.dob;

  return (
    <>
      <Row>
        <h4>Qualifications</h4>
      </Row>

      <Row className="mb-5">
        <Col md={6}>
          <Select
            name="categoryOfPractice"
            placeholder="Category of Practice *"
            defaultVal="Select Practice Category"
            label="Category of Practice *"
            options={practiceOptions}
            formContext={formContext}
          />
        </Col>
        <Col md={6}>
          <Input
            name="nifstNumber"
            type="text"
            label="NIFST Registration Number"
            placeholder="NIFST Registration Number"
            formContext={formContext}
          />
          <small className="text-danger py-0 my-0">
            {calculateAge(ongoingDOB) < 60 &&
              !values.nifstNumber &&
              "please provide NIFST number"}{" "}
          </small>
        </Col>
        <Col md={6}>
          <Col md={6}>
            <Form.Label>NIFST Certificate Image (png/jpg)</Form.Label>
            <Form.Control
              size="md"
              name="nifstCertificateImage"
              onChange={handleCertificateFileChange}
              src={
                window.sessionStorage.getItem("NIFST certificate") ||
                AvatarPlaceholder
              }
              accept="images/*"
              label={values.nifstCertificateImage && "NIFST Certificate Image"}
              isValid={values?.nifstCertificateImage}
              type="file"
            />
            <Form.Control.Feedback type="invalid">
              {errors.nifstCertificateImage}
            </Form.Control.Feedback>
          </Col>
        </Col>
      </Row>

      <h6>Education</h6>

      <FieldArray
        name="education"
        render={(_arrayHelpers) => (
          <>
            {values?.education?.map((_data, index) => (
              <EachQualification
                index={index}
                key={"00" + index}
                handleRemoveQualification={handleRemoveQualification}
              />
            ))}
            {/* ================ Add qualification button ================  */}
            {qualificationCount <= 10 && (
              <Col md={12} className="mt-3">
                <Button
                  onClick={handleAddQualification}
                  variant="outline-secondary"
                >
                  <i className="fa fa-plus me-2"></i>
                  Add Qualification
                </Button>
              </Col>
            )}
          </>
        )}
      />
    </>
  );
}

const EachQualification = ({ index, handleRemoveQualification }) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const { values, setValues, errors } = useFormikContext();
  const formContext = useFormikContext();

  const qualificationOptions = [
    "OND",
    "HND",
    "PGD",
    "B.Tech",
    "B.Sc",
    "B.Eng",
    "MBBS",
    "M.Tech",
    "M.Sc",
    "Ph.D",
  ];

  const firstQualification = ["OND", "HND", "B.Sc", "B.Tech", "B.Eng", "MBBS"];

  const ongoing = JSON.parse(
    window.sessionStorage.getItem("registration:ongoing")
  );
  const ongoingPics =
    ongoing?.qualification?.education[index]?.certificateImage;

  function generateYearsArray() {
    const currentYear = new Date().getFullYear();
    const startYear = 1900;

    // Using Array.from and Array.keys to generate an array of years
    const yearsArray = Array.from(
      Array(currentYear - startYear + 1).keys(),
      (year) => startYear + year
    );

    return yearsArray;
  }

  const handleFileChange = (e) => {
    const file = e.target?.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        setPreviewImage(reader.result);
        setValues((initVal) => ({
          ...initVal,
          education: initVal.education.map((edu, i) =>
            i === index ? { ...edu, certificateImage: base64String } : edu
          ),
        }));
        window.sessionStorage.setItem(`certificate${[index]}`, reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Row key={index} className="border-bottom mt-2">
        <Col md={7}>
          <Row>
            <Col md={6}>
              <Input
                name={`education[${index}].courseOfStudy`}
                value={values.education[index].courseOfStudy}
                type="text"
                index={index}
                label="Course of Study *"
                formContext={formContext}
                placeholder="Course of Study *"
              />
            </Col>
            <Col md={6}>
              <Input
                name={`education[${index}].institution`}
                value={values.education[index].institution}
                type="text"
                index={index}
                label="Tertiary Institution *"
                formContext={formContext}
                placeholder="Tertiary Institution *"
              />
            </Col>
            <Col md={6}>
              <Select
                name={`education[${index}].qualification`}
                value={values.education[index].qualification}
                type="select"
                index={index}
                placeholder="Qualification *"
                label="Qualification *"
                defaultVal={"Select Qualification"}
                formContext={formContext}
                options={
                  index === 0 ? firstQualification : qualificationOptions
                }
              />
            </Col>

            <Col md={6}>
              <Select
                name={`education[${index}].year`}
                type="select"
                value={values.education[index].year}
                label="Year *"
                index={index}
                placeholder="Year *"
                defaultVal={"Select Year"}
                options={generateYearsArray()}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>
              Certificate Image (png/jpg) *
              <div className="mt-2" onClick={() => setShowModal(true)}>
                <Image
                  alt="certificate image"
                  style={{ width: "auto", height: "150px" }}
                  className="mt-2"
                  src={
                    previewImage ||
                    (ongoingPics?.includes(process.env.REACT_APP_BASE_URL)
                      ? ongoingPics
                      : FilePlaceholder)
                  }
                  thumbnail
                />
              </div>
            </Form.Label>
            <Form.Control
              name={`education[${index}].certificateImage`}
              onChange={handleFileChange}
              label={
                values.education[index]?.certificateImage && "Certificate Image"
              }
              accept="images/*"
              type="file"
            />

            {errors.education && (
              <Form.Control.Feedback type="invalid">
                {errors.education[index]?.certificateImage}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>

        <Col md={1} key={"000" + index}>
          <Button
            style={{
              visibility: index === 0 ? "hidden" : "visible",
            }}
            disabled={index === 0}
            title="Remove"
            onClick={() => handleRemoveQualification(index)}
            variant="outline-secondary"
          >
            <i className="fa fa-times"></i>
          </Button>
        </Col>
      </Row>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Certificate Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showModal && (
            <Image
              className="h-100 w-100"
              src={
                previewImage ||
                (ongoingPics?.includes(process.env.REACT_APP_BASE_URL)
                  ? ongoingPics
                  : AvatarPlaceholder)
              }
              fluid
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
