import React, { Component } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";

import moment from "moment";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import NumberFormat from "react-number-format";
import logo from "../assets/images/logo.png";
import profileImage from "../assets/images/invoice.jpeg";
let CryptoJS = require("crypto-js");

const baseUrl = process.env.REACT_APP_BASE_URL;

class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      paymentData: [],
      printPaymentDetails: [],
      postsPerPage: 10,
      currentPage: 1,
      loading: true,
      isLoading: false,
      hasError: false,
      rrr: "",
      noRecords: false,
      isVerifiedLoading: false,
      isPrintLoading: false,
      isGenerating: false,
      isDisabled: false,
      loadVerify: false,
    };
  }

  // static getDerivedStateFromError(error){
  //    return this.setState({ hasError: true})
  // }
  //
  // componentDidCatch(error){
  //   console.log("error occurred")
  // }

  print() {
    window.print();
  }

  getUserPaymentDetails = (recid) => {
    const url = `${baseUrl}/Payments/getPaymentsByRecID/${recid}`;
    this.setState({ isVerifiedLoading: true });
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          isVerifiedLoading: false,
          paymentData: res,
        });
      })
      .catch((error) => {
        this.setState({ error: true, isVerifiedLoading: false });
        alert(error);
      });
  };

  payNow = async (value) => {
    let d = new Date();
    let orderId = d.getTime();

    let values = value.split(",");
    // console.warn(values);

    localStorage.setItem("rrr", values[0]);

    const obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        amount: values[2],
        formid: values[3],
        formtype: "registration",
        message: "Payment Initialized",
        orderid: values[1],
        rrr: values[0],
        status: "Pending",
        userid: localStorage.getItem("userid"),
      }),
    };
    await fetch(`${baseUrl}/Payments/initPayment`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.warn(responseJson);
        if (responseJson.status === 401) {
          this.setState({ loading: false });
          Swal.fire({
            title: "Session Expired",
            text: "Session expired. Please login",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            this.props.history.push("/login");
          });
        } else {
          // this.retrievePayments();
        }
      })
      .catch((error) => {
        this.setState({ loading: false, disabled: false });
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  // checkPaymentStatus = async (rrr) => {
  //   this.setState({isVerifiedLoading: true});
  //   let merchantId = "9554487021";
  //   let apiKey = "520436"
  //   let serviceTypeId = "4430731"
  //   let d = new Date();
  //   let orderId = d.getTime();
  //   let totalAmount = "10000";
  //   let rrr_value = `${rrr}`;
  //   let apiHash = CryptoJS.SHA512(rrr + apiKey + merchantId);
  //
  //   let req = {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: `remitaConsumerKey=${merchantId},remitaConsumerToken=${apiHash}`,
  //     },
  //   };
  //
  //   await fetch(`https://login.remita.net/remita/exapp/api/v1/send/api/echannelsvc/${merchantId}/${rrr}/${apiHash}/status.reg`, req)
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       // console.warn(responseJson);
  //       this.setState({isVerifiedLoading: false});
  //       Swal.fire({
  //       title: '<strong>PAYMENT STATUS</u></strong>',
  //       icon: 'info',
  //       html:
  //         '<b>RRR:</b> ' + responseJson.RRR + '<br/><br/>'+
  //         '<b>AMOUNT:</b> ' +responseJson.amount+ '<br/><br/>'+
  //         '<b>STATUS:</b> ' + responseJson.message+ '<br/><br/>'+
  //         '<b>DATE:</b> ' + responseJson.transactiontime,
  //       showCloseButton: true,
  //       showCancelButton: false,
  //       focusConfirm: false,
  //       confirmButtonText:
  //         'OK',
  //       confirmButtonAriaLabel: 'Thumbs up, great!',
  //       cancelButtonText:
  //         '<i class="fa fa-thumbs-down"></i>',
  //       cancelButtonAriaLabel: 'Thumbs down'
  // })
  //     })
  //     .catch((error) => {
  //       this.setState({ isVerifiedLoading: false, disabled: false });
  //       Swal.fire({
  //         title: "Error!",
  //         text: error.message,
  //         icon: "error",
  //         confirmButtonText: "OK",
  //       });
  //     });
  //   }

  checkPaymentStatus = async (rrr) => {
    this.setState({ isVerifiedLoading: true });
    const url = `${baseUrl}/Remita/checkRRRStatus/${rrr}`;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ isVerifiedLoading: false });
        Swal.fire({
          title: "<strong>PAYMENT STATUS</u></strong>",
          icon: "info",
          html:
            "<b>RRR:</b> " +
            res.RRR +
            "<br/><br/>" +
            "<b>AMOUNT:</b> " +
            res.amount +
            "<br/><br/>" +
            "<b>STATUS:</b> " +
            res.message +
            "<br/><br/>" +
            "<b>DATE:</b> " +
            res.transactiontime,
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: "OK",
          confirmButtonAriaLabel: "Thumbs up, great!",
          cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
          cancelButtonAriaLabel: "Thumbs down",
        });
      });
  };

  printPaymentDetails = async (recid) => {
    this.setState({ isPrintLoading: true });
    // let merchantId = "9554487021";
    // let apiKey = "520436"
    // let serviceTypeId = "4430731"
    // let d = new Date();
    // let orderId = d.getTime();
    // let totalAmount = "10000";
    // let rrr_value = `${rrr}`;
    // let apiHash = CryptoJS.SHA512(rrr + apiKey + merchantId);

    let req = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    await fetch(`${baseUrl}/Payments/getPaymentsByRecID/${recid}`, req)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.warn(responseJson);
        this.setState({
          printPaymentDetails: responseJson,
          isPrintLoading: false,
        });

        //   Swal.fire({
        //   title: '<strong>PAYMENT STATUS</u></strong>',
        //   icon: 'info',
        //   html:
        //     '<b>RRR:</b> ' + responseJson.RRR + '<br/><br/>'+
        //     '<b>AMOUNT:</b> ' +responseJson.amount+ '<br/><br/>'+
        //     '<b>STATUS:</b> ' + responseJson.message+ '<br/><br/>'+
        //     '<b>DATE:</b> ' + responseJson.transactiontime,
        //   showCloseButton: true,
        //   showCancelButton: false,
        //   focusConfirm: false,
        //   confirmButtonText:
        //     'OK',
        //   confirmButtonAriaLabel: 'Thumbs up, great!',
        //   cancelButtonText:
        //     '<i class="fa fa-thumbs-down"></i>',
        //   cancelButtonAriaLabel: 'Thumbs down'
        // })
      })
      .catch((error) => {
        this.setState({ isPrintLoading: false, disabled: false });
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  generateRRR = async () => {
    this.setState({ isGenerating: true, isDisabled: true });
    let merchantId = "9554487021";
    let apiKey = "520436";
    let serviceTypeId = localStorage.getItem("membership-individual-remita-id");
    let d = new Date();
    let orderId = d.getTime();
    let totalAmount = localStorage.getItem("membership-individual-amount");
    let apiHash = CryptoJS.SHA512(
      merchantId + serviceTypeId + orderId + totalAmount + apiKey
    );

    let req = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `remitaConsumerKey=${merchantId},remitaConsumerToken=${apiHash}`,
      },
      body: JSON.stringify({
        serviceTypeId: `${serviceTypeId}`,
        amount: `${totalAmount}`,
        orderId: orderId,
        payerName: `${localStorage.getItem("firstName")} ${localStorage.getItem(
          "surname"
        )}`,
        payerEmail: localStorage.getItem("email"),
        payerPhone: localStorage.getItem("phone"),
        description: localStorage.getItem("membership-individual-description"),
      }),
    };

    fetch(
      `https://login.remita.net/remita/exapp/api/v1/send/api/echannelsvc/merchant/api/paymentinit`,
      req
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 401) {
          this.setState({ isGenerating: false, isDisabled: false });
          Swal.fire({
            title: "Session Expired",
            text: "Session expired. Please login",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            this.props.history.push("/login");
          });
        } else if (responseJson.status === "Payment Reference generated") {
          this.setState({
            rrr: responseJson.RRR,
            isGenerating: false,
            isDisabled: false,
          });

          fetch(`${baseUrl}/Remita/checkRRStatus/${responseJson.RRR}`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
            .then((res) => res.json())
            .then((res) => {
              console.warn(res);
              // this.setState({
              //   isPreviewLoading: false,
              //   paymentData: res,
              // });
            })
            .catch((error) => {
              this.setState({ error: true, isPreviewLoading: false });
              alert(error);
            });
        }
      })
      .catch((error) => {
        this.setState({ isGenerating: false, isDisabled: false });
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  initializePayment = async () => {
    let d = new Date();
    let orderId = d.getTime();

    const obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        amount: localStorage.getItem("membership-individual-amount"),
        formid: localStorage.getItem("formid"),
        formtype: "registration",
        message: "Payment Initialized",
        orderid: orderId,
        rrr: localStorage.getItem("rrr_food"),
        status: "Pending",
        userid: localStorage.getItem("userid"),
      }),
    };
    await fetch(`${baseUrl}/Payments/initPayment`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        console.warn(responseJson);
        if (responseJson.status === 401) {
          this.setState({ loading: false });
          Swal.fire({
            title: "Session Expired",
            text: "Session expired. Please login",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            this.props.history.push("/login");
          });
        } else {
          // this.retrievePayments();
        }
      })
      .catch((error) => {
        this.setState({ loading: false, disabled: false });
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  showPagination = () => {
    const { postsPerPage, data } = this.state;
    const pageNumbers = [];
    const totalPosts = data.length;
    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
      pageNumbers.push(i);
    }

    const paginate = (pageNumbers) => {
      this.setState({ currentPage: pageNumbers });
    };

    return (
      <nav>
        <ul className="pagination">
          {pageNumbers.map((number) => (
            <li
              key={number}
              className={
                this.state.currentPage === number
                  ? "page-item active"
                  : "page-item"
              }
            >
              <button onClick={() => paginate(number)} className="page-link">
                {number}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    );
  };

  showTable = () => {
    const { postsPerPage, currentPage, data } = this.state;
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

    try {
      return currentPosts.map((item, index) => {
        return (
          <tbody>
            <tr>
              <td>
                <span className="text-xs font-weight-bold">
                  {postsPerPage * (currentPage - 1) + index + 1}
                </span>
              </td>
              <td className="text-xs text-secondary text-capitalize font-weight-bold">
                {item.rrr}
              </td>
              <td className="text-xs text-secondary text-capitalize font-weight-bold">
                {item.description}
              </td>
              <td className="text-xs font-weight-bold">
                <NumberFormat
                  value={item.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={(value) => <span> {value}</span>}
                />
              </td>
              <td
                className={
                  item.message === "Successful"
                    ? "badge bg-success mt-2"
                    : item.message == "Payment Initialized"
                    ? "badge bg-warning text-dark mt-2"
                    : "btn btn-danger mt-2"
                }
              >
                {item.message}
              </td>
              <td className="text-xs font-weight-bold">
                {moment(item.transactiontime).format("LL") === "Invalid date"
                  ? null
                  : moment(item.transactiontime).format("LL")}
              </td>
              <td>
                <button
                  className="btn btn-primary-2 mb-0"
                  id="dropdownMenuButton2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span
                    class="iconify"
                    data-icon="charm:menu-meatball"
                    style={{ fontSize: "large" }}
                  ></span>
                </button>
                <ul
                  className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4"
                  aria-labelledby="#dropdownMenuButton2"
                >
                  <li
                    className="mb-2"
                    id={`${item.rrr}, ${item.orderid}, ${item.amount}, ${item.formid}`}
                    onClick={() =>
                      this.payNow(
                        `${item.rrr}, ${item.orderid}, ${item.amount}, ${item.formid}`
                      )
                    }
                    data-bs-toggle="modal"
                    data-bs-target="#viewPayments"
                  >
                    <a
                      className="dropdown-item border-radius-md"
                      href="javascript:;"
                    >
                      <div className="d-flex py-1">
                        <h6 className="text-sm font-weight-normal mb-1">
                          <span className="font-weight-bold">Pay Now</span>
                        </h6>
                      </div>
                    </a>
                  </li>

                  <li
                    className="mb-2"
                    id={item.recid}
                    onClick={() => this.printPaymentDetails(item.recid)}
                    data-bs-toggle="modal"
                    data-bs-target="#printPayments"
                  >
                    <a
                      className="dropdown-item border-radius-md"
                      href="javascript:;"
                    >
                      <div className="d-flex py-1">
                        <h6 className="text-sm font-weight-normal mb-1">
                          <span className="font-weight-bold">
                            Print Pay Advice / Receipt
                          </span>
                        </h6>
                      </div>
                    </a>
                  </li>

                  <li
                    className="mb-2"
                    id={item.rrr}
                    onClick={() => this.checkPaymentStatus(item.rrr)}
                  >
                    <a
                      className="dropdown-item border-radius-md"
                      href="javascript:;"
                    >
                      <div className="d-flex py-1">
                        <h6 className="text-sm font-weight-normal mb-1">
                          <span className="font-weight-bold">
                            Verify Payment
                          </span>
                        </h6>
                      </div>
                    </a>
                  </li>

                  {/*  <li className="mb-2" data-bs-toggle="modal" data-bs-target="#generate">
            <a className="dropdown-item border-radius-md" href="javascript:;">
              <div className="d-flex py-1">
                  <h6 className="text-sm font-weight-normal mb-1">
                    <span className="font-weight-bold">Generate New RRR</span>
                  </h6>
              </div>
            </a>
          </li> */}
                </ul>
              </td>
              <td></td>
            </tr>
          </tbody>
        );
      });
    } catch (e) {
      Swal.fire({
        title: "Error",
        text: e.message,
        type: "error",
      });
    }
  };

  retrievePayments = async () => {
    let obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await fetch(
      `${baseUrl}/Payments/getPaymentsByUserID/${localStorage.getItem(
        "userid"
      )}`,
      obj
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 401) {
          this.setState({ loading: false });
          Swal.fire({
            title: "Session Expired",
            text: "Session expired. Please login",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            this.props.history.push("/login");
          });
        } else if (responseJson.length <= 0) {
          this.setState({ noRecords: true, loading: false });
        } else {
          this.setState({ data: responseJson, loading: false });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  componentDidMount() {
    this.retrievePayments();
  }

  render() {
    const {
      isVerifiedLoading,
      noRecords,
      isPrintLoading,
      paymentPrintData,
      printPaymentDetails,
      isGenerating,
      isDisabled,
      disabled,
      loadVerify,
      loading,
      isLoading,
      paymentData,
    } = this.state;

    return (
      <div className="g-sidenav-show">
        <Sidebar />

        <main
          className="main-content position-relative max-height-vh-100 h-100 border-radius-lg "
          style={{ width: "80%", float: "right" }}
        >
          <div
            className="container-fluid justify-content-center py-4"
            style={{ width: "100%", position: "relative", left: 0 }}
          >
            <div class="container-fluid px-4">
              <div class="rown">
                <div class="col-12">
                  <div class="card my-3">
                    <div class="card-header pb-0 bg-success">
                      <div class="d-flex flex-wrap align-items-center justify-content-center">
                        <h5 className="mb-4 text-light font-weight-bold">
                          {" "}
                          {this.state.isVerifiedLoading ? (
                            <center>
                              <h5 className="text-light">
                                Verifying Payment ...{" "}
                              </h5>
                              <Spinner
                                animation="border"
                                className="text-center"
                                variant="light"
                                size="lg"
                              />
                            </center>
                          ) : (
                            "My Payment History"
                          )}
                        </h5>
                      </div>
                    </div>
                    <div class="card-body">
                      {loading ? (
                        <center>
                          <Spinner
                            animation="border"
                            variant="success"
                            size="lg"
                            style={{ alignSelf: "center" }}
                            className="text-center"
                          />
                        </center>
                      ) : (
                        <div class="table-responsive p-0 pb-2">
                          <table
                            id="table"
                            className="table align-items-center justify-content-center mb-0"
                          >
                            <thead>
                              <tr>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                  S/N
                                </th>
                                <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">
                                  RRR
                                </th>
                                <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">
                                  Payment Description
                                </th>
                                <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">
                                  Amount (&#8358;)
                                </th>
                                <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">
                                  Message
                                </th>
                                <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">
                                  Transaction Date
                                </th>
                                <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            {this.showTable()}
                          </table>
                          {noRecords && (
                            <center>
                              <p>No payment record found.</p>
                            </center>
                          )}
                        </div>
                      )}

                      <div style={{ float: "right" }}>
                        {this.showPagination()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
          <br />
          <br />

          {/* Start of View Modal */}
          <div
            class="modal fade"
            id="viewPayments"
            tabindex="-1"
            aria-labelledby="viewRunning"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header d-flex align-items-center justify-content-between">
                  <h5 class="modal-title">Make Payment</h5>
                  <button
                    type="button"
                    class="btn btn-link m-0 p-0 text-dark fs-4"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span class="iconify" data-icon="carbon:close"></span>
                  </button>
                </div>
                {isLoading ? (
                  <Spinner
                    animation="border"
                    style={{
                      position: "relative",
                      right: 0,
                      left: 220,
                      top: 0,
                    }}
                    className="text-center"
                    variant="danger"
                    size="sm"
                  />
                ) : (
                  <div class="modal-body">
                    <center>
                      <a
                        className="btn btn-success"
                        target="_blank"
                        href={process.env.PUBLIC_URL + "remita.html"}
                        style={{ padding: 13 }}
                      >
                        Click Here to Pay With Remita
                      </a>
                    </center>
                  </div>
                )}
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* End of View Modal */}

          {/* Start of Verify Modal */}
          <div
            class="modal fade"
            id="verifyPayments"
            tabindex="-1"
            aria-labelledby="viewRunning"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header d-flex align-items-center justify-content-between bg-secondary">
                  <h5 class="modal-title text-light font-weight-bold">
                    Verify Payment
                  </h5>
                  <button
                    type="button"
                    class="btn btn-link m-0 p-0 text-light fs-4"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span class="iconify" data-icon="carbon:close"></span>
                  </button>
                </div>
                {isVerifiedLoading ? (
                  <center>
                    <Spinner
                      animation="border"
                      className="text-center"
                      variant="success"
                      size="lg"
                    />
                  </center>
                ) : (
                  <div class="modal-body">
                    <div class="container-fluid px-4">
                      {paymentData.length > 0 &&
                        paymentData.map((item) => {
                          return (
                            <div class="rown">
                              <div class="col-12">
                                <div>
                                  {/*<div class="card-header pb-0">
                       <div class="d-flex flex-wrap align-items-center justify-content-between">
                         <h5>Verify Payment</h5>
                       </div>
                     </div> */}
                                  <div class="card-body">
                                    <form id="payment-form">
                                      <div class="form-floating mb-4">
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="Enter RRR"
                                          value={item.rrr}
                                        />
                                        <label for="rrr">Enter RRR</label>
                                      </div>
                                      <button
                                        type="button"
                                        disabled={disabled}
                                        style={{
                                          alignSelf: "center",
                                          width: "100%",
                                          backgroundColor: "#003314",
                                        }}
                                        className="btn btn-success btn-lg"
                                        onClick={() =>
                                          this.checkPaymentStatus(item.rrr)
                                        }
                                      >
                                        {isVerifiedLoading ? (
                                          <Spinner
                                            animation="border"
                                            variant="light"
                                            size="sm"
                                          />
                                        ) : (
                                          <span className="font-weight-bold">
                                            {/* APPLY <i class="fas fa-chevron-right"></i> */}
                                            Verify Payment
                                          </span>
                                        )}
                                      </button>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}

                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* End of Verify Modal */}

          {/* Start of Print Payment Modal */}
          <div
            class="modal fade"
            id="printPayments"
            tabindex="-1"
            aria-labelledby="viewRunning"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header d-flex align-items-center justify-content-between bg-secondary">
                  <h5 class="modal-title text-light font-weight-bold">
                    Print Payment Slip
                  </h5>
                  <div class="d-flex align-items-center">
                    <button class="btn bg-success text-light font-weight-bold mb-0">
                      {" "}
                      <span
                        class="iconify"
                        data-icon="carbon:printer"
                        style={{ fontSize: 36 }}
                      ></span>{" "}
                      Print
                    </button>
                  </div>
                  <button
                    type="button"
                    class="btn btn-link m-0 p-0 text-light fs-4"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span class="iconify" data-icon="carbon:close"></span>
                  </button>
                </div>
                {isPrintLoading ? (
                  <center>
                    <Spinner
                      animation="border"
                      className="text-center"
                      variant="success"
                      size="lg"
                    />
                  </center>
                ) : (
                  <div class="modal-body">
                    <div class="container-fluid px-4">
                      {printPaymentDetails.length > 0 &&
                        printPaymentDetails.map((item) => {
                          return (
                            <div class="rown">
                              <div class="col-12">
                                <div>
                                  <center>
                                    <img
                                      src={logo}
                                      className="navbar-brand-img mb-2"
                                      alt="main_logo"
                                      style={{ width: 81, marginTop: 9 }}
                                    />
                                    <h6>
                                      NIGERIAN COUNCIL OF FOOD SCIENCE AND
                                      TECHNOLOGY (NiCFoST)
                                    </h6>
                                  </center>
                                  <div class="card-body">
                                    <p className="font-weight-bold">
                                      Name: {item.payer}
                                    </p>
                                    <p className="font-weight-bold">
                                      RRR: {item.rrr}
                                    </p>
                                    <p className="font-weight-bold">
                                      Payment Description:{" "}
                                      {`${item.description}/ ${item.formtype}`}
                                    </p>
                                    <p className="font-weight-bold">
                                      Amount: &#8358;{`${item.amount}`}
                                    </p>
                                    <p className="font-weight-bold mb-4">
                                      Status: {`${item.message}`}
                                    </p>
                                    <p className="text-danger">
                                      NB: Remita charges apply at the point of
                                      payment
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* End of Print Payment Modal */}

          {/* Start of Generate RRR */}
          <div
            class="modal fade"
            id="generate"
            tabindex="-1"
            aria-labelledby="viewRunning"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header d-flex align-items-center justify-content-between bg-secondary">
                  <h5 class="modal-title text-light font-weight-bold">
                    Generate RRR
                  </h5>
                  <button
                    type="button"
                    class="btn btn-link m-0 p-0 text-light fs-4"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span class="iconify" data-icon="carbon:close"></span>
                  </button>
                </div>
                {isVerifiedLoading ? (
                  <center>
                    <Spinner
                      animation="border"
                      className="text-center"
                      variant="success"
                      size="lg"
                    />
                  </center>
                ) : (
                  <div class="modal-body">
                    <div class="container-fluid px-4">
                      <div class="rown">
                        <div class="col-12">
                          <div>
                            {/*<div class="card-header pb-0">
                       <div class="d-flex flex-wrap align-items-center justify-content-between">
                         <h5>Verify Payment</h5>
                       </div>
                     </div> */}
                            <div class="card-body">
                              <form id="payment-form">
                                <div class="form-floating mb-4">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Enter RRR"
                                    value={this.state.rrr}
                                  />
                                  <label for="rrr">RRR</label>
                                </div>
                                <button
                                  type="button"
                                  disabled={isDisabled}
                                  style={{
                                    alignSelf: "center",
                                    width: "100%",
                                    backgroundColor: "#003314",
                                  }}
                                  className="btn btn-success btn-lg"
                                  onClick={() => this.generateRRR()}
                                >
                                  {isGenerating ? (
                                    <Spinner
                                      animation="border"
                                      variant="light"
                                      size="sm"
                                    />
                                  ) : (
                                    <span className="font-weight-bold">
                                      {/* APPLY <i class="fas fa-chevron-right"></i> */}
                                      Click to Generate RRR
                                    </span>
                                  )}
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* End of Verify Modal */}
        </main>
      </div>
    );
  }
}

export default Payments;
