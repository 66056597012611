import React, { Component } from "react";
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
// require("bootstrap/less/bootstrap.less");

class NewPagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: this.props.activePage
    };
  }

  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({activePage: pageNumber});
    this.props.getPageChanged(pageNumber)
  }

  render() {
    const {activePage, totalItemsCount} = this.props

    return (
      <div style={{ float: "inline-end" }}>
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          activePage={activePage}
          itemsCountPerPage={10}
          totalItemsCount={totalItemsCount}
          pageRangeDisplayed={5}
          onChange={this.handlePageChange.bind(this)}
        />
      </div>
    );
  }
}


export default NewPagination