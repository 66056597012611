import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Form, Spinner } from "react-bootstrap";
 
import { states } from "../components/States";
import moment from 'moment';
import Footer from "../components/Footer";
import Sidebar from '../components/Sidebar';
import NumberFormat from 'react-number-format';
import coat from "../assets/images/coat.png";
import logo from "../assets/images/logo.png";
import profileImage from '../assets/images/invoice.jpeg';
let CryptoJS = require("crypto-js");
let PASSPORT_FILEBASE64 = "";
let TOKEN = "";


const baseUrl = process.env.REACT_APP_BASE_URL


class Inspection extends Component {

  constructor(props){
    super(props);
    this.state = {
     companySize: [],
     companySizeSingle: '',
     companyName: "",
     companyAddress: "",
     companyState: "",
     companyLGA: "",
     businessType: "",
     buisnessDescription: "",
     productBrands: "",
     contactName: "",
     contactEmail: "",
     contactPhone: "",
     password: "",
     name: "",
     email: "",
     phone: "",
     password: "",
     confirmPassword: "",
     boxAccept: false,
     isLoading: false,
     isDisabled: false,

     //VALIDATION VARIABLES
     companyNameColor: 'black',
     companyAddressColor: 'black',
     companyLGAColor: 'black',
     companyStateColor: 'black',
     businessTypeColor: 'black',
     companySizeColor: 'black',
     businessDescriptionColor: 'black',
     brandColor: 'black',
     nameColor: 'black',
     emailColor: 'black',
     phoneColor: 'black',
     passwordColor: 'black',
     confirmPasswordColor: 'black',
     passportImageColor: 'black'
    }
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleBusinessTypeChange = this.handleBusinessTypeChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.handlePassportImage = this.handlePassportImage.bind(this);
  }

  handleStateChange(e) {
    this.setState({ companyState: e.target.value });
  }
  handleBusinessTypeChange(e) {
    this.setState({ businessType: e.target.value });
  }

  getStates() {
    return states.map((state) => {
      return <option value={state}>{state}</option>;
    });
  }

  handleCheckChange(e) {
    this.setState({ boxAccept: e.target.checked }, () => {
    });
  }

  async handlePassportImage(e){
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(e.target.files[0]);
        fileReader.onload = () => {
            resolve(fileReader.result);
            PASSPORT_FILEBASE64 = fileReader.result
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
  }

  uploadImage = async () => {
    this.setState({ isLoading: true });
    var obj = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem('token'),
      },
      body: JSON.stringify({
        imageToBase64String: PASSPORT_FILEBASE64,
        userid: localStorage.getItem("userid"),
      }),
    };
    await fetch(`${baseUrl}/Registration/uploadRegistrationImage`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message === "Image Updated Successfully") {
          this.addInspection();
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false, disabled: false });
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  addInspection = async() => {
    let date = new Date();
    this.setState({ isLoading: true });

     let obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        addresslga: this.state.companyLGA,
        addressstate: this.state.companyState,
        addressstreet: this.state.companyAddress,
        applicationdate: date.getTime(),
        businessdescription: this.state.businessDescription,
        businesstype: this.state.businessType,
        companysize: "",
        contactemail: this.state.email,
        contactname: this.state.name,
        contactphone: this.state.phone,
        organisationname: this.state.companyName,
        productbrands: this.state.productBrands,
        userid: localStorage.getItem("userid")
      }),
    };
    await fetch(`${baseUrl}/Inspection/addInspection`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        console.warn(responseJson);
        if (responseJson.message === "New Inspection added Successfully") {
          Swal.fire({
            title: "Success",
            text: "Registration successful",
            icon: "success",
            confirmButtonText: "OK",
          }).then(()=> {
            this.props.history.push("/dashboard")
          })
        } else {
          this.setState({ isLoading: false, isDisabled: false });
          Swal.fire({
            title: "Error!",
            text: responseJson.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false, isDisabled: false });
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  }


  //VALIDATE USER CREDENTIALS
  checkValidation = () => {
     const { companyName, companyAddress, companyLGA, companyState, businessType, businessDescription, productBrands, name, email, phone, password, confirmPassword, boxAccept, companyNameColor, companyAddressColor, companyLGAColor, companyStateColor, businessTypeColor, businessDescriptionColor, brandColor, nameColor, emailColor, phoneColor, passwordColor, confirmPasswordColor, passportImageColor } = this.state;
      if(companyName === ""){
        Swal.fire({
          title: "Empty",
          text: "Please enter company / business name",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ companyNameColor: 'red'})
      }else if(companyAddress === ""){
        Swal.fire({
          title: "Empty",
          text: "Please enter company / business address",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ companyAddressColor: 'red'})
      }else if(companyLGA === ""){
        Swal.fire({
          title: "Empty",
          text: "Please enter company / business LGA",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ companyLGAColor: 'red'})
      }else if(companyState === ""){
        Swal.fire({
          title: "Empty",
          text: "Please enter company / business State Location",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ companyStateColor: 'red'})
      }else if(businessType === ""){
        Swal.fire({
          title: "Empty",
          text: "Please enter company / business Type",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ businessTypeColor: 'red'})
      }else if(businessDescription === ""){
        Swal.fire({
          title: "Empty",
          text: "Please enter company / business description",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ companyDescriptionColor: 'red'})
      }else if (productBrands === ""){
        Swal.fire({
          title: "Empty",
          text: "Please enter product brands",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ brandColor: 'red'})
      }else if(name == ""){
        Swal.fire({
          title: "Empty",
          text: "Please enter representative name",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ name: 'red'})
      }else if(email == ""){
        Swal.fire({
          title: "Empty",
          text: "Please enter representative email",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ email: 'red' })
      }else if(phone === ""){
        Swal.fire({
          title: "Empty",
          text: "Please enter representative phone number",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ phone: 'red' })
      }else if(password === "" && !localStorage.getItem('userid')){
        Swal.fire({
          title: "Empty",
          text: "Please enter your password",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ passwordColor: 'red' })
      }else if(confirmPassword === "" && !localStorage.getItem('userid')){
        Swal.fire({
          title: "Empty",
          text: "Please confirm your password",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ confirmPasswordColor: 'red' })
      }else if(PASSPORT_FILEBASE64 === "" && !localStorage.getItem('userid')){
        Swal.fire({
          title: "Empty",
          text: "Please upload your recent passport photograph",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ passportImageColor: 'red' })
      }
      else if (boxAccept === false) {
        Swal.fire({
          title: "Accept The Terms",
          text: "Please accept the terms and conditions",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ isLoading: false, disabled: false });
      }else{
        this.checkAuth();
      }
  }


  //CREATE NEW USER
  register = async (e) => {
    const { email, phone, password, confirmPassword } = this.state;
    this.setState({ isLoading: true });

     let obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: "Bearer " + TOKEN,
      },
      body: JSON.stringify({
        confirmpassword: confirmPassword,
        countryid: "+234",
        email: email,
        password: password,
        telephone:phone,
      }),
    };
    await fetch(`${baseUrl}/Users/newUser`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message === "Registration Successfull") {
          localStorage.setItem("userid", responseJson.userid);
          let req = {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userid: responseJson.userid,
            }),
          };
          fetch(`${baseUrl}/Users/activateUser`, req)
            .then((response) => response.json())
            .then((responseJson) => {
              if (responseJson.message === "User Activated Successfully") {
                 this.login()
              } else {
                this.setState({ isLoading: false });
                Swal.fire({
                  title: "Error!",
                  text: responseJson.message,
                  icon: "error",
                  confirmButtonText: "OK",
                });
              }
            })
            .catch((error) => {
              this.setState({ isLoading: false, isDisabled: false });
              Swal.fire({
                title: "Error!",
                text: error.message,
                icon: "error",
                confirmButtonText: "OK",
              });
            });
        } else {
          this.setState({ isLoading: false, isDisabled: false });
          Swal.fire({
            title: "Error!",
            text: responseJson.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false, isDisabled: false });
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  //CHECK IF USER IS ALREADY REGISTERED
  checkAuth(){
    if(localStorage.getItem('userid')){
      this.addInspection()
    }else{
      this.register();
    }
  }

  //LOGIN USER
  login = async (e) => {
    var obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        countryid: "+234",
        password: this.state.password,
        telephone: this.state.phone,
      }),
    };
    fetch(`${baseUrl}/Users/login`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.warn(responseJson);
        if (responseJson.message === "Login Successfull") {
          this.setState({ isLoading: false });
          localStorage.setItem("userid", responseJson.userid);
          localStorage.setItem("email", responseJson.email);
          TOKEN = responseJson.token;
          localStorage.setItem("token", responseJson.token);
          this.uploadImage()
        } else if (
          responseJson.message === "Index 0 out of bounds for length 0"
        ) {
          this.setState({ isLoading: false });
          Swal.fire({
            title: "Error!",
            text: "Invalid username or password",
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          this.setState({ isLoading: false, disabled: false });
          Swal.fire({
            title: "Error!",
            text: responseJson.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false, isDisabled: false });
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  render(){
    const { isLoading, isDisabled } = this.state;

    return(
      <div className="container" style={{ alignSelf: 'center'}}>
      {localStorage.getItem("token") &&
      <Sidebar />
      }
      <div>
      {!localStorage.getItem("token") &&
      <div className="text-center">
      <img src={logo} className="navbar-brand-img" alt="main_logo" style={{ width: 81, marginTop: 45}} />
    <h3
      className="nicfostText"
      style={{
        position: "relative",
        fontWeight: "bold",
        top: 45,
        color: "#333",
        fontSize: 25,
        textAlign: "center",
        marginLeft: 85,
      }}
    >
      NIGERIAN COUNCIL OF FOOD SCIENCE AND TECHNOLOGY (NiCFoST)
    </h3>
    </div>
  }
{!localStorage.getItem("token") &&
  <br />
}
{!localStorage.getItem("token") &&
  <br />
}
{!localStorage.getItem("token") &&
  <br />
}

{localStorage.getItem("token") &&
<div className="text-center container-fluid px-4 d-flex justify-content-between" style={{width: '80%', justifyContent: 'space-evenly', alignItems: 'center', position: 'relative', top: 27, left: 90, marginBottom: 27 }}>
<div>
<img src={logo} className="navbar-brand-img" alt="main_logo" style={{ width: 90, position: 'relative', left:45 }} />
</div>
<div>
<h3 className="font-weight-bold text-center" style={{ position: 'relative', left: 45}}>NIGERIAN COUNCIL OF FOOD SCIENCE AND TECHNOLOGY (NiCFoST)</h3>
</div>
<div>
<img src={coat} className="navbar-brand-img h-100" style={{ width: 126, position: 'relative', left: 45 }} alt="main_logo" />
</div>
</div>
}


  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg " style={{width: !localStorage.getItem("token") ? '90%' : '80%', position: localStorage.getItem("token") ? 'relative' : '', left: !localStorage.getItem("token") ? 81: '', padding: 18, float: !localStorage.getItem("token") ? '' : 'right' }}>
    {/*  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg " style={{width: '80%', float: 'right' }}> */}
      <div className="container-fluid px-4 d-flex justify-content-end">
        <div className="rown">
          <div className="col-12">
            <div className="card my-3">
              <div className="card-header pb-0 bg-success">
                <div className="text-center">
                  <h5 className="text-light text-center font-weight-bold mb-4">Application For Premises Inspection</h5>
                </div>
              </div>
              <div class="card-body">

      <div className="container" style={{ marginTop: 0, padding: 85 }}>

        <form className="row">
          <div className="row" style={{padding: 15}}>
          <label
            className="mb-3 h4"
            style={{ color: "green" }}
            htmlFor="floatingInputCustom"
          >
            Orgnainzation Information
          </label>

          <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
            <label
              style={{ color: this.state.companyNameColor}}
              className="form-label"
            >
              Name of Organization / Company <span className="text-danger">*</span>
            </label>
            <div className="input-group input-group-outline mb-3">
              <label className="form-label"></label>
              <input
                className="form-control shadow-none"
                type="text"
                required="required"
                onChange={(e) => this.setState({ companyName: e.target.value })}
              />
            </div>
          </div>

          <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
            <label
              style={{ color: this.state.companyAddressColor}}
              className="form-label"
            >
            Address of Organization / Company <span className="text-danger">*</span>
            </label>
            <div className="input-group input-group-outline mb-3">
              <label className="form-label"></label>
              <input
                className="form-control shadow-none"
                type="text"
                required="required"
                onChange={(e) => this.setState({ companyAddress: e.target.value })}
              />
            </div>
          </div>

          <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
            <label
              style={{ color: this.state.companyLGAColor}}
              className="form-label"
            >
            LGA of Business Location <span className="text-danger">*</span>
            </label>
            <div className="input-group input-group-outline mb-3">
              <label className="form-label"></label>
              <input
                className="form-control shadow-none"
                type="text"
                required="required"
                onChange={(e) => this.setState({ companyLGA: e.target.value })}
              />
            </div>
          </div>

          <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
            <label
              className="form-label"
              style={{ color: this.state.companyStateColor}}
            >
              Select State of Location <span className="text-danger">*</span>
            </label>
            <div className="input-group input-group-outline mb-3">
              <label className="form-label"></label>
              <select
                className="form-control shadow-none"
                aria-label="Select state"
                onChange={this.handleStateChange}
              >
                <option
                  selected
                  disabled
                >
                   -- Select state --
                </option>

                {this.getStates()}
              </select>
            </div>
          </div>

          <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
            <label
              style={{ color: this.state.businessTypeColor}}
              className="form-label"
            >
            Business Type <span className="text-danger">*</span>
            </label>
            <div className="input-group input-group-outline mb-3">
              <label className="form-label"></label>
              <select
                className="form-control shadow-none"
                aria-label="Select"
                onChange={this.handleBusinessTypeChange}
              >
                <option selected disabled>
                  -- Select category --
                </option>
                <option value="MANUFACTURING">MANUFACTURING</option>
                <option value="STORAGE">STORAGE</option>
                <option value="RESTAURANT">RESTAURANT</option>
                <option value="RETAIL">RETAIL</option>
                <option value="WHOLESALE">WHOLESALE</option>
                <option value="FOOD SERVICES">FOOD SERVICES</option>
              </select>
            </div>
          </div>

        {/*  <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
            <label
              style={{ color: this.state.companySizeColor }}
              className="form-label"
            >
              Company size <span className="text-danger">*</span>
            </label>
            <div className="input-group input-group-outline mb-3">
              <label className="form-label"></label>
              <select
                className="form-control shadow-none"
                aria-label="Select"
                onChange={this.handleCompnaySizeChange}
                style={{ color: this.state.colorCompany }}
              >
                <option
                selected >
                -- Select company size --
                </option>
                {this.showCompanySize()}
              </select>
            </div>
          </div> */}

          <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
            <label
            className="form-label"
            style={{ color: this.state.businessDescriptionColor }}>
              Business description <span className="text-danger">*</span>
            </label>
            <div className="input-group input-group-outline mb-3">
              <label className="form-label"></label>

              <textarea
                className="form-control shadow-none"
                type="text"
                rows={3}
                onChange={(e) => this.setState({ businessDescription: e.target.value })}
              ></textarea>
            </div>
          </div>

          <div
          className="col-sm-6 col-lg-6 col-md-6 mb-3">
            <label
            style={{ color: this.state.brandColor }}
            className="form-label">
              List product brands <span className="text-danger">*</span>
            </label>
            <div className="input-group input-group-outline mb-3">
              <label className="form-label"></label>

              <textarea
                className="form-control shadow-none"
                type="text"
                rows={3}
                onChange={(e) => this.setState({ productBrands: e.target.value })}
              ></textarea>
            </div>
          </div>
          <hr />

          <label
            className="mb-3 h4"
            style={{ color: "green" }}
            htmlFor="floatingInputCustom"
          >
            Personal Information
          </label>

          <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
            <label
            style={{ color: this.state.nameColor }}
              className="form-label"
            >
            Contact Name <span className="text-danger">*</span>
            </label>
            <div className="input-group input-group-outline mb-3">
              <label className="form-label"></label>
              <input
                className="form-control shadow-none"
                type="text"
                required="required"
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </div>
          </div>
          <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
            <label
            style={{ color: this.state.emailColor }}
            className="form-label"
            >
            Contact Email <span className="text-danger">*</span>
            </label>
            <div className="input-group input-group-outline mb-3">
              <label className="form-label"></label>
              <input
                className="form-control shadow-none"
                type="email"
                required="required"
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </div>
          </div>

          <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
            <label
            style={{ color: this.state.phoneColor }}
            className="form-label"
            >
            Contact Phone <span className="text-danger">*</span>
            </label>
            <div className="input-group input-group-outline mb-3">
              <label className="form-label"></label>
              <input
                className="form-control shadow-none"
                type="phone"
                required="required"
                onChange={(e) => this.setState({ phone: e.target.value })}
              />
            </div>
          </div>

          <hr />
          <br />

           {!localStorage.getItem('userid') &&
          <label
            className="h4"
            htmlFor="floatingInputCustom"
            style={{ color: "#145973" }}
          >
            Create Login Details
          </label>
            }

            {!localStorage.getItem('userid') &&
            <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
              <label
              style={{ color: this.state.phoneColor }}
              className="form-label"
              >
              Contact Phone <span className="text-danger">*</span>
              </label>
              <div className="input-group input-group-outline mb-3">
                <label className="form-label"></label>
                <input
                  className="form-control shadow-none"
                  type="phone"
                  required="required"
                  onChange={(e) => this.setState({ phone: e.target.value })}
                />
              </div>
            </div>
           }


           {!localStorage.getItem('userid') &&
           <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
             <label
               style={{ color: this.state.passwordColor }}
               className="form-label"
             >
               Pasword <span className="text-danger">*</span>
             </label>
             <div className="input-group input-group-outline mb-3">
               <label className="form-label"></label>
               <input
                 className="form-control shadow-none"
                 type="password"
                 required="required"
                 onChange={(e) => this.setState({ password: e.target.value })}
               />
             </div>
           </div>
            }

           {!localStorage.getItem('userid') &&
           <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
             <label
               style={{ color: this.state.confirmPasswordColor }}
               className="form-label"
             >
               Confirm Pasword <span className="text-danger">*</span>
             </label>
             <div className="input-group input-group-outline mb-3">
               <label className="form-label"></label>
               <input
                 className="form-control shadow-none"
                 type="password"
                 required="required"
                 onChange={(e) =>
                   this.setState({ confirmPassword: e.target.value })
                 }
               />
             </div>
           </div>
            }

            {!localStorage.getItem('userid') &&
            <div className="col-sm-6 col-lg-4 col-md-4 mb-3">
              <label
              style={{ color: this.state.passportImageColor }}
                htmlFor="floatingInputCustom"
              >
                Upload Your Qualification Image
              </label> <span className="text-danger">*</span>

              <div className="col-sm-6 col-lg-12 col-md-6 mb-3">
                <input
                  className="form-control shadow-none"
                  type="file"
                  required="required"
                  onChange={ this.handlePassportImage }
                />
              </div>
              </div>
            }
          </div>


          <div className="row" style={{ marginTop: 25 }}>
            <Form.Floating className="mb-3">
              <input
                class="form-check-input shadow-none"
                type="checkbox"
                id="checkBoxUS"
                name="US"
                value="US"
                onChange={this.handleCheckChange}
              />{" "}
              I hereby certify that the information given above is true
            </Form.Floating>
          </div>

          <div
          className="text-center"
            style={{
              margin: "auto",
              width: "100%",
              marginTop: 45,
              marginBottom: "15vh",
            }}
          >
            <button
              disabled={isDisabled}
              style={{
                alignSelf: "center",
                width: "60%",
                backgroundColor: "#003314",
              }}
              type="button"
              className="btn btn-success btn-lg"
              onClick={() => this.checkValidation()}
            >
              {isLoading ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                <span className="font-weight-bold">
                  {/* APPLY <i class="fas fa-chevron-right"></i> */}
                  SUBMIT APPLICATION
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      <Footer />
      </main>
      </div>
      </div>
    )
  }
}

export default Inspection;
