import React, { Component } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner, Card, FloatingLabel, Form } from "react-bootstrap";
import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { countries } from "../components/Countries";
 
import axios from "axios";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      passwordFocus: false,
      loading: false,
      disabled: false,
      registerPhone: "",
      registerEmail: "",
      registerPassword: "",
      registerConfirmPassword: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  getCountry() {
    return countries.map((country) => {
      return <option value={country.dial_code}>{country.name}</option>;
    });
  }

  handleChange(e) {
    this.setState({ selectValue: e.target.value });
  }

  togglePassword = (e) => {
    this.setState({ showPassword: e.target.checked });
  };

  handleFocus = (e) => {
    this.setState({ passwordFocus: true });
  };

  handleBlur = () => {
    this.setState({ passwordFocus: false });
  };

  register = async () => {
    const {
      registerConfirmPassword,
      registerEmail,
      registerPassword,
      registerPhone,
    } = this.state;

    const self = this;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{6,}$/;

    // Validate phone number
    if (!/^\d{11}$/.test(registerPhone)) {
      Swal.fire({
        title: "Invalid Phone Number",
        text: "Please enter a valid 11-digit phone number",
        icon: "error",
        confirmButtonText: "OK",
      });
      // return;
    }

    // Validate password
    else if (!passwordRegex.test(registerPassword)) {
      Swal.fire({
        title: "Invalid Password",
        text: "Password must contain at least 1 number, 1 uppercase letter, 1 lowercase letter, and 1 special character.",
        icon: "error",
        confirmButtonText: "OK",
      });
      // return;
    }

    // validate email address
    else if (!emailRegex.test(registerEmail)) {
      Swal.fire({
        title: "Invalid Email",
        text: "Please enter a valid email address",
        icon: "error",
        confirmButtonText: "OK",
      });
      // return;
    }

    // validate password is the same as confirm password
    else if (registerPassword !== registerConfirmPassword) {
      Swal.fire({
        title: "Passwords Do Not Match",
        text: "The entered passwords do not match. Please make sure to enter the same password in both fields.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      this.setState({ loading: true, disabled: true });

      const payload = {
        password: registerPassword,
        phone: registerPhone,
        email: registerEmail,
        confirmPassword: registerConfirmPassword,
      };

      const url = `${process.env.REACT_APP_BASE_URL}/v2/registration/register/create-account-information`;
      await axios
        .post(url, payload)
        .then((response) => {
          const { status, data } = response;

          if ((data && status === 200) || (data && status === 201)) {
            sessionStorage.setItem(
              "registration:ongoing",
              JSON.stringify(data)
            );
            sessionStorage.setItem(
              "registration:userId",
              JSON.stringify(data.userId)
            );
          }

          // registration is incomplete: i.e. user is
          // the yet to complete registration process
          if (data && status === 201) {
            Swal.fire({
              html: "Your registration in still in progress. Please, proceed to complete your registration",
              icon: "info",
              timerProgressBar: false,
              showConfirmButton: true,
              showCancelButton: false,
              showCloseButton: false,
              cancelButtonColor: "#ff7b7b",
              confirmButtonText: "Complete Registration",
              allowOutsideClick: false,
            }).then(() => {
              self.props.history.push("/register-food-scientist-v3");
            });
          }
          // registration is new: i.e. not existing record in the system
          else if (data && status === 200)
            self.props.history.push("/register-food-scientist-v3");
        })
        .catch((error) => {
          this.setState({ loading: false, disabled: false });

          if (error?.response?.status === 400) {
            Swal.fire({
              html: error?.response?.data?.message || "Error registering",
              icon: "error",
              timerProgressBar: true,
              showConfirmButton: false,
              showCancelButton: true,
              showCloseButton: true,
              cancelButtonColor: "#ff7b7b",
            });
          } else {
            Swal.fire({
              html:
                error?.response?.data?.message ||
                "Encountered an error while attempting to register.",
              icon: "error",
              timerProgressBar: true,
              showConfirmButton: false,
              showCancelButton: true,
              showCloseButton: true,
              cancelButtonColor: "#ff7b7b",
            });
          }
        });
    }
  };

  render() {
    const { loading, passwordFocus, showPassword, disabled } = this.state;

    return (
      <div className="loginContainer">
        <div
          className="d-flex justify-content-center"
          style={{
            width: "100%",
            top: 0,
            bottom: 0,
          }}
        >
          <Card
            style={{ backgroundColor: "rgba(255,255,255,0.7)" }}
            className="d-flex justify-content-center cardContainer loginContainer-content"
          >
            <img
              src={logo}
              alt=""
              style={{
                width: "15%",
                position: "absolute",
                top: 18,
                alignSelf: "center",
              }}
            />
            <h5
              className="nicfostText"
              style={{
                position: "relative",
                fontWeight: "bold",
                top: 25,
                color: "#333",
                textAlign: "center",
              }}
            >
              NIGERIAN COUNCIL OF FOOD SCIENCE AND TECHNOLOGY
              <br />
              (NiCFoST)
            </h5>
            <div className="mb-3"></div>
            <a
              href="https://nicfost.gov.ng/registration-instructions/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", alignSelf: "center" }}
            >
              <h6
                style={{
                  textTransform: "capitalize",
                  textDecoration: "underline",
                  marginTop: 10,
                  color: "red",
                  fontSize: 11,
                }}
              >
                Read registration instructions here.
              </h6>
            </a>

            {/* ... registration form fields */}
            <div className="container" style={{ marginTop: 20 }}>
              <h6 className="nicfostText">Sign Up </h6>

              <Form>
                <Form.Floating className="mb-3">
                  <input
                    className="form-control shadow-none"
                    type="email"
                    onChange={(e) =>
                      this.setState({ registerEmail: e.target.value })
                    }
                  />
                  <label htmlFor="email">Email Address</label>
                </Form.Floating>
                <Form.Floating className="mb-3">
                  <input
                    className="form-control shadow-none"
                    type="tel"
                    onChange={(e) =>
                      this.setState({ registerPhone: e.target.value })
                    }
                  />
                  <label htmlFor="email">Phone</label>
                </Form.Floating>
                <Form.Floating>
                  <input
                    className="form-control shadow-none"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    onChange={(e) =>
                      this.setState({ registerPassword: e.target.value })
                    }
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                  />
                  <label htmlFor="floatingPasswordCustom">Password</label>
                </Form.Floating>
                {passwordFocus && (
                  <small className="text-danger">
                    Password must contain at least 1 number, 1 uppercase letter,
                    1 lowercase letter, and 1 special character (!@#$%^*).{" "}
                  </small>
                )}
                <Form.Floating>
                  <input
                    className="form-control mt-3 shadow-none"
                    type={showPassword ? "text" : "password"}
                    id="confirmPassword"
                    onChange={(e) =>
                      this.setState({
                        registerConfirmPassword: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="floatingPasswordCustom">
                    Confirm Password
                  </label>
                </Form.Floating>
                <br />
                <Form.Floating>
                  <input
                    className="shadow-none"
                    type="checkbox"
                    onChange={this.togglePassword}
                  />
                  <span>
                    {showPassword ? "Hide password" : "Show password"}
                  </span>
                </Form.Floating>

                <div className="d-grid gap-2 mb-3" style={{ marginTop: 30 }}>
                  <button
                    style={{ backgroundColor: "#003314" }}
                    className="btn btn-success btn-lg"
                    onClick={(e) => this.register()}
                    disabled={disabled}
                  >
                    {loading ? (
                      <Spinner animation="border" variant="light" size="sm" />
                    ) : (
                      "Register"
                    )}
                  </button>
                </div>
              </Form>

              <div style={{ position: "relative", top: 5 }}>
                <h5 className="text-center text-dark mobile-text">
                  Already have an account?{" "}
                  <Link
                    to="/login"
                    style={{ textDecoration: "underline", color: "green" }}
                  >
                    sign in
                  </Link>
                </h5>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

export default Register;
