import React, { Component } from "react";
import { Spinner, Form, Alert } from "react-bootstrap";

import { states } from "../assets/data/json-data";
import { countries } from "../components/Countries";
import Swal from "sweetalert2";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import logo from "../assets/images/logo.png";
import coat from "../assets/images/coat.png";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import axios from "axios";
import { makePayment } from "../Helper/paymentHelper";


const baseUrl = process.env.REACT_APP_BASE_URL


class RegisterLicence extends Component {
  constructor(props) {
    var today = new Date(),
        date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();
    super(props);
    this.state = {
      startDate: "",
      contactPhone: "",
      phone: "",
      contactEmail: "",
      contactAddress: "",
      payment_description: "",
      amount: "",
      payment_category: "",
      payment_sub_category: "",
      qualifications: [
        {
          qualification: "",
          year: "",
          certificateImage: "",
        },
      ],
      additionQualification: {
        qualification: "",
        year: "",
        certificateImage: "",
      },
      payment_id: "",
      remitaid: "",
      currentDate: date,
      // fee: "",
      title: "",
      gender: "",
      surname: "",
      prevSurname: "",
      otherNames: "",
      practiceCategory: "",
      dob: "",
      position: "",
      mailingAddres: "",
      disabled: false,
      nationality: "",
      colorPassport: "black",
      state: "",
      lga: "",
      expDate: "",
      boxAll: false,
      employed: false,
      address: "",
      password: "",
      confirmPassword: "",
      sponsorName: "",
      sponsorName2: "",
      sponsorNo2: "",
      curLicense: "",
      sponsorNo: "",
      organizationName: "",
      organizationPosition: "",
      organizationEmail: "",
      organizationTelephone: "",
      organizationAddress: "",
      qualification: "",
      qualification2: "",
      qualificationYear2: "",
      qualificationYear: "",
      prevLicense: "",
      prevLicenseDate: "",
      practiceCat: "",
      regType: "",
      city: "",
      email: "",
      nifst: "",
      // feeType: [],
      countryCode: "",
      colorSurname: "black",
      colorTitle: "black",
      colorEmail: "black",
      colorOthername: "black",
      colorNationality: "black",
      colorLga: "black",
      colorPhone: "black",
      colorPassword: "black",
      colorPasswordConf: "black",
      colorSponsor: "black",
      colorSponsorNo: "black",
      colorOrgName: "black",
      colorOrgEmail: "black",
      colorOrgPhone: "black",
      colorAddress: "black",
      colorOrgAddress: "black",
      colorPayment: "black",
      colorPosition: "black",
      colorState: "black",
      colorGender: "black",
      colorDob: "black",
      colorNIFST: "black",
      colorQualYear: "black",
      colorSecondQual: "black",
      colorSecondQaulYear: "black",
      colorRemita: "black",
      colorQual: "black",
      colorMailAddress: "black",
      colorCountryCode: "black",
      colorPraCat: "black",
      colorPrevLic: "black",
      colorCurLic: "black",
      colorCurLicDate: "black",
      colorPrevLicDate: "black",
      colorRegType: "black",
      loading: false,
      isUserId: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleGenderChange = this.handleGenderChange.bind(this);
    // this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleQualificationChange = this.handleQualificationChange.bind(this);
    this.handleQualificationChange2 =
        this.handleQualificationChange2.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.handlePracticeChange = this.handlePracticeChange.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.handleCompnaySizeChange = this.onChangeValue.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    // this.handleFeeChange = this.handleFeeChange.bind(this);
    this.handleFiveYearsMembership = this.handleFiveYearsMembership.bind(this);
    this.handleSixYearsMembership = this.handleSixYearsMembership.bind(this);
    this.handleTwentyOneYearsMembership =
        this.handleTwentyOneYearsMembership.bind(this);
    this.handleElevenYearsMembership =
        this.handleElevenYearsMembership.bind(this);
    this.handleTwentyOneYearsMembership =
        this.handleTwentyOneYearsMembership.bind(this);
    this.handleAboveTwentyFiveYears =
        this.handleAboveTwentyFiveYears.bind(this);
    this.handleAboveSixtyYearsMembership =
        this.handleAboveSixtyYearsMembership.bind(this);
    this.handleEmployedCheck = this.handleEmployedCheck.bind(this);
  }


  componentDidMount() {
    this.getlicenseInfo();
  }



  handleChange(e) {
    this.setState({ title: e.target.value });
  }
  handlePracticeChange(e) {
    this.setState({ practiceCategory: e.target.value, colorPraCat: "black" });
  }
  handleStateChange(e) {
    const stateVal = e.target.value;
    this.setState({ state: stateVal, colorState: "black" });
  }

  handleGenderChange(e) {
    this.setState({ gender: e.target.value, colorGender: "black" });
  }

  handleFiveYearsMembership(e) {
    this.setState({ fiveYearsMembership: e.target.value });
  }
  handleSixYearsMembership(e) {
    this.setState({ sixYearsMembership: e.target.value });
  }
  handleElevenYearsMembership(e) {
    this.setState({ elevenYearsMembership: e.target.value });
  }

  handleTwentyOneYearsMembership(e) {
    this.setState({ twentyOneYearsMembership: e.target.value });
  }

  handleAboveTwentyFiveYears(e) {
    this.setState({ twentyFiveYearsMembership: e.target.value });
  }

  handleAboveSixtyYearsMembership(e) {
    this.setState({ sixtyYearsMembership: e.target.value });
  }

  handleQualificationChange(e) {
    this.setState({
      qualifications: [{ qualification: e.target.value, colorQual: "black" }],
    });
  }

  handleQualificationChange2(e) {
    this.setState({ qualification: e.target.value });
  }

  handleCheckChange(e) {
    this.setState({ boxAll: e.target.checked }, () => { });
  }

  handleEmployedCheck(e) {
    console.log(e.target.checked, "===================================  ");
    this.setState({ employed: e.target.checked });
  }

  handleCompnaySizeChange(e) {
    this.setState({ companySize: e.target.value });
  }

  onChangeValue(event) {
    this.setState({ regType: event.target.value });
  }

  handleDateChange(date, e) {
    const dob = date.target.value;
    this.setState({
      dob: dob,
      colorDob: "black",
    });
  }

  // handleFeeChange(e) {
  //   this.setState({ fee: e.target.value });
  // }

  getStates() {
    const state = states.map((state) => state.name);
    return state?.map((each, i) => (
        <option key={i} value={each}>
          {each}
        </option>
    ));
  }

  getLgas() {
    const stateVal = this?.state?.state;
    if (stateVal) {
      const choosenState = states.find((state) => state.name === stateVal);
      return choosenState?.locals?.map(({ name, i }) => (
          <option value={name} key={i}>
            {name}
          </option>
      ));
    }
  }

  getCountry() {
    return countries.map((country) => {
      return <option value={country.dial_code}>{country.name}</option>;
    });
  }

  generateRRR(licenseId, retries = 3) {
    if (retries === 0) {
      Swal.fire({
        title: "Error",
        text: "Unable to process payments at the moment. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        this.props.history.push("/dashboard");
      });
      return;
    }

    axios.post(`${baseUrl}/v2/license/license/${licenseId}/generate-rrr`)
        .then(response => {
          // handle successful response
          localStorage.setItem("rrr", response.data.rrr);
          localStorage.setItem("license_id", response.data.licenseId);
          makePayment();
        })
        .catch(error => {
          // decrement retries count and call the function again
          this.generateRRR(licenseId, retries - 1);
        });
  }

  getlicenseInfo() {
    const self = this;
    const userid = localStorage.getItem("userid");
    axios
        .get(`${baseUrl}/v2/license/license-information/${userid}`)
        .then(async (response) => {
          this.setState((prevState) => ({
            ...prevState,
            ...response.data.personalInformation,
            ...response.data.contactInformation,
            qualifications: [...response.data.qualifications],
            ...response.data.workInformation,
            ...response.data.practiceCategory,
          }));
        })
        .catch((err) => {
          const response = err.response;
          this.setState({ loading: false, disabled: false });
          switch (response?.status) {
            case 400:
              Swal.fire({
                title: "Error!",
                text: response.data.message,
                icon: "error",
                confirmButtonText: "OK",
              }).then(() => {
                self.props.history.push("/dashboard");
              });
              break;
            default:
              Swal.fire({
                title: "Error!",
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
              });
              break;
          }
        });
  }


  showAlert = () => {
    return (
        <div
            className="alert"
            style={{ position: "relative", top: '-18px', width: "100%", marginLeft: 0 }}
        >
          <Alert
              className="text-light font-weight-bold"
              variant="warning"
              onClose={() => this.toggleInfo()}
              dismissible
          >
            <Alert.Heading className="text-light">
              Important Information on updating your Registration Information
              Earlier Supplied!
            </Alert.Heading>
            <p style={{ fontWeight: "bold" }}>
              EVERY REGISTERED FOOD SCIENTIST SHOULD SEND IMMEDIATE NOTIFICATION
              OF ANY CHANGE OF ADDRESS/DATA SUPPLIED TO THE REGISTRAR OF THE
              COUNCIL.
            </p>
          </Alert>
        </div>
    );
  };


  checkValidation = async (e) => {
    e.preventDefault();

    const {
      title,
      gender,
      surname,
      othername,
      contactPhone,
      contactAddress,
      contactEmail,
      nationality,
      state,
      dob,
      boxAll,
      employed,
      lga,
      organizationName,
      organizationPosition,
      organizationEmail,
      organizationTelephone,
      organizationAddress,
      practiceCategory
    } = this.state;



    if (surname === "" || surname === null) {
      Swal.fire({
        title: "Empty",
        text: "Please enter your surname",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorSurname: "red" });
    } else if (othername === "" || othername === null) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your other name",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorOthername: "red" });
    } else if (title === "" || title === null) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your title",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorTitle: "red" });
    } else if (dob === "" || dob === null) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your Date of birth",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorDob: "red" });
    } else if (nationality === "" || nationality === null) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your nationality",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({
        loading: false,
        disabled: false,
        colorNationality: "red",
      });
    } else if (gender === "" || gender === null) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your gender",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorGender: "red" });
    } else if (state === "" || lga === null) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your State of Origin",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorState: "red" });
    } else if (lga === "" || lga === null) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your Local Government Area",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorLga: "red" });
    } else if (contactPhone === "" || contactPhone === null) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your Phone Number",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorPhone: "red" });
    } else if (contactEmail === "" || contactEmail === null) {
      Swal.fire({
        title: "Empty",
        text: "Please verify your email",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorEmail: "red" });
    } else if (contactAddress === "" || contactAddress === null) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your Contact Address",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({
        loading: false,
        disabled: false,
        colorAddress: "red",
      });
    } else if (employed && (organizationName === "" || organizationName === null)) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your Organization Name",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorOrgName: "red" });
    } else if (employed && (organizationPosition === "" || organizationPosition === null)) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your position in your Organization",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorPosition: "red" });
    } else if (employed && (organizationAddress === "" || organizationAddress === null)) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your Organization address",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorOrgAddress: "red" });
    } else if (employed && (organizationTelephone === "" || organizationTelephone === null)) {
      Swal.fire({
        title: "Empty",
        text: "Please specify phone number of your Organization",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorOrgPhone: "red" });
    } else if (employed && (organizationEmail === "" || organizationEmail === null)) {
      Swal.fire({
        title: "Empty",
        text: "Please specify email of your Organization",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorOrgEmail: "red" });
    } else if (practiceCategory === "" || practiceCategory === null) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your practice category",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorPraCat: "red" });
    } else if (boxAll === false) {
      Swal.fire({
        title: "Accept The Terms",
        text: "Please accept the terms and conditions",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false });
    } else {

      this.setState({ loading: true, disabled: true });
      this.registerLicence();
    }
  };


  registerLicence = () => {
    this.setState({ loading: true, disabled: true });

    let date = new Date();
    var obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        dob: this.state.dob,
        applicationdate: date.getTime(),
        contactAddress: this.state.contactAddress,
        contactEmail: this.state.contactEmail,
        contactTelephone: this.state.contactPhone,
        formType: "registration",
        gender: this.state.gender,
        lga: this.state.lga,
        nationality: this.state.nationality,
        organization: this.state.organizationName,
        organizationAddress: this.state.organizationAddress,
        organizationEmail: this.state.organizationEmail,
        organizationPosition: this.state.organizationPosition,
        organizationTelephone: this.state.organizationTelephone,
        otherNames: this.state.otherNames,
        practiceCategory: this.state.practiceCategory,
        previoussurname: this.state.prevSurname,
        registrationnumber: this.state.nifst,
        state: this.state.state,
        surname: this.state.surname,
        title: this.state.title,
        userId: localStorage.getItem("userid"),
      }),
    };


    fetch(`${baseUrl}/v2/license/create-license`, obj)
        .then(async (response) => {
          if (response.status === 200) {
            const responseJson = await response.json();
            if (responseJson.rrrGenerated) {
              localStorage.setItem("rrr", responseJson.rrr);

              localStorage.setItem("license_id", responseJson.licenseId)
              makePayment()
              this.props.history.push("/dashboard")
            } else {
              this.generateRRR(responseJson.licenseId);
              this.setState({ loading: false, disabled: false });

            }

          }

          if (response.status === 400) {
            const responseJson = await response.json();
            Swal.fire({
              title: "Error",
              text: responseJson?.violations[0]?.message || responseJson?.message,
              icon: "error",
              confirmButtonText: "OK",
            }).then(() => {
              this.props.history.push("/dashboard")
            });
          }

          if (response.status === 500) {
            Swal.fire({
              title: "Error",
              text: "Unable to apply for a license at the moment. Please try again later.",
              icon: "error",
              confirmButtonText: "OK",
            });
          }

          this.setState({ loading: false, disabled: false });
        })
        .catch((error) => {
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        });

  };


  convertToBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({
        additionQualification: {
          ...this.state.additionQualification,
          certificateImage: reader.result,
        },
      });
    };
    reader.onerror = (error) => {
      console.error("Error converting file to base64:", error);
    };
  };


  render() {
    const { loading, disabled, employed } = this.state;
    console.log(employed, "rendering emplyed vlaue...");
    return (
        <div className="g-sidenav-show">
          {localStorage.getItem("token") && <Sidebar />}
          <div
              className="text-center container-fluid px-4 d-flex justify-content-between brand-container"
              style={{
                width: "70%",
                justifyContent: "space-evenly",
                alignItems: "center",
                position: "relative",
                top: 0,
                right: -105,
              }}
          >
            <div>
              <img
                  src={logo}
                  className="navbar-brand-img"
                  alt="main_logo"
                  style={{ width: 81 }}
              />
            </div>
            <div className="text-center" style={{ position: "", left: 45 }}>
              <h4 className="font-weight-bold text-center brand-header">
                NIGERIAN COUNCIL OF FOOD SCIENCE AND TECHNOLOGY (NiCFoST)
              </h4>
            </div>
            <div>
              <img
                  src={coat}
                  className="navbar-brand-img brand-img-2 h-100"
                  style={{ width: 126, position: "relative", left: 90 }}
                  alt="main_logo"
              />
            </div>
          </div>
          <main
              class="main-content position-relative max-height-vh-100 h-100 border-radius-lg "
              style={{
                width: !localStorage.getItem("token") ? "90%" : "80%",
                position: localStorage.getItem("token") ? "relative" : "",
                left: !localStorage.getItem("token") ? 81 : "",
                padding: "0px 18px",
                float: !localStorage.getItem("token") ? "" : "right",
              }}
          >
            <div className="container-fluid px-4" id="register-form-container">
              <div className="rown">
                <div className="col-12">
                  <div className="card my-3">
                    <div className="card-header pb-0 bg-success">
                      <div className="d-flex flex-wrap justify-content-center mb-2">
                        <h5 className="text-light text-center font-weight-bold">
                          APPLICATION FORM FOR LICENCE TO PRACTICE AS A
                          CERTIFIED FOOD SCIENTIST
                        </h5>
                      </div>
                    </div>
                    <div class="card-body reg-body">
                      <h3
                          className="nicfostText"
                          style={{
                            position: "relative",
                            fontWeight: "bold",
                            top: 0,
                            fontSize: 25,
                            marginLeft: 85,
                            textAlign: "center",
                          }}
                      >
                        NIGERIAN COUNCIL OF FOOD SCIENCE AND TECHNOLOGY (NiCFoST)
                      </h3>
                      {/* {this.showAlert()} */}

                      <h5
                          style={{
                            position: "relative",
                            fontWeight: "bold",
                            top: 65,
                            fontSize: 20,
                            marginLeft: 85,
                            textAlign: "center",
                          }}
                      ></h5>
                      <div
                          className="container"
                          style={{ marginTop: 0, padding: 18 }}
                      >
                        <form className="row">
                          <label
                              className="mb-3 h4"
                              style={{ color: "green" }}
                              htmlFor="floatingInputCustom"
                          >
                            Personal Information
                          </label>
                          <br />
                          <br />
                          <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                            <label
                                style={{ color: this.state.colorSurname }}
                                className="form-label"
                            >
                              Surname <span className="text-danger">*</span>
                            </label>
                            <div className="input-group input-group-outline mb-3">
                              <label className="form-label"></label>
                              <input
                                  className="form-control shadow-none"
                                  type="text"
                                  value={this?.state?.surname}
                                  onChange={(e) =>
                                      this.setState({
                                        surname: e.target.value,
                                        colorSurname: "black",
                                      })
                                  }
                              />
                            </div>
                          </div>

                          <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                            <label className="form-label text-dark">
                              Previous Surname (If applicable)
                            </label>
                            <div className="input-group input-group-outline mb-3">
                              <label className="form-label"></label>
                              <input
                                  className="form-control shadow-none"
                                  type="text"
                                  value={this?.state?.prevSurname}
                                  onChange={(e) =>
                                      this.setState({
                                        prevSurname: e.target.value,
                                        colorprevState: "black",
                                      })
                                  }
                              />
                            </div>
                          </div>

                          <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                            <label
                                style={{ color: this.state.colorOthername }}
                                className="form-label"
                            >
                              Other name(s) <span className="text-danger">*</span>
                            </label>
                            <div className="input-group input-group-outline mb-3">
                              <label className="form-label"></label>
                              <input
                                  className="form-control shadow-none"
                                  type="text"
                                  value={this?.state?.otherNames}
                                  onChange={(e) =>
                                      this.setState({
                                        otherNames: e.target.value,
                                        colorOthername: "black",
                                      })
                                  }
                              />
                            </div>
                          </div>

                          <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                            <label
                                style={{ color: this.state.colorTitle }}
                                className="form-label"
                            >
                              Title <span className="text-danger">*</span>
                            </label>
                            <div className="input-group input-group-outline mb-3">
                              <label className="form-label"></label>
                              <select
                                  className="form-control"
                                  aria-label="Select title"
                                  onChange={this.handleChange}
                                  value={this?.state?.title && this?.state?.title}
                                  style={{ color: this.state.colorTitle }}
                              >
                                <option selected disabled>
                                  -- Select title --
                                </option>
                                <option value="mr">Mr.</option>
                                <option value="mrs">Mrs.</option>
                                <option value="ms">Ms.</option>
                                <option value="dr">Dr.</option>
                                <option value="prof">Prof.</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                            <label
                                style={{ color: this.state.colorDob }}
                                className="form-label"
                            >
                              DOB <span className="text-danger">*</span>
                            </label>
                            {/* <div className="input-group input-group-outline mb-3">
                  <label className="form-label"></label>
                    <DatePicker
                    selected={ this.state.dob }
                    calendarAriaLabel="Select date of birth"
                    className="input-group form-control shadow-none mr-1 mb-3"
                    value={this.state.startDate}
                    onChange={ this.handleDateChange }
                    name="startDate"
                    dateFormat="MM/dd/yyyy" />
                </div> */}
                            <Form.Control
                                name="startDate"
                                value={moment(this?.state?.dob).format(
                                    "yyyy-MM-DD"
                                )}
                                placeholder="Enter start date"
                                onChange={this.handleDateChange}
                                // isInvalid={!!errors.dob}
                                type="date"
                            />
                          </div>

                          <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                            <label
                                className="form-label"
                                style={{ color: this.state.colorNationality }}
                            >
                              Nationality <span className="text-danger">*</span>
                            </label>
                            <div className="input-group input-group-outline mb-3">
                              <label className="form-label"></label>
                              <input
                                  type="text"
                                  className="form-control shadow-none"
                                  value={
                                      this?.state?.nationality &&
                                      this?.state?.nationality
                                  }
                                  onChange={(e) =>
                                      this.setState({
                                        nationality: e.target.value,
                                        colorNationality: "black",
                                      })
                                  }
                              />
                            </div>
                          </div>

                          <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                            <label
                                className="form-label"
                                style={{ color: this.state.colorGender }}
                            >
                              Select Gender <span className="text-danger">*</span>
                            </label>
                            <div className="input-group input-group-outline mb-3">
                              <label className="form-label"></label>
                              <select
                                  className="form-control shadow-none"
                                  aria-label="Select gender"
                                  value={
                                    this?.state?.gender ? this?.state?.gender : null
                                  }
                                  onChange={this.handleGenderChange}
                                  style={{ color: this.state.colorGender }}
                              >
                                <option disabled selected>
                                  -- Select gender --
                                </option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                            <label
                                style={{ color: this.state.colorState }}
                                className="form-label"
                            >
                              Select State <span className="text-danger">*</span>
                            </label>
                            <div className="input-group input-group-outline mb-3">
                              <label className="form-label"></label>
                              <select
                                  className="form-control shadow-none"
                                  aria-label="Select state"
                                  value={
                                    this?.state?.state ? this?.state?.state : null
                                  }
                                  onChange={this.handleStateChange}
                                  style={{ color: this.state.colorState }}
                              >
                                <option disabled selected>
                                  -- Select state --
                                </option>

                                {this.getStates()}
                              </select>
                            </div>
                          </div>

                          {this?.state?.state && (
                              <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                <label
                                    style={{ color: this.state.colorLga }}
                                    className="form-label"
                                >
                                  LGA <span className="text-danger">*</span>
                                </label>
                                <div className="input-group input-group-outline mb-3">
                                  <label className="form-label"></label>
                                  <select
                                      className="form-control shadow-none"
                                      aria-label="Select state"
                                      value={
                                        this?.state?.lga ? this?.state?.lga : null
                                      }
                                      onChange={(e) =>
                                          this.setState({
                                            lga: e.target.value,
                                            colorLga: "black",
                                          })
                                      }
                                      style={{ color: this.state.colorLga }}
                                  >
                                    <option disabled selected>
                                      -- Select lga --
                                    </option>
                                    {this.getLgas()}
                                    {/* {this.state.getLgas.length > 0 && this.state?.getLgas.map(({name, i}) => (<option value={name} key={i}>{name}</option>) )} */}
                                  </select>
                                </div>
                              </div>
                          )}


                          <hr />

                          <div className="row" style={{ padding: 15 }}>
                            <label
                                className="mb-3 h4"
                                style={{ color: "green" }}
                                htmlFor="floatingInputCustom"
                            >
                              Contact Information
                            </label>

                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                              <label
                                  style={{ color: this.state.colorPhone }}
                                  className="form-label"
                              >
                                Phone <span className="text-danger">*</span>
                              </label>
                              <div className="input-group input-group-outline mb-3">
                                <label className="form-label"></label>
                                <input
                                    className="form-control shadow-none"
                                    type="number"
                                    value={this?.state?.contactPhone}
                                    onChange={(e) =>
                                        this.setState({
                                          contactPhone: e.target.value,
                                          colorPhone: "black",
                                        })
                                    }
                                />
                              </div>
                            </div>

                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                              <label
                                  style={{ color: this.state.colorEmail }}
                                  className="form-label"
                              >
                                Email <span className="text-danger">*</span>
                              </label>
                              <div className="input-group input-group-outline mb-3">
                                <label className="form-label"></label>
                                <input
                                    className="form-control shadow-none"
                                    type="email"
                                    required="required"
                                    value={this?.state?.contactEmail}
                                    onChange={(e) =>
                                        this.setState({
                                          contactEmail: e.target.value,
                                          colorEmail: "black",
                                        })
                                    }
                                />
                              </div>
                            </div>

                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                              <label
                                  style={{ color: this.state.colorAddress }}
                                  className="form-label"
                              >
                                Contact Address{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group input-group-outline mb-3">
                                <label className="form-label"></label>

                                <textarea
                                    className="form-control shadow-none"
                                    type="text"
                                    required="required"
                                    value={this?.state?.contactAddress}
                                    onChange={(e) =>
                                        this.setState({
                                          contactAddress: e.target.value,
                                          colorAddress: "black",
                                        })
                                    }
                                ></textarea>
                              </div>
                            </div>
                          </div>

                          <hr />
                          <label
                              className="mb-3 h4"
                              style={{ color: "green" }}
                              htmlFor="floatingInputCustom"
                          >
                            {`Place of work   `} <small className="text-danger">(Fill if currently employed)</small>
                          </label>

                          {employed && (<> <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                            <label
                                style={{ color: this.state.colorOrgName }}
                                className="form-label"
                            >
                              Name of Organization/Institution{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="input-group input-group-outline mb-3">
                              <label className="form-label"></label>
                              <input
                                  className="form-control shadow-none"
                                  type="text"
                                  required="required"
                                  value={this?.state?.organizationName}
                                  onChange={(e) =>
                                      this.setState({
                                        organizationName: e.target.value,
                                        colorOrgName: "black",
                                      })
                                  }
                              />
                            </div>
                          </div>

                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                              <label
                                  style={{ color: this.state.colorPosition }}
                                  className="form-label"
                              >
                                Position <span className="text-danger">*</span>
                              </label>
                              <div className="input-group input-group-outline mb-3">
                                <label className="form-label"></label>
                                <input
                                    className="form-control shadow-none"
                                    type="text"
                                    required="required"
                                    value={this?.state?.organizationPosition}
                                    onChange={(e) =>
                                        this.setState({
                                          organizationPosition: e.target.value,
                                          colorPosition: "black",
                                        })
                                    }
                                />
                              </div>
                            </div>

                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                              <label
                                  style={{ color: this.state.colorOrgAddress }}
                                  className="form-label"
                              >
                                Address <span className="text-danger">*</span>
                              </label>
                              <div className="input-group input-group-outline mb-3">
                                <label className="form-label"></label>
                                <input
                                    className="form-control shadow-none"
                                    type="text"
                                    required="required"
                                    value={this?.state?.organizationAddress}
                                    onChange={(e) =>
                                        this.setState({
                                          organizationAddress: e.target.value,
                                          colorOrgAddress: "black",
                                        })
                                    }
                                />
                              </div>
                            </div>

                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                              <label
                                  style={{ color: this.state.colorOrgPhone }}
                                  className="form-label"
                              >
                                Organization Telephone{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group input-group-outline mb-3">
                                <label className="form-label"></label>
                                <input
                                    className="form-control shadow-none"
                                    type="number"
                                    required="required"
                                    value={this?.state?.organizationTelephone}
                                    onChange={(e) =>
                                        this.setState({
                                          organizationTelephone: e.target.value,
                                          colorOrgPhone: "black",
                                        })
                                    }
                                />
                              </div>
                            </div>

                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                              <label
                                  style={{ color: this.state.colorOrgEmail }}
                                  className="form-label"
                              >
                                Organization Email{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group input-group-outline mb-3">
                                <label className="form-label"></label>
                                <input
                                    className="form-control shadow-none"
                                    type="text"
                                    required="required"
                                    value={this?.state?.organizationEmail}
                                    onChange={(e) =>
                                        this.setState({
                                          organizationEmail: e.target.value,
                                          colorOrgEmail: "black",
                                        })
                                    }
                                />
                              </div>
                            </div>
                          </>)}

                          <div className="row" style={{ marginTop: 25 }}>
                            <Form.Floating className="mb-3">
                              <input
                                  class="form-check-input shadow-none"
                                  type="checkbox"
                                  id="checkBoxUS"
                                  name="US"
                                  value="US"
                                  onChange={this.handleEmployedCheck}
                              />{"  "}
                              Are you currently employed?
                            </Form.Floating>
                          </div>

                          <hr />

                          <label
                              className="h4"
                              htmlFor="floatingInputCustom"
                              style={{ color: "green" }}
                          >
                            Practice Category
                          </label>
                          <br />
                          <br />

                          <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                            <label
                                style={{ color: this.state.practiceCat }}
                                className="form-label"
                            >
                              Practice Category{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="input-group input-group-outline mb-3">
                              <label className="form-label"></label>
                              <select
                                  className="form-control shadow-none"
                                  aria-label="Select"
                                  value={this?.state?.practiceCategory}
                                  onChange={this.handlePracticeChange}
                                  style={{ color: this.state.colorPraCat }}
                              >
                                <option selected disabled>
                                  -- Select category --
                                </option>
                                <option value="ACADEMIC/RESEARCH">
                                  ACADEMIC/RESEARCH
                                </option>
                                <option value="GOVERNMENT/REGULATORY">
                                  GOVERNMENT/REGULATORY
                                </option>
                                <option value="INDUSTRY">INDUSTRY</option>
                                <option value="PRIVATE PRACTICE">
                                  PRIVATE PRACTICE
                                </option>
                                <option value="NGO">NGO</option>
                                <option value="INTERNATIONAL DEVELOPMENT">
                                  INTERNATIONAL DEVELOPMENT
                                </option>
                              </select>
                            </div>
                          </div>

                          <hr />

                          <div className="row" style={{ marginTop: 25 }}>
                            <Form.Floating className="mb-3">
                              <input
                                  class="form-check-input shadow-none"
                                  type="checkbox"
                                  id="checkBoxUS"
                                  name="US"
                                  value="US"
                                  onChange={this.handleCheckChange}
                              />{" "}
                              I hereby certify that I am not a registered and
                              practicing member of an allied profession and the
                              particulars furnished herein are true
                            </Form.Floating>
                          </div>

                          <div
                              className="text-center"
                              style={{
                                margin: "auto",
                                width: "100%",
                                marginTop: 45,
                                marginBottom: "15vh",
                              }}
                          >
                            <button
                                type="button"
                                disabled={this.state.disabled}
                                style={{
                                  alignSelf: "center",
                                  width: "100%",
                                  backgroundColor: "#003314",
                                }}
                                className="btn btn-success btn-lg"
                                onClick={(e) => this.checkValidation(e)}
                            >
                              {loading && (
                                  <Spinner
                                      animation="border"
                                      variant="light"
                                      size="sm"
                                  />
                              )}
                              <span className="font-weight-bold">
                              {" SUBMIT APPLICATION AND PROCEED TO PAYMENT"}
                            </span>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </main>
        </div>
    );
  }
}

export default RegisterLicence;

