import React, { Component } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner, Card, FloatingLabel, Form } from "react-bootstrap";
import logo from "../assets/images/logo.png"
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
 

const baseUrl = process.env.REACT_APP_BASE_URL


class NewPassword extends Component{
    constructor(props) {
        super(props);
        this.state = {
          password: "",
          showPassword: "",
          loading: false,
          disabled: false
        };
        this.togglePassword = this.togglePassword.bind(this);
      }

      togglePassword = (e) => {
        this.setState({ showPassword: e.target.checked});
      }


      resetPassword = async () => {
        const { password } = this.state;
        if(password.length === 0){
          Swal.fire({
            title: "Empty!",
            text: "Please enter phone number",
            icon: "error",
            confirmButtonText: "OK",
          });
        }else{
        this.setState({loading: true, disabled: true})
        var obj = {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            confirmPassword: password,
            countryid: "+234",
            newPassword: password,
            telephone: localStorage.getItem("reset-phone"),
            userid: localStorage.getItem("user-reset-id")
          }),
        };
        await fetch(`${baseUrl}/Users/changePassword`, obj)
          .then((response) => response.json())
          .then((responseJson) => {
            console.warn(responseJson);
            if (responseJson.message === "Changed User's Password Successfully") {
              localStorage.clear();
              this.setState({ loading: false });
              // localStorage.setItem("userid", responseJson.userid);
              // localStorage.setItem("email", responseJson.email);
              // localStorage.setItem("token", responseJson.token);
              Swal.fire({
                title: "Success",
                text: "Password changed successfully",
                icon: "success",
                confirmButtonText: "OK",
              }).then(() => {
                this.props.history.push("/login")
              });
            } else if (
              responseJson.message === "Index 0 out of bounds for length 0"
            ) {
              this.setState({ loading: false, disabled: false });
              Swal.fire({
                title: "Error!",
                text: "Invalid username or password",
                icon: "error",
                confirmButtonText: "OK",
              });
            } else {
              this.setState({ loading: false, disabled: false });
              Swal.fire({
                title: "Error!",
                text: responseJson.message,
                icon: "error",
                confirmButtonText: "OK",
              });
            }
          })
          .catch((error) => {
            this.setState({ loading: false, disabled: false });
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          });
        }
      };

      render() {
        const { loading, disabled, showPassword } = this.state;
        return (
          <div className="loginContainer">
            <div
              className="d-flex justify-content-center"
              style={{
                backgroundColor: "rgba(0,0,0,0.7)",
                width: "100%",
                position: "absolute",
                top: 0,
                bottom: 0,
              }}
            >
              <Card style={{ backgroundColor: 'rgba(255,255,255,0.7)' }} className="d-flex justify-content-center cardContainer loginContainer-content">
                <img
                  src={logo}
                  style={{
                    width: "15%",
                    position: "absolute",
                    top: 25,
                    alignSelf: "center",
                  }}
                />

                <h5 className="nicfostText" style={{position: 'relative', fontWeight:'bold', top: 35, color:'#333', textAlign:'center'}}>NIGERIAN COUNCIL OF FOOD SCIENCE AND TECHNOLOGY<br/>(NiCFoST)</h5>
                  <div className="mb-3"></div>
                <h6 className="nicfostText" style={{position:'relative', marginLeft: 13, textAlign:'center', top: 45}}>Enter new password</h6>

                <div className="container" style={{ marginTop: 50 }}>
                  <Form>
                    <Form.Floating>
                      <input
                        className="form-control shadow-none"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        onChange={(e) =>
                          this.setState({ password: e.target.value })
                        }
                      />
                      <label htmlFor="password">Password</label>
                    </Form.Floating>
                    <br />
                    <Form.Floating>
                      <input
                        className="shadow-none"
                        type="checkbox"
                        onChange={this.togglePassword}
                      /><span>{ showPassword ? 'Hide password' : 'Show password'}</span>
                    </Form.Floating>

                    <div className="d-grid gap-2 mb-3" style={{ marginTop: 30 }}>
                      <button
                        style={{backgroundColor: '#003314'}}
                        className="btn btn-success btn-lg"
                        onClick={(e) => this.resetPassword()}
                        disabled={disabled}
                      >
                        {loading ? (
                          <Spinner animation="border" variant="light" size="sm" />
                        ) : (
                          "Reset Password"
                        )}
                      </button>
                    </div>
                  </Form>
                </div>
              </Card>
            </div>
          </div>
        );
      }
    }


export default NewPassword
