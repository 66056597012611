import React, { Component } from "react";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";

import { Link } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import image from "../assets/images/image.jpeg";
import axios from "axios";
import moment from "moment";
import { makePayment, makeLicensePayment } from "../Helper/paymentHelper";

const baseUrl = process.env.REACT_APP_BASE_URL;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      allPayments: [],
      licence: [],
      isLicenceLoading: true,
      isAllPaymentsLoading: true,
      isUserInfoLoading: false,
      isDownloading: false,
      fullName: "",
      regNumber: "",
      userMail: "",
      applicationDate: "",
      registrationDate: "",
      practiceCategory: "",
      address: "",
      orgName: "",
      orgNamePractice: "",
      leadScientist: "",
      image: "",
      regCert: "",
      licenceCert: "",
      recId: "",
      regCertificateFile: "",
      licenceCertificateFile: "",
      premisesCertificateFile: "",
      premisesStatus: "",
      licenceStatus: "",
      regStatus: "",
      hasLicenceApplication: false,
      paymentStatus: false,
      paymentIsVerified: false,
      paymentVerificationDate: "",
      licenseIsPaid: false,
      isSidebarCollapsed: false,
      retrievedLicenseInfo: {
        licenseId: null,
        rrr: null,
      },
      isPayingForLicense: false,
    };

    this.getLicenceDetails = this.getLicenceDetails.bind(this);
    this.translateLicenseStatusCode =
      this.translateLicenseStatusCode.bind(this);
    this.makePaymentReponse = this.makePaymentReponse.bind(this);
  }

  getLicenceNumber = async () => {
    let obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await fetch(
      `${baseUrl}/License/getlicenseByUserID/${localStorage.getItem("userid")}`,
      obj
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 401) {
          this.setState({ isLicenceLoading: false });
          Swal.fire({
            title: "Session Expired",
            text: "Session expired. Please login",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            // do nothing
          });
        } else {
          if (responseJson.length !== 0) {
            responseJson[0].applicationstatus
              ? this.setState({
                  licenceStatus: responseJson[0]?.applicationstatus,
                  licence: responseJson[0]?.licensenumber,
                  isLicenceLoading: false,
                  userMail: responseJson[0]?.contactemail,
                })
              : this.setState({ isLicenceLoading: false });
          } else {
            this.setState({ isLicenceLoading: false });
          }
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  downloadCert = async () => {
    this.setState({ isDownloading: true });
    try {
      const apiUrl = `${baseUrl}/eservices/GetLicenseFile?timestamp=${Date.now()}`;
      const payload = {
        id: this.state.regNumber,
        nameOfCompany: this.state.orgName,
        regNumber: this.state.licence,
        validTillDate: "31 December 2023",
      };

      const headers = {
        "Content-Type": "application/json",
        "Cache-Control": "no-store",
        Pragma: "no-cache",
        Expires: "0",
      };
      const response = await axios.post(apiUrl, payload, {
        responseType: "blob",
        headers: headers,
      });

      let url = window.URL.createObjectURL(response.data);
      let a = document.createElement("a");
      a.href = url;
      a.download = "licence.pdf";
      this.setState({ licenceCertificateFile: url });
      this.setState({ isDownloading: false });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //DOWNLOAD PREMISES CERT
  getPremisesCertificate = async () => {
    this.setState({ isDownloading: true });
    try {
      const apiUrl = `${baseUrl}/eservices/GetPremisesFile?timestamp=${Date.now()}`;
      const payload = {
        nameOfCompany: this.state.orgNamePractice,
        addressOfPremises: this.state.address,
        regNumber: this.state.regNumber,
        categoryOfBusiness: this.state.practiceCategory,
        nameOfLeadScientist: this.state.leadScientist,
        cfsnNo: this.state.regNumber,
        validTillDate: "31 December 2023",
        id: this.state.regNumber,
      };

      const headers = {
        "Content-Type": "application/json",
        "Cache-Control": "no-store",
        Pragma: "no-cache",
        Expires: "0",
      };
      const response = await axios.post(apiUrl, payload, {
        responseType: "blob",
        headers: headers,
      });
      let url = window.URL.createObjectURL(response.data);
      let a = document.createElement("a");
      a.href = url;
      a.download = "premises.pdf";
      this.setState({ premisesCertificateFile: url });
      this.setState({ isDownloading: false });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //DOWNLOAD REGISTRATION CERT
  downloadRegCert = async () => {
    this.setState({ isDownloading: true });
    let date = moment(this.state.registrationDate).format("D MMMM YYYY ");
    try {
      const apiUrl = `${baseUrl}/eservices/GetCertificateFile?timestamp=${Date.now()}`;
      const payload = {
        cfsnNo: this.state.regNumber,
        dateOfRegistration: date,
        id: this.state.regNumber,
        nameOfCompany: `${
          this?.state?.title?.charAt(0).toUpperCase() +
          this?.state?.title?.slice(1)
        } ${this.state.surname} ${this.state.othernames}`,
      };

      const headers = {
        "Content-Type": "application/json",
        "Cache-Control": "no-store",
        Pragma: "no-cache",
        Expires: "0",
      };
      const response = await axios.post(apiUrl, payload, {
        responseType: "blob",
        headers: headers,
      });
      let url = window.URL.createObjectURL(response.data);
      let a = document.createElement("a");
      a.href = url;
      a.download = "certificate.pdf";
      this.setState({ regCertificateFile: url });
      this.setState({ isDownloading: false });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  getPremisesDetails = async () => {
    this.setState({ isUserInfoLoading: true });
    let obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await fetch(
      `${baseUrl}/Premises/getPremisesByUserID/${localStorage.getItem(
        "userid"
      )}`,
      obj
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 401) {
          this.setState({ isUserInfoLoading: false });
          Swal.fire({
            title: "Session Expired",
            text: "Session expired. Please login",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            // do nothing
          });
        } else {
          responseJson.forEach((item) => {
            this.setState({
              premisesStatus: item.applicationstatus,
              orgNamePractice: item.organisationname,
              practiceCategory: item.businesstype,
              address: item.locationaddress,
              leadScientist: item.leadfoodscientistname,
              isUserInfoLoading: false,
            });
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  getUserDetails = async () => {
    const self = this;
    this.setState({ isUserInfoLoading: true });
    let obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await fetch(
      `${baseUrl}/v2/dashboard/user/${localStorage.getItem("userid")}`,
      obj
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 401) {
          Swal.fire({
            title: "Session Expired",
            text: "Session expired. Please login",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            this.props.history.push("/login");
          });
        } else {
          const {
            licenseInformation,
            premisisInformation,
            registrationInformation,
            userInformation,
          } = responseJson;

          this.setState({
            regNumber: registrationInformation.csfnNumber,
            regStatus: registrationInformation.applicationStatus,
            licence: licenseInformation.licenseNumber,
            paymentStatus: licenseInformation.paymentStatus,
            licenceStatus: licenseInformation.applicationStatus,
            hasLicenceApplication: licenseInformation.hasLicenseApplication,
            licenseIsPaid: licenseInformation.licenseIsPaid,
            paymentIsVerified: licenseInformation.paymentIsVerified,
            paymentVerificationDate: licenseInformation.verificationDate,
            image: userInformation.userImage,
            fullName: userInformation.fullName,
            isUserInfoLoading: false,

            // the author decided to use local storage to send data
            // across the application while forgetting that function
            // parameters exist attempting to remedy by storing
            // necessary payment information in this state instead.
            retrievedLicenseInfo: {
              licenseId: licenseInformation.licenseId,
              rrr: licenseInformation.rrr,
            },
          });

          self.setState();

          localStorage.setItem("license_id", licenseInformation.licenseId);
          localStorage.setItem("rrr", licenseInformation.rrr);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  getLicenceRegistration = async () => {
    const url = `${baseUrl}/License/getlicenseByUserID/${localStorage.getItem(
      "userid"
    )}`;
    this.setState({ isLoading: true });
    await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(async (res) => {
        const resp = await res.json();
        return resp;
      })
      .then((res) => {
        res.map((item) => {
          this.setState({ recId: item.recid });
          this.getLicenceDetails();
          // eslint-disable-next-line array-callback-return
          return;
        });
        if (res.status === 401) {
          Swal.fire({
            title: "Session Expired",
            text: "Session expired. Please login",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            // do nothing
          });
        } else {
          this.setState({ data: res, loading: false });
        }
      })
      .catch((error) => {
        this.setState({ error: true, loading: false });
      });
  };

  getLicenceDetails = (recid) => {
    const url = `${baseUrl}/License/getlicenseByRecID/${this.state.recId}`;
    this.setState({ isLoading: true });
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(async (response) => {
        const res = await response.json();

        this.setState({
          isLoading: false,
          foodScientistData: res,
        });
      })
      .catch((error) => {
        this.setState({ error: true, loading: false });
        // alert(error);
      });
  };

  ToggleCollapesSidebar = () => {
    this.setState({ isSidebarCollapsed: !this.state.isSidebarCollapsed });
  };

  PassToggleCollapesSidebar = (value) => {
    this.setState({ isSidebarCollapsed: value });
  };

  translateLicenseStatusCode = (status) => {
    switch (status) {
      case "pending_payment":
        return "You've not made payments yet";
      case "pending_confirmation":
        return "Pls wait, your payment is pending";
      case "complete":
        return "Your payment is successful";
      case "rejected":
        return "Licence rejected";
      default:
        return "Unknown Status";
    }
  };

  makePaymentReponse = () => {
    return (
      <button
        disabled={this.state.isPayingForLicense}
        onClick={async () => {
          this.setState({ isPayingForLicense: true });

          await makeLicensePayment(
            this.state.retrievedLicenseInfo.licenseId,
            this.state.retrievedLicenseInfo.rrr
          );

          this.setState({ isPayingForLicense: false });
        }}
        class="btn btn-danger m-0 p-1 px-3"
      >
        {this.state.isPayingForLicense && (
          <Spinner className="me-2" animation="grow" role="status" size="sm">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        Pay for licence
      </button>
    );
  };

  componentDidMount() {
    // this.retrieveSuccessfulPayments();
    this.getLicenceNumber();
    this.getUserDetails();
    this.getPremisesDetails();
    this.getLicenceRegistration();
  }

  render() {
    const {
      allPayments,
      regNumber,
      regCert,
      image,
      fullName,
      licenceCert,
      isCertificateLoading,
      licence,
      isLicenceLoading,
      regStatus,
      hasLicenceApplication,
      licenseIsPaid,
      isUserInfoLoading,
      surname,
      othernames,
      licenceStatus,
      isSidebarCollapsed,
      paymentStatus,
      isAllPaymentsLoading,
    } = this.state;

    const isPaid = localStorage.getItem("paymentSuccessful");

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-2">
            <Sidebar
              isOpen={isSidebarCollapsed}
              ToggleCollapesSidebar={this.PassToggleCollapesSidebar}
            />
          </div>
          <div className="col-md-10">
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
              <div
                className="container  border-bottom"
                style={{ marginTop: 0 }}
              >
                <div className="d-flex justify-content-between">
                  <div
                    onClick={this.ToggleCollapesSidebar}
                    className="text-center me-2 d-flex align-items-center justify-content-center bg-dark w-15 rounded-circle"
                    id="dash-menu"
                    style={{ height: "50px" }}
                  >
                    <i class="material-icons text-center opacity-10 text-light w-20">
                      menu-up
                    </i>
                  </div>
                  <div className="text-dark text-bold">
                    <h4 style={{ marginTop: 27 }}>DASHBOARD</h4>
                  </div>

                  <div
                    className="rounded-circle"
                    style={{ marginTop: 10, marginBottom: 10 }}
                  >
                    {image ? (
                      <img
                        crossorigin="anonymous"
                        className="border rounded-circle  "
                        width="50"
                        height="50"
                        alt=""
                        src={`${image}`}
                      />
                    ) : (
                      <img
                        className="border rounded-circle"
                        src={image}
                        width="50"
                        height="50"
                        alt=""
                      />
                    )}
                    <span
                      className="font-weight-bold"
                      style={{ marginLeft: "10px" }}
                    >
                      {fullName.toUpperCase()}
                    </span>
                  </div>
                </div>
              </div>

              <div
                class="container-fluid justify-content-center py-4"
                id="dash"
              >
                <div class="row">
                  <div class="col-xl-4 col-sm-12 mb-xl-0 mb-4">
                    <div class="card bg-primary">
                      <div class="card-header p-3 pt-2 bg-gradient-primary">
                        <div class="icon icon-lg icon-shape bg-gradient-success shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                          <i class="material-icons opacity-10">store</i>
                        </div>
                        <div class="text-end pt-1">
                          <p class="text-lg mb-0 text-light font-weight-bold text-capitalize fw-bold">
                            Licence Number
                          </p>
                        </div>
                      </div>
                      <span class="pt-4">
                        <hr class="light horizontal my-0" />
                      </span>
                      <div class="card-footer p-3">
                        {isLicenceLoading ? (
                          <Spinner
                            animation="border"
                            className="justify-content-end text-end"
                            variant="light"
                          />
                        ) : (
                          <h4 class="mb-0 text-light text-end">
                            {!licence?.length ? "Not issued" : licence}
                          </h4>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-sm-12 mb-xl-0 mb-4">
                    <div class="card bg-success">
                      <div class="card-header p-3 pt-2 bg-gradient-primary">
                        <div class="icon icon-lg icon-shape bg-gradient-success shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                          <i class="material-icons opacity-10">receipt</i>
                        </div>
                        <div class="text-end pt-1">
                          <p class="text-lg mb-0 text-light text-capitalize fw-bold">
                            CFSN Number
                          </p>
                        </div>
                      </div>
                      <span class="pt-4">
                        <hr class="dark horizontal my-0" />
                      </span>
                      <div class="card-footer p-3">
                        {isUserInfoLoading ? (
                          <Spinner
                            animation="border"
                            className="justify-content-end text-end"
                            variant="light"
                          />
                        ) : (
                          <h4 class="mb-0 text-light text-end">
                            {regNumber ? regNumber : "Not issued"}
                          </h4>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-sm-12 mb-xl-0 mb-4">
                    <div
                      className={
                        regStatus === "rejected"
                          ? "card bg-danger"
                          : regStatus === "approved"
                          ? "card bg-success"
                          : regStatus === "pending"
                          ? "card bg-warning"
                          : "card"
                      }
                    >
                      <div class="card-header p-3 pt-2 bg-gradient-primary">
                        <div class="icon icon-lg icon-shape bg-gradient-success shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                          <i class="material-icons opacity-10">badge</i>
                        </div>
                        <div class="text-end pt-1">
                          <p class="text-lg mb-0 text-light text-capitalize fw-bold">
                            Registration Status
                          </p>
                        </div>
                      </div>
                      <span class="pt-4">
                        <hr class="dark horizontal my-0" />
                      </span>
                      <div class="card-footer p-3">
                        {isUserInfoLoading ? (
                          <Spinner
                            animation="border"
                            className="justify-content-end text-end"
                            variant="dark"
                          />
                        ) : (
                          <h4 class="mb-0 text-light text-uppercase text-end">
                            {regStatus === "rejected"
                              ? "Contact us"
                              : regStatus}
                          </h4>
                        )}
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-4 col-sm-12 mb-xl-0 mt-6">
                    <div className="card bg-secondary">
                      <div class="card-header p-3 pt-2 bg-gradient-primary">
                        <div class="icon icon-lg icon-shape bg-gradient-success shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                          <i class="material-icons opacity-10">badge</i>
                        </div>
                        <div class="text-end pt-1">
                          <p class="text-lg mb-0 text-light text-capitalize fw-bold">
                            Licence Status
                          </p>
                        </div>
                      </div>
                      <span class="pt-4">
                        <hr class="dark horizontal my-0" />
                      </span>
                      <div class="card-footer p-3">
                        {isUserInfoLoading ? (
                          <Spinner
                            animation="border"
                            className="justify-content-end text-end"
                            variant="dark"
                          />
                        ) : (
                          <h4 class="mb-0 text-light text-uppercase text-end">
                            {!hasLicenceApplication ? (
                              <Link
                                to="/register-licence"
                                className="btn btn-primary m-0 p-1 px-3"
                              >
                                Apply Now
                              </Link>
                            ) : hasLicenceApplication &&
                              paymentStatus !==
                                ("complete" || "pending_confirmation") ? (
                              this.makePaymentReponse()
                            ) : (
                              this.translateLicenseStatusCode(paymentStatus)
                            )}
                          </h4>
                        )}
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-4 col-sm-12 mb-xl-0 mt-6">
                    <div className="card bg-secondary">
                      <div class="card-header p-3 pt-2 bg-gradient-primary">
                        <div class="icon icon-lg icon-shape bg-gradient-success shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                          <i class="material-icons opacity-10">badge</i>
                        </div>
                        <div class="text-end pt-1">
                          <p class="text-lg mb-0 text-light text-capitalize fw-bold">
                            Licence Certificate
                          </p>
                        </div>
                      </div>
                      <span class="pt-4">
                        <hr class="dark horizontal my-0" />
                      </span>
                      <div
                        class="card-footer p-3"
                        style={{ marginBottom: "-20px" }}
                      >
                        {isUserInfoLoading ? (
                          <Spinner
                            animation="border"
                            className="justify-content-end text-end"
                            variant="light"
                          />
                        ) : (
                          <p className="text-end m-0">
                            <button
                              disabled={
                                /*this.state.regStatus !== "approved"*/ true
                              }
                              className="btn btn btn-primary"
                              onClick={() => this.downloadRegCert()}
                              data-bs-toggle="modal"
                              data-bs-target="#viewRegCert"
                            >
                              view Certificate
                            </button>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-4 col-sm-12 mb-xl-0 mt-6">
                    <div className="card bg-secondary">
                      <div class="card-header p-3 pt-2 bg-gradient-primary">
                        <div class="icon icon-lg icon-shape bg-gradient-success shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                          <i class="material-icons opacity-10">badge</i>
                        </div>
                        <div class="text-end pt-1">
                          <p class="text-lg mb-0 text-light text-capitalize fw-bold">
                            Premises Certificate
                          </p>
                        </div>
                      </div>
                      <span class="pt-4">
                        <hr class="dark horizontal my-0" />
                      </span>
                      <div
                        class="card-footer p-3"
                        style={{ marginBottom: "-20px" }}
                      >
                        {isUserInfoLoading ? (
                          <Spinner
                            animation="border"
                            className="justify-content-end text-end"
                            variant="light"
                          />
                        ) : (
                          <p className="text-end m-0">
                            <button
                              disabled={
                                /*this.state.premisesStatus !== 'approved'*/ true
                              }
                              className="btn btn-primary"
                              onClick={() => this.getPremisesCertificate()}
                              data-bs-toggle="modal"
                              data-bs-target="#viewPremCert"
                            >
                              view Certificate
                            </button>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>

          {/* View Certificate */}
          <div
            className="modal fade"
            id="certificate"
            tabindex="-1"
            aria-labelledby="ViewCertificate"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header d-flex align-items-center justify-content-between">
                  <h5 className="modal-title">View Membership Certificate</h5>
                  {/*<div class="d-flex align-items-center">
                       <button class="btn bg-danger text-light font-weight-bold mb-0"> <span class="iconify" data-icon="carbon:printer" style={{fontSize: 36}}></span> Print</button>
                     </div> */}
                  <button
                    type="button"
                    className="btn btn-link m-0 p-0 text-dark fs-4"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span class="iconify" data-icon="carbon:close"></span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div clasNames="d-flex px-3">
                      {isCertificateLoading ? (
                        <center>
                          <Spinner
                            animation="border"
                            className="text-center"
                            variant="success"
                            size="lg"
                          />
                        </center>
                      ) : (
                        <div className="d-flex flex-column">
                          <main
                            class="main-content position-relative max-height-vh-100 h-100 border-radius-lg "
                            style={{
                              width: !localStorage.getItem("token")
                                ? "90%"
                                : "100%",
                              position: localStorage.getItem("token")
                                ? "relative"
                                : "",
                              right: 0,
                              padding: 18,
                              float: !localStorage.getItem("token")
                                ? ""
                                : "right",
                              marginBottom: 90,
                            }}
                          >
                            <div className="container-fluid px-4">
                              <div className="rown">
                                <div className="col-12">
                                  <div className="card my-3 mb-4">
                                    <div className="card-header pb-0 bg-success">
                                      <div className="text-center">
                                        <h5 className="text-light text-center font-weight-bold mb-4">{`Certificate For ${surname} ${othernames}`}</h5>
                                      </div>
                                    </div>
                                    {/* <div class="card-body"> */}
                                    <div
                                      className="container"
                                      style={{ marginTop: 18, padding: 9 }}
                                    >
                                      <div style={{ marginTop: 0 }}></div>
                                      <center>
                                        {regCert ? (
                                          <img
                                            crossorigin="anonymous"
                                            width="450"
                                            height="450"
                                            src={`${regCert}`}
                                          />
                                        ) : (
                                          <p>
                                            No certificate uploaded for this
                                            user.
                                          </p>
                                        )}
                                      </center>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*   </div>*/}
                          </main>
                        </div>
                      )}
                    </div>
                    <span className="pt-3">
                      <hr class="dark horizontal my-3" />
                    </span>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    class="btn btn-danger"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* View Certificate */}

          {/* licence Certificate Modal */}
          <div
            class="modal fade"
            id="viewCert"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header bg-success d-flex align-items-center justify-content-between">
                  <h5 class="modal-title font-weight-bold text-light">
                    Download Your File
                  </h5>
                  <button
                    type="button"
                    class="btn btn-link m-0 p-0 text-dark fs-4"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span class="iconify" data-icon="carbon:close"></span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <embed
                      type="application/pdf"
                      src={this.state.licenceCertificateFile}
                      width="600"
                      height="400"
                    ></embed>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div class="d-flex align-items-center">
                        <div>
                          {this.state.isDownloading && (
                            <Spinner
                              animation="border"
                              variant="danger"
                              size="lg"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    id="closeButton"
                    type="button"
                    class="btn btn-danger data"
                    data-bs-dismiss="modal"
                  >
                    Abort
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* registration Certificate Modal */}
          <div
            class="modal fade"
            id="viewRegCert"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header bg-success d-flex align-items-center justify-content-between">
                  <h5 class="modal-title font-weight-bold text-light">
                    Download Your File
                  </h5>
                  <button
                    type="button"
                    class="btn btn-link m-0 p-0 text-dark fs-4"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span class="iconify" data-icon="carbon:close"></span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <embed
                      type="application/pdf"
                      src={this.state.regCertificateFile}
                      width="600"
                      height="400"
                    ></embed>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div class="d-flex align-items-center">
                        <div>
                          {this.state.isDownloading && (
                            <Spinner
                              animation="border"
                              variant="danger"
                              size="lg"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    id="closeButton2"
                    type="button"
                    class="btn btn-danger data"
                    data-bs-dismiss="modal"
                  >
                    Abort
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* premises Certificate Modal */}
          <div
            class="modal fade"
            id="viewPremCert"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header bg-success d-flex align-items-center justify-content-between">
                  <h5 class="modal-title font-weight-bold text-light">
                    Download Your File
                  </h5>
                  <button
                    type="button"
                    class="btn btn-link m-0 p-0 text-dark fs-4"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span class="iconify" data-icon="carbon:close"></span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <embed
                      type="application/pdf"
                      src={this.state.premisesCertificateFile}
                      width="600"
                      height="400"
                    ></embed>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div class="d-flex align-items-center">
                        <div>
                          {this.state.isDownloading && (
                            <Spinner
                              animation="border"
                              variant="danger"
                              size="lg"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    id="closeModal"
                    type="button"
                    class="btn btn-danger data"
                    data-bs-dismiss="modal"
                  >
                    Abort
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
